.magazine {
  padding-top: 3rem;
  padding-bottom: 1.5rem;

  .btn-large {
    max-width: 382px;
    width: 100%;
    border-radius: 24px;
    font-size: 16px;
  }

  &__text {
    h2 {
      text-align: center;
      color: $aco-dark-blue;
    }

    ul {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      font-size: 14px;

      li {
        position: relative;
        padding-left: 36px;
        margin-bottom: 8px;

        &::before {
          content: "";
          position: absolute;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#01063f" ><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"/></svg>');
          background-size: 18px;
          left: 0;
          top: -3px;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: 0 3px;
        }

        p {
          margin-bottom: 0;
          color: $aco-gray-01;
        }
      }
    }
  }
}
