@use "sass:map";

/* Breadcrumbs */

/**
 Variables
*/
$breadcrumb-font-size: $font-size-sm;
$breadcrumb-item-font-weight: $font-weight-medium;
$breadcrumb-item-active-font-weight: $font-weight-normal;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: map.get($spacers, "spacing-8");
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: null;
$breadcrumb-divider-color: $aco-blue;
$breadcrumb-active-color: $aco-dark-blue;
$breadcrumb-divider: "";
$breadcrumb-divider-flipped: $breadcrumb-divider;
$breadcrumb-border-radius: null;
$breadcrumb-divider-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($breadcrumb-divider-color)}' stroke='#{to-rgb($breadcrumb-divider-color)}' d='M113.3 47.41l183.1 191.1c4.469 4.625 6.688 10.62 6.688 16.59s-2.219 11.97-6.688 16.59l-183.1 191.1c-9.152 9.594-24.34 9.906-33.9 .7187c-9.625-9.125-9.938-24.38-.7187-33.91l168-175.4L78.71 80.6c-9.219-9.5-8.906-24.78 .7187-33.91C88.99 37.5 104.2 37.82 113.3 47.41z'/></svg>");

/**
BS default import
*/
@import "~bootstrap/scss/breadcrumb";

.breadcrumb {
  // anti-CLS
  min-height: 21px;

  & li.breadcrumb-item {
    color: $aco-blue;
    display: inline-flex;
    align-items: center;
    font-weight: $breadcrumb-item-font-weight;
    min-width: 16px;
    min-height: 21px;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      display: none;

      &:first-child {
        display: inline-flex;
      }

      &:nth-last-child(-n + 3) {
        display: inline-flex;
      }

      &:nth-last-child(3):not(:first-child) {
        & a {
          display: none;
        }

        &::after {
          content: "...";
        }
      }
    }

    & a {
      display: flex;
      align-items: center;
    }

    & svg {
      width: $font-size-base;
      height: $font-size-base;
    }

    &::before {
      background-image: $breadcrumb-divider-icon;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      margin-right: $breadcrumb-item-padding-x;
    }

    &.active {
      font-weight: $breadcrumb-item-active-font-weight;
      color: $breadcrumb-active-color;
    }
  }

  &.breadcrumb-light {
    & li.breadcrumb-item {
      color: $aco-white;

      &::before {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($aco-white)}' stroke='#{to-rgb($aco-white)}' d='M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z'/></svg>");
      }

      & a {
        color: $aco-white;

        &:hover {
          color: $aco-light-blue;
        }
      }

      &.active {
        color: $aco-light-blue;
      }
    }
  }
}
