/* choice-chips */
@use "sass:map";

/**
 Variables
 */

$font-weight-semi-bold: $font-weight-medium;

.choice-chip,
%choice-chip {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  overflow: hidden;
  font-weight: $font-weight-semi-bold;
  border: none;

  &.choice-chip-tag {
    @extend %choice-chip-aco-gray-06;

    border-radius: $border-radius-pill;
    font-size: $font-size-xs;
    font-weight: $font-weight-semi-bold;
    padding: map.get($spacers, "spacing-4") map.get($spacers, "spacing-12");
    line-height: 1.5rem;
  }

  &:hover,
  &.hover {
    background-color: $aco-gray-05 !important;
  }

  &:active,
  &.active {
    border-radius: 20px;
    box-shadow: 0 0 0 1px $aco-dark-blue;
  }

  &:focus,
  &.focus {
    border-radius: 20px;
    box-shadow: 0 0 0 2px $aco-dark-blue;
  }

  &:disabled,
  &.disabled {
    color: $aco-dark-blue-disabled !important;
  }

  &.pressed {
    background-color: $aco-gray-04 !important;
  }
}

.choice-chip-aco-blue,
%choice-chip-aco-blue {
  background-color: $aco-blue;
  color: $aco-white;
}

.choice-chip-aco-light-blue,
%choice-chip-aco-light-blue {
  background-color: $aco-light-blue;
  color: $aco-dark-blue;
}

.choice-chip-aco-gray-06,
%choice-chip-aco-gray-06 {
  background-color: $aco-gray-06;
  color: $aco-dark-blue;
}
