/* Calendar */

@use "sass:map";

/**
 Variables
 */
$datepicker-max-width: 425px;
$datepicker-base-font-size: $font-size-base;
$datepicker-base-color: $aco-blue;
$datepicker-bg-color: $aco-white;
$datepicker-header-padding-bottom: map.get($spacers, "spacing-24");
$datepicker-control-button-size: 36px;
$datepicker-control-icon-size: 12px;
$datepicker-control-icon-border-radius: $border-radius-pill;
$datepicker-control-space-between-buttons: 24px;
$datepicker-weekdays: 7;
$datepicker-weekdays-color: $aco-gray-02;
$datepicker-weekdays-font-size: $font-size-xs;
$datepicker-weekdays-padding-bottom: map.get($spacers, "spacing-16");
$datepicker-cell-border-radius: $border-radius-pill;
$datepicker-cell-selected-bg-color: $aco-light-blue;
$datepicker-cell-selected-color: $aco-dark-blue;
$datepicker-cell-selected-font-size: $font-size-lg;
$datepicker-cell-focused-bg-color: $aco-gray-05;
$datepicker-cell-hover-bg-color: $aco-gray-06;
$datepicker-cell-days-size: 40px;
$datepicker-cell-days-gap: 20px;
$datepicker-cell-flex-basis: calc((100% - (($datepicker-weekdays - 1) * $datepicker-cell-days-gap)) / $datepicker-weekdays);
$datepicker-submenu-cell-border-radius: map.get($radius-map, 16);
$datepicker-view-switch-padding: map.get($spacers, "spacing-6");
$datepicker-view-switch-color: $aco-dark-blue;

/**
 BS default import
 */

@mixin use-back-icon($color) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($color)}' stroke='#{to-rgb($color)}'  d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin use-next-icon($color) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($color)}' stroke='#{to-rgb($color)}' d='M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

/**
 Custom classes
 */
.custom-datepicker-cell,
%custom-datepicker-cell {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $datepicker-base-color;
  font-size: $datepicker-base-font-size;
  z-index: 2;

  &:hover:not(.selected):not(.focused),
  &.focused,
  &.selected {
    background-color: transparent;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      aspect-ratio: 1;
      border-radius: $datepicker-cell-border-radius;
      z-index: -1;
    }
  }

  &:hover:not(.selected):not(.focused)::after {
    background-color: $datepicker-cell-hover-bg-color;
  }

  &.focused::after {
    background-color: $datepicker-cell-focused-bg-color;
  }

  &.selected {
    color: $datepicker-cell-selected-color;
    font-size: $datepicker-cell-selected-font-size;
    font-weight: $font-weight-bold;

    &::after {
      background-color: $datepicker-cell-selected-bg-color;
    }
  }
}

.datepicker {
  background-color: $datepicker-bg-color;
  max-width: $datepicker-max-width;

  & .datepicker-header {
    color: $datepicker-base-color;
    font-size: $datepicker-base-font-size;
    font-weight: $font-weight-base;
    padding-bottom: $datepicker-header-padding-bottom;

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      padding-left: map.get($spacers, "spacing-6");
    }

    & .datepicker-controls {
      position: relative;
      height: $datepicker-control-button-size;
      width: 100%;

      & .prev-btn {
        @include use-back-icon($datepicker-base-color);

        right: calc($datepicker-control-button-size + $datepicker-control-space-between-buttons);
      }

      & .next-btn {
        @include use-next-icon($datepicker-base-color);

        right: 0;
      }

      & .prev-btn,
      & .next-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: $datepicker-control-button-size;
        height: $datepicker-control-button-size;
        color: transparent;
        user-select: none;
        border-radius: $datepicker-control-icon-border-radius;
        background-size: $datepicker-control-icon-size;
      }

      & .view-switch {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        color: $datepicker-view-switch-color;
        font-weight: $font-weight-base;
        padding: $datepicker-view-switch-padding;
      }
    }
  }

  & .datepicker-main {
    padding-left: 0;
    padding-right: 0;

    & .datepicker-view {
      & .days {
        & .days-of-week {
          text-transform: uppercase;
          gap: $datepicker-cell-days-gap;
          padding-bottom: $datepicker-weekdays-padding-bottom;

          & .dow {
            color: $datepicker-weekdays-color;
            font-size: $datepicker-weekdays-font-size;
            flex-basis: $datepicker-cell-flex-basis;
          }
        }

        & .datepicker-grid {
          width: 100%;
          gap: $datepicker-cell-days-gap;

          & .datepicker-cell {
            @extend %custom-datepicker-cell;

            flex-basis: $datepicker-cell-flex-basis;
            height: $datepicker-cell-days-size;

            &.prev,
            &.next {
              visibility: hidden;
            }

            &:nth-child(36).next {
              &,
              & ~ .next {
                display: none;
              }
            }
          }
        }
      }

      &.months.datepicker-grid,
      &.years.datepicker-grid,
      &.decades.datepicker-grid {
        width: 100%;

        & .datepicker-cell {
          @extend %custom-datepicker-cell;

          border-radius: $datepicker-submenu-cell-border-radius;
        }
      }
    }
  }
}
