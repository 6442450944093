/* Range sliders */

@use "sass:map";

/**
 Import noUiSlider
 */
@import "nouislider";

$handlebars-size: 32px;
$handlebars-border-focus: 2px solid $aco-dark-blue;

/**
 Custom Styles
 */
.noUi-target {
  border: none;
  box-shadow: none;
  background-color: $aco-gray-05;
}

.noUi-horizontal {
  height: 8px;
  margin-bottom: map.get($spacers, "spacing-16");
}

.noUi-connect {
  background: linear-gradient(90deg, $aco-blue, $aco-light-blue);

  &s {
    border-radius: 4px;
  }
}

[disabled] .noUi-connect {
  background: transparent;
}

// Handlebars style
.noUi-horizontal .noUi-handle {
  height: $handlebars-size;
  width: $handlebars-size;
  top: -12px;
  right: -19px;
  border-radius: $border-radius-pill;
  box-shadow: none;
  background-color: $aco-light-blue;
  border-color: transparent;

  &:hover {
    background-color: $aco-light-blue-hover;
  }

  &:focus {
    border: $handlebars-border-focus;
  }

  &:focus-visible {
    outline: none;
  }
}

[disabled].noUi-horizontal .noUi-handle {
  border: none;
  background-color: $aco-chatbot-blue;
}

.noUi-handle::before,
.noUi-handle::after {
  display: none;
}

// Mobile touch style
.noUi-handle .noUi-touch-area {
  border: 1px solid transparent;
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  width: auto;
  height: auto;

  &:focus-visible {
    outline: none;
  }
}

// Value display element
.range-display {
  // non-display the input-field arrows
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
    border: none;
  }

  &[type="number"] {
    appearance: textfield;
    border: none;
  }
}

.rangeSliderWrapper {
  & input {
    background-color: transparent;
  }
}
