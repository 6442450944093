/* Sidebar */

@use "sass:map";

/**
 Variables
 */

$sidebar-items-padding: map.get($spacers, "spacing-8");
$sidebar-items-padding-selected: map.get($spacers, "spacing-12");
$sidebar-items-color: $aco-gray-01;
$sidebar-items-font-size: $font-size-sm;
$sidebar-items-selected-font-weight: $font-weight-bold;
$sidebar-items-selected-font-size: $font-size-base;
$sidebar-items-font-weight: $font-weight-medium;
$sidebar-items-selected-color: $aco-dark-blue;
$sidebar-items-dropwdown-color: $aco-blue;

/**
 Custom Classes
 */

.sidebar {
  width: fit-content;
  height: auto;
  line-height: 21px;

  &-item {
    font-size: $sidebar-items-font-size;
    color: $sidebar-items-color;
    padding-top: $sidebar-items-padding;
    padding-bottom: $sidebar-items-padding;
    font-weight: $sidebar-items-font-weight;
    cursor: pointer;

    &:last-child {
      padding-bottom: map.get($spacers, "spacing-16");
    }
  }

  &-item-selected {
    font-size: $sidebar-items-selected-font-size;
    font-weight: $sidebar-items-selected-font-weight;
    color: $sidebar-items-selected-color;
    padding-bottom: map.get($spacers, "spacing-6");
  }

  &-item-dropdown {
    padding-left: map.get($spacers, "spacing-16");

    &-item {
      font-size: $sidebar-items-font-size;
      color: $sidebar-items-dropwdown-color;
      padding-top: $sidebar-items-padding;
      padding-bottom: $sidebar-items-padding;
      list-style: none;
      font-weight: $font-weight-medium;

      &-selected {
        font-size: $sidebar-items-font-size;
        font-weight: $sidebar-items-selected-font-weight;
        color: $sidebar-items-selected-color;
        line-height: 21px;
        padding-top: $sidebar-items-padding;
        padding-bottom: $sidebar-items-padding;
      }
    }
  }
}
