/* Icon text list */

.icon-text-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: $content-spacing-y;
  padding-left: 0;

  &__item {
    flex: 1 0 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    gap: 0.1rem $content-spacing-x;

    &-link {
      display: inherit;
      flex-flow: column;
      align-items: inherit;
      gap: inherit;
    }
  }

  &__icon {
    flex: 0 0 auto;
    width: 3rem;
    height: 3rem;
    color: $aco-blue;
    fill: currentColor;
  }

  &__text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
  }

  &__label {
    flex: 1 0 100%;
    font-size: 16px;
    font-weight: bold;
    color: $aco-blue;
  }

  &__subline {
    font-size: 14px;
    hyphens: auto;
  }

  // Modifier
  &--horizontal {
    flex-wrap: nowrap;

    .icon-text-list {
      &__item {
        flex: 1 1 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        gap: $content-spacing-y;
      }

      &__icon {
        flex: 0 0 auto;
      }

      &__text {
        text-align: center;
      }

      &__label {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
