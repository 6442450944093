/** Print order **/
@use "sass:map";

$field-spacing-x: map.get($spacers, "spacing-12");
$field-spacing-y: map.get($spacers, "spacing-8");

.print-order {
  margin: map.get($spacers, "spacing-20") 0;
  font-size: $font-size-sm;
  color: $black;

  .logo {
    width: 100%;
    max-width: 360px;
  }

  th {
    background-color: $aco-gray-06;
    padding: $field-spacing-y $field-spacing-x;
  }

  td {
    padding: $field-spacing-y $field-spacing-x;
  }

  .product {
    border-bottom: 1px solid $aco-gray-03;
    page-break-inside: avoid;

    td {
      padding: map.get($spacers, "spacing-16") $field-spacing-x;
      vertical-align: baseline;
    }
  }

  .print-order-products {
    display: block;
    padding-left: map.get($spacers, "spacing-12");
    padding-right: map.get($spacers, "spacing-12");
    padding-bottom: map.get($spacers, "spacing-16");
  }

  .print-order-totals {
    float: right;
    display: block;
    padding-left: map.get($spacers, "spacing-12");
    padding-right: map.get($spacers, "spacing-12");
    padding-bottom: map.get($spacers, "spacing-16");
    page-break-inside: avoid;

    td {
      padding: 0 0 0 map.get($spacers, "spacing-12");

      &:first-child {
        text-align: right;
      }
    }

    tr {
      &:last-child td {
        padding-top: map.get($spacers, "spacing-24");
        font-weight: $font-weight-bold;
      }
    }
  }

  [class*="docyet_embedded_button"] {
    display: none;
  }
}
