.input-product-interaction {
  border: 2px solid $aco-gray-06;

  .form-control,
  .input-group-text {
    border: 0;
  }
}

.overflow-elements {
  max-height: 350px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $aco-gray-04;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $aco-light-blue;
    border-radius: 20px;
  }
}

.product-interaction-check + .product-interaction-check {
  border-top: 1px solid $aco-blue-disabled;
}
