@use "sass:map";

$data-information-list-element-padding: map.get($spacers, "spacing-16");

.data-information-list {
  list-style-type: none;
  padding: 0;

  & li {
    padding-top: $data-information-list-element-padding;

    &:first-child {
      padding-top: 0;
    }
  }
}
