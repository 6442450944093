@use "sass:map";

.section-header {
  background-color: $aco-gray-06;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  color: $aco-gray-01;
  text-transform: uppercase;
}

[data-class="ot_total"] {
  margin-top: map.get($spacers, "spacing-20");
  text-transform: uppercase;
}

.order-summary {
  display: flex;
  flex-direction: column;

  & .products-summary {
    & .product-entry:not(:last-child) {
      border-bottom: 1px solid $aco-gray-05;
    }
  }

  & .section-overview {
    padding: map.get($spacers, "spacing-32") 9%;
    color: $aco-white;
    background-color: $aco-dark-blue;

    @media screen and (max-width: map.get($grid-breakpoints, "sm")) {
      padding: map.get($spacers, "spacing-32") map.get($spacers, "spacing-16");
    }

    & [data-class="ot_shipping"] {
      & span:last-child {
        color: $aco-yellow;
      }
    }

    & [data-class="ot_total"] {
      margin-top: map.get($spacers, "spacing-20");
    }

    & .overview-title {
      padding-top: map.get($spacers, "spacing-16");
      font-size: $font-size-sm;
      color: $aco-gray-03;
    }

    & .apo-punkte-card {
      display: flex;
      align-items: center;
      width: fit-content;
      min-height: 67px;
      color: $aco-yellow;
      background-color: $aco-blue;
      border-radius: map.get($radius-map, 8);
      padding: 0 map.get($spacers, "spacing-16");

      @media screen and (max-width: map.get($grid-breakpoints, "sm")) {
        width: 100%;
      }

      & .icon {
        position: relative;
        width: fit-content;

        & svg {
          width: 24px;
          height: 24px;
          color: $aco-white;
        }

        & svg:last-child {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          width: 14px;
          height: 14px;
          color: $aco-white;
          border: 4px solid $aco-blue;
          border-radius: $border-radius-pill;
        }
      }
    }
  }
}

[data-selector="confirm_modal"] p {
  font-size: $font-size-base;
}

.modal-header [data-action="close-modal"] {
  background-size: 10px;
}

.section-retoure-cancel {
  display: flex;
  flex-direction: column;

  & .title {
    font-size: $h3-font-size;
    padding-bottom: map.get($spacers, "spacing-16");
  }

  & .description {
    font-weight: $font-weight-base;
    margin-bottom: map.get($spacers, "spacing-16");
  }
}

// Show Order Details (form) with NO login

.show-order-details-nologin {
  max-width: 450px;
  width: 80%;
}
