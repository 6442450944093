/* Footer */

@use "sass:map";

$footer-svg-padding-top: map.get($spacers, "spacing-48");
$footer-svg-padding-bottom: map.get($spacers, "spacing-56");
$footer-svg-gap: map.get($spacers, "spacing-48");
$footer-svg-cards-width: 356px;
$footer-svg-cards-width-sm: 100%;
$footer-svg-color: $aco-dark-blue;
$footer-main-padding-y: map.get($spacers, "spacing-24");
$footer-main-padding-bottom-sm: map.get($spacers, "spacing-12");
$footer-main-link-padding: map.get($spacers, "spacing-24") 0;
$footer-service-padding-y: map.get($spacers, "spacing-16");
$footer-service-link-padding-y-sm: map.get($spacers, "spacing-20");
$footer-end-margin: map.get($spacers, "spacing-4");
$footer-end-padding-top: map.get($spacers, "spacing-40");
$footer-end-padding-bottom: map.get($spacers, "spacing-72");
$footer-extra-margin: map.get($spacers, "spacing-48") 0 map.get($spacers, "spacing-8") 0.3px;
$footer-svg-misc-padding: map.get($spacers, "spacing-24") 50px;
$footer-background: $aco-gray-06;
$footer-titles-margin-bottom: map.get($spacers, "spacing-24");

footer,
.footer-tag {
  background: $footer-background;
}

.footer-svg {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $footer-svg-gap;
  padding-top: $footer-svg-padding-top;
  padding-bottom: $footer-svg-padding-bottom;
  color: $footer-svg-color;

  @media screen and (max-width: map.get($grid-breakpoints, lg)) {
    grid-template-columns: 1fr;
  }

  &-safe-buying-title,
  &-payment-title,
  &-partner-title,
  &-delivery-title {
    font-size: $h3-font-size;
    font-weight: $font-weight-semibold;
    margin-bottom: $footer-titles-margin-bottom;
  }

  &-safe-buying-list,
  &-payment-list,
  &-partner-list,
  &-delivery-list {
    background: $aco-white;
    border-radius: 12px;
    gap: map.get($spacers, "spacing-24");
    padding: $footer-svg-misc-padding;
    min-height: 152px;
    width: $footer-svg-cards-width;

    @media screen and (max-width: map.get($grid-breakpoints, lg)) {
      width: $footer-svg-cards-width-sm;
    }
  }
}

.footer-main {
  color: $aco-blue;
  padding-top: $footer-main-padding-y;
  padding-bottom: $footer-main-padding-y;
  list-style: none;

  &-link {
    font-size: $h3-font-size;
    font-weight: $font-weight-semibold;
    padding: $footer-main-link-padding;
  }
}

.footer-service-wrapper {
  background: $aco-white;
}

.footer-service {
  padding-top: $footer-service-padding-y;
  padding-bottom: $footer-service-padding-y;
  list-style: none;
  margin-bottom: 0;

  &-link {
    color: $aco-blue;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
  }
}

.footer-end {
  padding-top: $footer-end-padding-top;
  padding-bottom: $footer-end-padding-bottom;

  & .footer-extra {
    color: $aco-blue;
    list-style: none;
    gap: map.get($spacers, "spacing-16");
    margin: $footer-extra-margin;
    padding: 0;
    overflow: hidden;
    line-height: 1;

    & .footer-extra-link {
      font-weight: $font-weight-bold;
    }

    & .footer-extra-link + .footer-extra-link a {
      position: relative;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        height: 12px;
        width: 1px;
        left: -8px;
        top: 3px;
        background-color: $aco-blue;
      }
    }
  }

  & .footer-end-z3 {
    margin-top: map.get($spacers, "spacing-24");
  }

  & .footer-end-copyright,
  & .footer-end-z3 {
    font-size: $font-size-sm;
  }
}

@media screen and (max-width: map.get($grid-breakpoints, "lg")) {
  .footer-main {
    margin-bottom: 0;
    padding-bottom: $footer-main-padding-bottom-sm;
  }

  .footer-service {
    padding-top: 0;
    padding-bottom: 0;

    &-link {
      padding-top: $footer-service-link-padding-y-sm;
      padding-bottom: $footer-service-link-padding-y-sm;
    }
  }

  .footer-main,
  .footer-service {
    &-link {
      width: 100%;
    }

    &-link + &-link {
      border-top: 1px solid $aco-gray-04;
    }
  }
}
