/* Spacers */

/**
 Variables
 */
$spacer: 1rem;
$size: 16;
$enable-negative-margins: true;
$spacers: (
  "spacing-0": 0,
  "spacing-2": calc((2 / $size) * $spacer),
  "spacing-4": calc((4 / $size) * $spacer),
  "spacing-6": calc((6 / $size) * $spacer),
  "spacing-8": calc((8 / $size) * $spacer),
  "spacing-12": calc((12 / $size) * $spacer),
  "spacing-16": calc((16 / $size) * $spacer),
  "spacing-20": calc((20 / $size) * $spacer),
  "spacing-24": calc((24 / $size) * $spacer),
  "spacing-32": calc((32 / $size) * $spacer),
  "spacing-40": calc((40 / $size) * $spacer),
  "spacing-48": calc((48 / $size) * $spacer),
  "spacing-56": calc((56 / $size) * $spacer),
  "spacing-64": calc((64 / $size) * $spacer),
  "spacing-72": calc((72 / $size) * $spacer),
  "spacing-80": calc((80 / $size) * $spacer),
  "spacing-96": calc((96 / $size) * $spacer),
  "spacing-112": calc((112 / $size) * $spacer),
  "spacing-128": calc((128 / $size) * $spacer),
  "spacing-144": calc((144 / $size) * $spacer),
  "spacing-160": calc((160 / $size) * $spacer),
);
$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);

@each $keyBreakpoint, $valueBreakpoint in $grid-breakpoints {
  @each $keySpacer, $valueSpacer in $spacers {
    .px-#{$keyBreakpoint}-down-#{$keySpacer} {
      @include media-breakpoint-down($keyBreakpoint) {
        padding-left: $valueSpacer !important;
        padding-right: $valueSpacer !important;
      }
    }

    .py-#{$keyBreakpoint}-down-#{$keySpacer} {
      @include media-breakpoint-down($keyBreakpoint) {
        padding-top: $valueSpacer !important;
        padding-bottom: $valueSpacer !important;
      }
    }

    .ps-#{$keyBreakpoint}-down-#{$keySpacer} {
      @include media-breakpoint-down($keyBreakpoint) {
        padding-left: $valueSpacer !important;
      }
    }

    .pe-#{$keyBreakpoint}-down-#{$keySpacer} {
      @include media-breakpoint-down($keyBreakpoint) {
        padding-right: $valueSpacer !important;
      }
    }
  }
}
