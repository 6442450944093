/* Product Interaction Result Page */

.product-interaction-result {
  border-top: 1px solid $aco-blue-disabled;

  &-scroll {
    max-height: 285px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $aco-gray-04;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $aco-light-blue;
      border-radius: 20px;
    }
  }

  &:first-child {
    border-top: none;
  }

  &-form {
    & textarea {
      min-height: 13.75rem;
      color: $aco-dark-blue;
      border: 1px solid $border-color;
      border-radius: $border-radius-sm;
      resize: none;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $aco-dark-blue;
        opacity: 1;
      }
    }
  }

  &-image {
    position: relative;
  }
}

.product-interaction-content-text {
  font-size: $font-size-base;
}

.product-interaction-result-header {
  &.product-name {
    font-size: $font-size-base;
    color: $aco-white;
  }

  &.product-id {
    font-size: $font-size-xs;
    color: $aco-gray-03;
  }

  &.product-price {
    font-size: $font-size-base;

    &:first-child {
      font-size: $font-size-base;
      color: $aco-gray-03;
    }

    &.avp {
      text-decoration: line-through;
    }

    &.final-price {
      font-weight: $font-weight-bold;
      color: $aco-orange;
    }
  }
}

@include media-breakpoint-down(md) {
  .product-interaction-content-text {
    font-size: $font-size-sm;
  }
}
