// ==============================================================
// Styles specific to the hero banner of the Contentful module:
//  aco_pharmacore_home_top
// ==============================================================

/* NOTE:   - This stylesheet applies exclusively to banners added in apo_com_responsive/pages/index_contentful.html.tpl:16
*/

#pharmacore-header-banner {
  margin: 0 auto;
  padding: 0;

  .swiper-controls-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    border-top: none;
  }

  [data-slider="contentful-banner"] {
    border-radius: 0 0 12px 12px;
    overflow: hidden;

    .swiper-wrapper {
      height: 230px;
      border-color: transparent !important;

      a,
      picture,
      img {
        display: block;
        position: relative;
        height: 100%;
        width: auto;
      }

      picture {
        overflow: hidden;
      }

      img {
        left: 50%;
        transform: translateX(-50%);
      }

      @include media-breakpoint-up(md) {
        height: 320px;
      }

      @include media-breakpoint-up(xxl) {
        height: 400px;
      }
    }

    .swiper-slide {
      height: 100%;
    }
  }

  .banner-slider-pflichttext {
    border-radius: 0 12px;
    background: rgb(242 242 247 / 64%);
    color: $aco-dark-blue;
    width: fit-content;
    display: flex;
    align-items: center;
    height: auto !important;
    font-family: Roboto, sans-serif;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    padding: 4px 8px !important;
    bottom: 0;
  }
}
