@use "sass:map";

/* Service teaser card */

$content-height-elements-mobile: 161px;
$content-width-elements-mobile: 161px;

.service-teaser-card {
  color: $aco-white;
  display: flex;
  align-items: center;
  width: 100%;
  background: $aco-dark-blue;
  padding: map.get($spacers, "spacing-16");
  border: 2px solid $aco-blue-disabled;
  border-radius: map.get($radius-map, 20);
  outline: none;
  cursor: default;
  text-align: left;

  &[href]:hover {
    color: $aco-white;
    cursor: pointer;
  }

  &.active {
    cursor: pointer;

    &:hover {
      border: 2px solid $aco-white;
      color: $aco-white;
    }
  }

  &:focus {
    border: 2px solid $aco-light-blue;
    outline: none;
  }

  & .icon {
    width: fit-content;
    padding-right: map.get($spacers, "spacing-16");
  }

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .title {
      font-weight: $font-weight-bold;
    }

    & .description {
      color: $aco-gray-03;
    }
  }
}

.service-teaser-card-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: map.get($spacers, "spacing-6");
  justify-content: center;
  width: $content-width-elements-mobile;
  min-height: $content-height-elements-mobile;
  color: $aco-white;
  background: $aco-dark-blue;
  padding: map.get($spacers, "spacing-16");
  border: 2px solid $aco-blue-disabled;
  border-radius: map.get($radius-map, 20);
  outline: none;

  &:hover {
    border: 2px solid $aco-white;
    color: $aco-white;
  }

  &:focus {
    border: 2px solid $aco-light-blue;
    outline: none;
  }

  & .icon {
    width: fit-content;
  }

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .title {
      font-weight: $font-weight-bold;
    }
  }
}
