@use "sass:map";

.modal-backdrop.show {
  opacity: 0.6;
  z-index: 9998;
}

[data-selector="consent-manager"] {
  padding: 0.5rem;
  background-color: rgba(0 0 0 / 60%);

  &.bg-transparent {
    background-color: rgba(0 0 0 / 0%);
    pointer-events: auto;
  }

  &.pointer-none {
    pointer-events: none;
  }

  & .modal-content {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 5rem);
  }

  & .modal-dialog {
    max-width: none;
    width: 100%;
    margin: 0;
    font-size: $font-size-base;

    h2 {
      letter-spacing: 0.42px;
      font-weight: $font-weight-lighter;

      &.yellow {
        color: $aco-yellow;
      }
    }

    p {
      overflow-x: hidden;
      margin-bottom: 0;

      &.small {
        font-size: $font-size-sm;
      }
    }

    a {
      color: $aco-light-blue;
      text-decoration: none;
    }

    label {
      font-weight: $font-weight-normal;
      color: $aco-white;
      font-size: $font-size-md;

      svg {
        color: $aco-light-blue;
        font-size: 1.5rem;
        border-radius: 50%;
        background-color: $aco-dark-blue;
      }
    }

    @include media-breakpoint-down(md) {
      font-size: $font-size-sm;
    }
  }

  .modal-body {
    font-size: $font-size-base;
    padding-bottom: 0;
  }

  .modal-footer {
    margin: auto;
    padding: 0;

    .btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        max-width: 265px;
      }
    }
  }

  & [data-selector="cm_advanced_content"] a {
    word-break: break-all;
  }

  [data-selector="footer_links"] {
    gap: 0.5rem;

    a:first-child {
      border-right: 1px solid $aco-light-blue;
      padding-right: 0.5rem;
    }

    @include media-breakpoint-down(md) {
      margin-top: map.get($spacers, "spacing-24");
    }
  }

  [data-selector="footer_buttons"] {
    width: 60%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  [data-selector="consent_manager_standard"] {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    .modal-body {
      margin: auto;
      align-items: flex-start;

      @include media-breakpoint-down(md) {
        max-height: 90%;
      }
    }
  }

  [data-selector="consent_manager_settings"] {
    max-width: 592px;
    margin: auto;
    width: 100%;

    .btn {
      width: 100%;
      max-width: 404px;
    }

    .modal-footer {
      margin: 0;
    }

    [data-selector="consent_manager_settings_back_button"] {
      text-decoration: underline;
    }

    .no-gap {
      gap: 0;
    }
  }

  [data-selector="consent_categories"] {
    p {
      color: $aco-blue-disabled;
    }

    [data-selector="cm_label"] {
      font-size: $font-size-base;

      @include media-breakpoint-down(md) {
        font-size: $font-size-sm;
      }
    }
  }

  [data-selector="consent_manager_settings_advanced"] {
    min-height: 200px;

    &.modal-content {
      max-width: 800px;
      width: 100%;
    }

    .modal-footer {
      flex-basis: 50%;
      margin: 0;

      .btn {
        max-width: 100%;
      }
    }

    .search-wrapper {
      height: 40px;

      .search .search-input {
        top: 0;
        padding-left: 12px;

        @include media-breakpoint-up(md) {
          padding-left: 50px;
        }

        &:focus {
          // background-color: #1947a3;
        }
      }
    }

    [data-selector="cm_advanced_sidebar_wrapper"] {
      border-bottom-right-radius: 24px;
      border-bottom-left-radius: 24px;
      width: 100%;
      height: 100%;
      max-width: 229px;
      background-color: $white;

      [data-selector="cm_advanced_sidebar"] {
        background-color: $white;
        max-height: 520px;
        overflow-y: scroll;
        width: 100%;
        height: 100%;

        a {
          color: $aco-dark-blue;
        }
      }

      @media screen and (max-width: map.get($grid-breakpoints, "sm")) {
        display: none;
      }
    }

    [data-selector="cm_advanced_content"] {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      max-height: 536px;
      scrollbar-width: thin;
      scrollbar-color: $aco-light-blue $aco-blue-disabled;

      > div {
        height: 100%;
      }

      .status-round {
        border-radius: 50%;
        width: 15px;
        height: 15px;

        &-green {
          background-color: $success;
        }

        &-red {
          background-color: $danger;
        }
      }
    }
  }

  [data-selector="manage_settings"] {
    text-decoration: underline;
    cursor: pointer;
  }

  [data-selector="text_entrance"],
  [data-selector="privacy_settings_body"],
  [data-selector="consent_manager_settings_advanced_body"] {
    overflow-y: auto;
  }
}
