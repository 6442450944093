/* Eyecatchers */

@use "sass:map";

/* Variables */

$eyecatcher-border-radius: 0 12px 12px 0;
$eyecatcher-multiline-border-radius: 0 20px 20px 0;
$eyecatcher-font-size: 14px;
$eyecatcher-default-padding: 4px 12px 4px 8px;
$eyecatcher-font-weight: $font-weight-bold;

/**
 Custom Styles
 */

.eyecatcher {
  position: relative;
  display: inline-flex;
  background-color: $aco-orange;
  padding: $eyecatcher-default-padding;
  border-radius: $eyecatcher-border-radius;
  color: $aco-white;
  font-size: $eyecatcher-font-size;
  font-weight: $eyecatcher-font-weight;
  line-height: 1.3;

  //  max-width exceptions for medical-interaction and shpopping-cart items //
  .product-card & {
    max-width: 75%;
  }

  &-3d {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -$badge-ribbon-margin-outside;
    overflow: visible;

    &::after {
      content: "";
      position: absolute;
      display: block;
      border-style: solid;
      border-color: transparent transparent $aco-dark-orange;
      top: - calc($badge-ribbon-margin-outside / 2);
      left: 0;
      border-width: 0 0 calc($badge-ribbon-margin-outside / 2) $badge-ribbon-margin-outside;
    }

    &.blue::after {
      border-color: transparent transparent $aco-dark-blue;
    }
  }

  &-combination {
    border-radius: 0 !important;

    div:nth-child(2) {
      display: flex;
      align-items: center;
      position: absolute;
      right: -2px;
      height: 100%;
      top: 0;
      border-radius: 0 12px 12px 0;
      padding: 2px 12px 2px 8px;
      background-color: #ea7b0c;
      transform: translateX(100%);
    }

    &.multiline {
      div:nth-child(2) {
        border-radius: $eyecatcher-multiline-border-radius;
      }
    }
  }

  &.multiline {
    border-radius: $eyecatcher-multiline-border-radius;
  }

  &.blue {
    background-color: $aco-blue;
  }

  &.blue-orange {
    background-color: $aco-blue;

    div:nth-child(2) {
      background-color: $aco-orange;
    }

    &::after {
      border-color: transparent transparent $aco-dark-blue;
    }
  }

  &.orange-blue {
    background-color: $aco-orange;

    div:nth-child(2) {
      background-color: $aco-blue;
    }

    &::after {
      border-color: transparent transparent $aco-dark-orange;
    }
  }
}
