/* Progress bars */
@use "sass:map";

/**
 Variables
 */
$progress-height: 4px;
$progress-font-size: $font-size-base * 0.75;
$progress-bg: $aco-gray-06;
$progress-bg-light-blue: rgba($aco-light-blue, 0.3);
$progress-border-radius: 2px;
$progress-box-shadow: $box-shadow-inset;
$progress-bar-color: transparent;
$progress-bar-bg: linear-gradient(to left, $aco-light-blue, $aco-blue);
$progress-player-bar-bg: linear-gradient(to right, $aco-light-blue, $aco-blue);
$progress-bar-bg-dark: linear-gradient(to left, $aco-light-blue, $aco-dark-blue);
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;
$progress-player-height: 2px;
$progress-splited-margin: 0.8rem;

/**
 BS default import
 */
@import "~bootstrap/scss/progress";

.progress {
  & .progress-bar {
    background: $progress-bar-bg;
    border-radius: $progress-border-radius;

    @at-root .dark {
      & .progress .progress-bar {
        background: $progress-bar-bg-dark;
      }
    }
  }

  &.player {
    height: $progress-player-height;

    & .progress-bar {
      height: $progress-player-height;
      background: $progress-player-bar-bg;

      @at-root .progress {
        height: $progress-player-height;
      }
    }
  }

  &.splited {
    height: $progress-height;
    background: transparent;

    & .progress-bar-wrapper {
      margin-right: $progress-splited-margin;
      width: 100%;
      background: $progress-bg-light-blue;
      border-radius: $progress-border-radius;

      & .progress-bar {
        height: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-timer {
    font-size: $font-size-xs;
    line-height: 18px;
    color: $aco-gray-01;
    margin-top: map.get($spacers, "spacing-2");
  }
}
