@use "sass:map";

/* Category */
.category-filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: map.get($spacers, "spacing-16") map.get($spacers, "spacing-16");
  list-style: none;
}

.dropdown-menu-item,
%dropdown-menu-item {
  white-space: nowrap;
  padding: map.get($spacers, "spacing-4") map.get($spacers, "spacing-20");
}

.navigation-page-links {
  & .pagination-wrapper {
    justify-content: center;
    display: flex;
  }
}

[data-section="seo-teaser-image"] {
  img {
    object-fit: cover;
    height: 150px;
    width: 100%;
  }
}

@media (min-width: 576px) {
  [data-section="seo-teaser-image"] {
    img {
      height: 200px;
    }
  }
}

@media (min-width: 768px) {
  [data-section="seo-teaser-image"] {
    img {
      height: 300px;
    }
  }
}
