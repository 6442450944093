.webAnimationContainer {
  background: var(--aco-light-blue-gradient, linear-gradient(135deg, rgb(100 214 252 / 32%) -0.12%, rgb(102 178 255 / 64%) 100.13%), #fff) !important;
}

.animation-container {
  position: relative;
  width: 100%;
  padding: 3rem 1.5rem;

  .animation-container__outer-images {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: -3rem -1.5rem;
  }

  .animation-container__content {
    display: flex;
    align-items: center;
    flex-flow: column;
    max-width: 426px;
    margin: 0 auto;
  }

  .animation-container__plant {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-100%, -100%);
    transition: transform 2s ease-in-out;
  }

  .animation-container__planer {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(100%, 50%);
    transition: transform 2s ease-in-out;
  }

  .animation-container-animation__container {
    position: relative;
    aspect-ratio: 1;
    height: 100%;
    width: 100%;
    margin: 1rem auto 2rem;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .animation-container-borderline {
    height: 1px;
    width: 100%;
    mix-blend-mode: multiply;
    border-bottom: 1px solid $aco-gray-06;
  }

  .animation-container-text {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin: 0 auto;
    transition: margin 500ms;

    .btn {
      height: 48px;
      border-radius: 24px;
      background-color: white;
    }
  }

  .animation-container-list {
    h2 {
      margin-bottom: 20px;
    }

    ol {
      padding-left: 1rem;
      margin-bottom: 2rem;

      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .animation-container__content {
      max-width: none;
      margin: 0;
      flex-flow: row nowrap;
    }

    .animation-container-animation__container {
      aspect-ratio: 1;
      margin: 0 auto;
      flex: 0 0 34%;
    }

    .animation-container-text {
      max-width: 340px;
      margin: 0 5% 0 auto;
    }
  }

  @include media-breakpoint-up(lg) {
    .animation-container-animation__container {
      margin: -3rem auto 0;
    }

    .animation-container-text {
      max-width: 450px;
      margin: 0 7% 0 auto;
    }
  }

  @include media-breakpoint-up(xl) {
    .animation-container__plant,
    .animation-container__planer {
      transform: translate(0, 0);
    }

    .animation-container-animation__container {
      position: absolute;
      margin: 0 auto 0 5%;
    }

    .animation-container-text {
      max-width: 450px;
      margin: 0 12% 0 auto;
    }

    .animation-container-borderline {
      display: none;
    }
  }

  @include media-breakpoint-up(xxl) {
    .animation-container-animation__container {
      margin: 0 auto 0 12%;
    }
  }
}
