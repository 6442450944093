/* Badges */
@use "sass:map";

/**
 Variables
 */

$badge-font-weight: $font-weight-medium;
$badge-color: $white;
$badge-padding-y: 0.35em;
$badge-padding-x: 0.65em;
$badge-border-radius: $border-radius;
$badge-bubble-size: 1.3125rem;
$badge-bubble-border-radius: $border-radius-pill;
$badge-pagination-size: 1.6rem;
$badge-pagination-padding: 0.5rem;
$badge-pagination-border-radius: $border-radius-pill;
$badge-chat-size: 3rem;
$badge-chat-padding: 0.6rem;
$badge-chat-border-radius: $border-radius-pill;
$badge-calendar-day-size: 2.5rem;
$badge-calendar-day-padding: 0.4rem;
$badge-calendar-day-border-radius: $border-radius-pill;
$badge-ribbon-margin-outside: map.get($spacers, "spacing-8");

/**
 BS default import
 */
@import "~bootstrap/scss/badge";

.badge,
%badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  overflow: hidden;
  font-weight: $badge-font-weight;

  &.badge-bubble {
    @extend %badge-aco-orange;

    width: $badge-bubble-size;
    height: $badge-bubble-size;
    border-radius: $badge-bubble-border-radius;
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
  }

  &.badge-pagination {
    @extend %badge-aco-light-blue;

    width: $badge-pagination-size;
    height: $badge-pagination-size;
    padding: $badge-pagination-padding;
    border-radius: $badge-pagination-border-radius;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
  }

  &.badge-chat {
    @extend %badge-aco-light-blue;

    width: $badge-chat-size;
    height: $badge-chat-size;
    padding: $badge-chat-padding;
    border-radius: $badge-chat-border-radius;
    box-shadow: $box-shadow-sm;

    & svg {
      width: 24px;
      height: 24px;
    }
  }

  &.badge-calendar-day {
    color: $aco-blue;
    width: $badge-calendar-day-size;
    height: $badge-calendar-day-size;
    padding: $badge-calendar-day-padding;
    border-radius: $badge-calendar-day-border-radius;
    font-size: $font-size-base;
    font-weight: $font-weight-base;

    &.position-absolute {
      right: 48px;
    }

    &.disabled,
    &:disabled {
      color: $aco-blue-disabled;
    }

    &.active,
    &:active {
      @extend %badge-aco-light-blue;

      font-weight: $font-weight-bold;
      font-size: $font-size-lg;
    }
  }

  &.badge-tag {
    @extend %badge-aco-gray-06;

    border-radius: $border-radius-pill;
    font-size: $font-size-xs;
    font-weight: $badge-font-weight;
    padding: map.get($spacers, "spacing-2") map.get($spacers, "spacing-8");
    margin-right: map.get($spacers, "spacing-8");
    margin-bottom: map.get($spacers, "spacing-8");
    line-height: 1.5rem;
  }

  &.badge-price {
    @extend %badge-aco-orange;

    padding: map.get($spacers, "spacing-4") map.get($spacers, "spacing-16");
    border-radius: $border-radius-pill;
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
    line-height: 1.5rem;
  }

  &.badge-free-gift {
    @extend %badge-aco-orange;

    margin-left: 0.1rem;
    border-top-right-radius: $border-radius-pill;
    border-bottom-right-radius: $border-radius-pill;
  }

  &.badge-price-discount {
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    line-height: 1.5rem;
    background: transparent;
    margin: 0;
    padding: 0;

    & .price {
      @extend %badge-aco-orange;

      margin-left: 0.1rem;
      padding: map.get($spacers, "spacing-2") map.get($spacers, "spacing-8") map.get($spacers, "spacing-2") map.get($spacers, "spacing-6");
      width: 100%;
      border-top-right-radius: $border-radius-pill;
      border-bottom-right-radius: $border-radius-pill;
    }

    & .discount {
      @extend %badge-aco-orange;

      margin-right: 2px;
      padding: map.get($spacers, "spacing-2") map.get($spacers, "spacing-12") map.get($spacers, "spacing-2") map.get($spacers, "spacing-8");
      width: 100%;
      border-top-left-radius: $border-radius-pill;
      border-bottom-left-radius: $border-radius-pill;
    }

    &.ribbon {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -$badge-ribbon-margin-outside;
      position: relative;
      overflow: visible;

      &::after {
        content: "";
        position: absolute;
        display: block;
        border-style: solid;
        border-color: transparent transparent $aco-dark-blue;
        top: - calc($badge-ribbon-margin-outside / 2);
        left: 0;
        border-width: 0 0 calc($badge-ribbon-margin-outside / 2) $badge-ribbon-margin-outside;
      }

      & .discount {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &.text-only {
          border-top-right-radius: $border-radius-pill;
          border-bottom-right-radius: $border-radius-pill;
          background-color: $aco-orange;
          text-transform: uppercase;
        }
      }

      &.price-only {
        &::after {
          border-color: transparent transparent $aco-dark-orange;
          margin-left: 0;
        }
      }
    }

    &.main {
      font-size: 1.5rem;

      & .price,
      & .discount {
        padding: map.get($spacers, "spacing-6") map.get($spacers, "spacing-16");
      }
    }
  }

  .price.no-percentage {
    @extend %badge-aco-orange;

    margin-left: 0.1rem;
    padding: map.get($spacers, "spacing-6") map.get($spacers, "spacing-16");
    width: 100%;
    border-radius: $border-radius-pill;
    font-weight: $font-weight-bold;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

.badge-aco-blue,
%badge-aco-blue {
  background-color: $aco-blue;
  color: $aco-white;
}

.badge-aco-orange,
%badge-aco-orange {
  background-color: $aco-orange;
  color: $aco-white;
}

.badge-aco-light-blue,
%badge-aco-light-blue {
  background-color: $aco-light-blue;
  color: $aco-dark-blue;
}

.badge-aco-gray-06,
%badge-aco-gray-06 {
  background-color: $aco-gray-06;
  color: $aco-dark-blue;
}

.overlay-badge {
  @extend %badge;

  position: absolute;
  top: 20%;
  left: 0;
  transform: translateY(-50%);
  padding: 0 $badge-padding-x;
  border-top-right-radius: $border-radius-pill;
  border-bottom-right-radius: $border-radius-pill;
}
