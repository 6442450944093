.button-userlike-chat {
  span {
    border: none;
    clear: none;
    float: none;
    height: 50px !important;
    width: 50px !important;
    overflow: hidden;
    outline-style: none;
    position: fixed;
    text-decoration: none;
    text-indent: -100000px;
    z-index: 98;
    margin: 0;
    bottom: 90px;
    right: 16px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 30%) !important;
  }

  div {
    position: fixed;
    border: 2px solid $aco-blue;
    background-color: $aco-white;
    width: 26px;
    height: 26px;
    border-radius: 15px;
    bottom: 20px;
    left: 180px;
    color: $aco-gray-01;
    font-size: $font-size-base;
    line-height: $line-height-base;
    z-index: 99;
    text-align: center;
    display: none;
  }
}
