/* Toasts */
@use "sass:map";

/**
 Variables
 */
$toast-max-width: 382px;
$toast-padding-x: map.get($spacers, "spacing-16");
$toast-padding-y: map.get($spacers, "spacing-16");
$toast-font-size: $font-size-sm;
$toast-color: $aco-white;
$toast-background-color: $aco-blue;
$toast-border-width: $border-width;
$toast-border-color: transparent;
$toast-border-radius: map.get($radius-map, 8);
$toast-box-shadow: none;
$toast-spacing: $container-padding-x;
$toast-header-color: $toast-color;
$toast-header-background-color: $toast-background-color;
$toast-header-border-color: transparent;
$toast-buttons-padding-y: map.get($spacers, "spacing-16");
$toast-icon-size: 24px;
$toast-close-button-scale: 0.7;
$toast-content-padding-left: calc($toast-icon-size + 1rem);
$toast-content-padding-right: calc(($btn-close-width + ($btn-close-padding-x * 2)) * $toast-close-button-scale + 0.75rem);

/**
 BS default import
 */
@import "~bootstrap/scss/toasts";

@media screen and (max-width: map.get($grid-breakpoints, "sm")) {
  .toast-container {
    justify-content: center;
    bottom: 48px;
    right: 0;
    left: 0;
  }

  .toast {
    max-width: 100%;
  }
}

@media screen and (min-width: calc(map.get($grid-breakpoints, "sm") + 1px)) {
  .toast-container {
    justify-content: flex-end;
    bottom: 72px;
    right: 96px;
  }

  .toast {
    max-width: $toast-max-width;
  }
}

.toast-container {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100%;
}

.toast {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: $toast-padding-y $toast-padding-x;
  width: 100%;
  position: relative;

  & .btn-close,
  & .toast-close {
    @extend %btn-close-icon-light;

    position: absolute;
    top: $toast-padding-y;
    right: $toast-padding-x;
    transform: scale(0.7);
  }

  & .toast-buttons {
    background: transparent;
    color: $toast-color;
    font-size: $toast-font-size;
    font-weight: $font-weight-bold;
    padding: 0;
    width: 100%;
  }

  & .toast-copy {
    font-weight: $font-weight-normal;

    & ~ toast-buttons {
      padding-top: $toast-buttons-padding-y;
    }
  }

  & .toast-header {
    position: relative;
    background: transparent;
    border: none;
    color: $toast-color;
    font-size: $toast-font-size;
    font-weight: $font-weight-bold;
    padding: 0 $toast-content-padding-right 0 0;

    & ~ .toast-buttons {
      padding-top: $toast-buttons-padding-y;
    }
  }

  & .toast-icon {
    position: absolute;
    top: $toast-padding-y;
    left: $toast-padding-x;
    width: fit-content;
    padding: 0;

    & svg {
      width: $toast-icon-size;
      height: $toast-icon-size;
    }

    & ~ .toast-header,
    & ~ .toast-copy {
      padding: 0 $toast-content-padding-right 0 $toast-content-padding-left;
    }
  }
}
