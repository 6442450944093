figure {
  &.zoom {
    background-position: 50% 50%;
    position: relative;
    overflow: hidden;
    cursor: url('data:image/svg+xml,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="40" rx="20" fill="#{$aco-white}" fill-opacity="0.7" /><path d="M27.7578 26.7188L23.5703 22.5312C24.4766 21.4375 24.9766 20.0312 24.9766 18.5C24.9766 14.9375 22.0391 12 18.4766 12C14.8828 12 12.0078 14.9375 12.0078 18.5C12.0078 22.0938 14.9141 25 18.4766 25C19.9766 25 21.3828 24.5 22.5078 23.5938L26.6953 27.7812C26.8516 27.9375 27.0391 28 27.2578 28C27.4453 28 27.6328 27.9375 27.7578 27.7812C28.0703 27.5 28.0703 27.0312 27.7578 26.7188ZM13.5078 18.5C13.5078 15.75 15.7266 13.5 18.5078 13.5C21.2578 13.5 23.5078 15.75 23.5078 18.5C23.5078 21.2812 21.2578 23.5 18.5078 23.5C15.7266 23.5 13.5078 21.2812 13.5078 18.5Z" fill="#{$aco-dark-blue}"/></svg>') 40 40, zoom-in;

    img {
      transition: opacity 0.5s;
      display: block;
      width: 100%;
    }

    & img:hover {
      opacity: 0;
    }
  }
}
