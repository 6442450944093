/* Checkout */

@use "sass:map";

#wallet-widget-div,
#address-book-widget-div {
  height: 300px;
  width: 100%;
}

.checkout-success-menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 356px;
  padding: 0;
  margin: map.get($spacers, "spacing-32") 0 map.get($spacers, "spacing-12") 0;

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }

  & li {
    margin-bottom: map.get($spacers, "spacing-16");
    width: 100%;
  }

  & li:last-child {
    margin-bottom: map.get($spacers, "spacing-0");
  }
}

.checkout-apopunkte {
  font-weight: $font-weight-normal;
}

.checkout-overlay {
  .check-icon-blue {
    @include circle-gradient($center-bg-color: $aco-dark-blue, $size: 166px, $border-main-color: $aco-light-blue, $border-secondary-color: transparent, $border-size: 20%);

    &::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{to-rgb($aco-light-blue)}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 47%;
    }
  }
}

.add-address-box-wrapper {
  &[data-visible="true"] {
    margin-bottom: map.get($spacers, "spacing-32");
  }

  & .add-address-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: map.get($spacers, "spacing-24") map.get($spacers, "spacing-32");
    background-color: $aco-gray-06;
    border-radius: map.get($radius-map, 8);

    @media screen and (max-width: map.get($grid-breakpoints, md)) {
      padding: map.get($spacers, "spacing-12") map.get($spacers, "spacing-32");
    }
  }
}

.checkout-success-form {
  display: flex;
  gap: map.get($spacers, "spacing-24");

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    gap: map.get($spacers, "spacing-48");
  }
}

.convert-user-account-footer {
  font-size: $font-size-xs;
  line-height: 18px;
  margin-bottom: map.get($spacers, "spacing-16");

  @include media-breakpoint-down(sm) {
    margin-bottom: map.get($spacers, "spacing-6");
  }

  a {
    color: $aco-white;
    text-decoration: underline;
    font-weight: bold;
  }
}

.klarna-express-service-address {
  & span {
    word-break: break-word;

    &.telephone,
    &.email {
      word-break: break-all;
    }
  }
}

.checkout-confirmation {
  .checkout-confirmation-form {
    & a {
      @extend %link-aco-light-blue;
    }
  }

  [data-selector="loading-payment-button"] .spinner-lg {
    scale: 0.5;
  }

  & .checkout-confirmation-product-row {
    display: flex;
    align-items: center;
    padding: map.get($spacers, "spacing-24") 0;

    &:not(:last-child) {
      border-bottom: 1px solid $aco-gray-06;
    }

    &:last-child {
      padding-bottom: 0;
    }

    & img {
      border: 1px solid $aco-gray-06;
      border-radius: map.get($radius-map, 12);
    }

    @include media-breakpoint-down(lg) {
      padding: map.get($spacers, "spacing-20") 0;
    }
  }

  & .email-hint {
    display: flex;

    & [class*="svg-inline--fa"] {
      transform: translateY(2px);
    }
  }
}
