/* Tables */
@use "sass:map";

/**
 Variables
 */
$table-cell-padding-y: 0.5rem;
$table-cell-padding-x: 0.5rem;
$table-cell-padding-y-sm: 0.25rem;
$table-cell-padding-x-sm: 0.25rem;
$table-cell-vertical-align: center;
$table-color: $body-color;
$table-bg: transparent;
$table-accent-bg: transparent;
$table-th-font-weight: $font-weight-bold;
$table-striped-color: $table-color;
$table-striped-bg-factor: 0.05;
$table-striped-bg: rgba($black, $table-striped-bg-factor);
$table-active-color: $table-color;
$table-active-bg-factor: 0.1;
$table-active-bg: rgba($black, $table-active-bg-factor);
$table-hover-color: $table-color;
$table-hover-bg-factor: 0.075;
$table-hover-bg: rgba($black, $table-hover-bg-factor);
$table-border-factor: 0.1;
$table-border-width: $border-width;
$table-border-color: $border-color;
$table-striped-order: odd;
$table-group-separator-color: currentcolor;
$table-caption-color: $text-muted;
$table-bg-scale: -80%;
$table-variants: (
  "light": $light,
  "dark": $dark,
);
$table-cell-padding: map.get($spacers, "spacing-8");
$table-row-min-height: 48px;
$table-dark-row-border-color: $aco-blue-disabled;
$table-row-border-color: $aco-gray-06;
$table-spacing: 34px 20px;
$table-spacing-xs: 10px 5px;

/**
 BS default import
 */
@import "~bootstrap/scss/tables";

.table {
  border-collapse: separate;
  border-spacing: 0;
  padding: $table-spacing;
  color: $aco-dark-blue;

  @media only screen and (max-width: map.get($grid-breakpoints, "sm")) {
    padding: $table-spacing-xs;
  }

  & thead,
  & tbody,
  & tfoot,
  & tr,
  & td,
  & th {
    border-color: $table-row-border-color;
  }

  & th {
    padding: $table-cell-padding !important;
    text-align: center;
    font-size: $font-size-xs;
    font-weight: $table-th-font-weight;
  }

  & td {
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
  }

  & tr {
    height: $table-row-min-height;
    text-align: center;
    vertical-align: middle;
    border-bottom: $table-border-width solid $table-row-border-color;
  }

  &.table-dark {
    @extend %table-dark;
  }

  @at-root .dark {
    & table:not(.table-light) {
      @extend %table-dark;
    }
  }
}

%table-dark {
  background-color: $dark;
  color: $aco-white;

  & thead,
  & tbody,
  & tfoot,
  & tr,
  & td,
  & th {
    border-color: $table-dark-row-border-color;
  }

  & tr {
    border-bottom: $table-border-width solid $table-dark-row-border-color;
  }
}
