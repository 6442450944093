@use "sass:map";

[data-selector="magazine-categories-wrapper"] {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

[data-selector="magazine-category-scroll"] {
  top: 0;
  right: 0;
}

.category-card {
  width: 25%;
  flex: 0 0 calc(25% - 12px);
  min-width: 240px;
  display: flex;
  border: 1px solid $aco-gray-04;
  border-radius: 24px;

  &:hover {
    background-color: $aco-gray-06;
  }

  &:focus {
    border: 1px solid $aco-light-blue;
  }

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "md")} + 1px)) {
    min-width: 240px;
  }

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "lg")} + 1px)) {
    min-width: 220px;
  }

  &__wrapper {
    width: 100%;
  }

  &__image {
    max-width: 60px;
  }

  &__title {
    color: $aco-dark-blue;
    font-weight: bold;
    hyphens: auto; /* Automatische Silbentrennung */
    hyphenate-limit-chars: auto 3;
    hyphenate-limit-lines: 4;
  }

  &__count {
    color: $aco-gray-01;
  }
}
