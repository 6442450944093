@use "sass:map";

/* Modals */

/**
 Variables
 */
$modal-inner-padding: $spacer;
$modal-footer-margin-between: map.get($spacers, "spacing-8");
$modal-dialog-margin: map.get($spacers, "spacing-8");
$modal-dialog-margin-y-sm-up: 1.75rem;
$modal-title-line-height: $line-height-base;
$modal-content-color: $aco-white;
$modal-content-bg: $aco-blue;
$modal-content-border-color: transparent;
$modal-content-border-width: 0;
$modal-content-border-radius: map.get($radius-map, 24);
$modal-content-inner-border-radius: subtract(
  $modal-content-border-radius,
  $modal-content-border-width
);
$modal-content-box-shadow-xs: $box-shadow-sm;
$modal-content-box-shadow-sm-up: $box-shadow;
$modal-backdrop-bg: $aco-dark-blue;
$modal-backdrop-opacity: 0.6;
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: $modal-inner-padding;
$modal-header-padding-x: $modal-inner-padding;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x;
$modal-sm: 300px;
$modal-md: 500px;
$modal-consent: 600px;
$modal-lg: 800px;
$modal-xl: 1140px;
$modal-xxl: 1280px;
$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;
$modal-scale-transform: scale(1.02);
$modal-z-index: 9999;

/**
 BS default import
 */
@import "~bootstrap/scss/modal";

.modal {
  z-index: $modal-z-index;
}

.modal-dialog {
  &.modal-dialog-centered {
    justify-content: center;
  }

  & .modal-title {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    padding: 1.5rem;
    padding-bottom: 0;
    padding-top: 0;
  }

  & .modal-body {
    font-size: $font-size-sm;
    padding: map.get($spacers, "spacing-24");
    padding-top: 0;
  }

  & .modal-content {
    &.modal-white {
      background-color: $aco-white;

      & .modal-title {
        color: $aco-dark-blue;
      }

      & .modal-body {
        color: $aco-dark-blue;
      }
    }
  }

  & .modal-header {
    padding-bottom: 0;
  }

  &.modal-fullscreen {
    width: 100%;
    padding-top: 1rem;
    max-width: $modal-xxl;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    & .modal-content {
      border-top-left-radius: map.get($radius-map, 24);
      border-top-right-radius: map.get($radius-map, 24);
    }
  }
}

[data-selector="product_detail_overlays_callback_service"] {
  .modal-dialog {
    .modal-title.h3 {
      max-width: 290px;
      font-size: $h3-font-size;
      font-weight: 400;

      .modal-body {
        font-size: $font-size-base;
      }
    }

    [data-selector="callback_form_legal"] {
      position: relative;
      font-size: $font-size-sm;

      sup {
        position: absolute;
        left: -(map.get($spacers, "spacing-16"));
        top: map.get($spacers, "spacing-8");
      }
    }

    .modal-footer {
      gap: 24px;

      & > * {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .modal-content {
      margin: auto map.get($spacers, "spacing-16");
    }

    .modal-footer {
      flex-direction: column;

      .col {
        width: 100%;
        display: flex;
        justify-content: center;

        &:first-child {
          order: 2;
        }

        &:last-child {
          order: 1;
        }
      }
    }
  }
}
