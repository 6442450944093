/* stylelint-disable no-descending-specificity */

/* Slider */
@use "sass:map";

/**
 Variables
 */
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
$secondary-gradient-circle-size: 57.69%;
$bullet-border-width: map.get($radius-map, 2);

/**
 slick-carousel default import
 */
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme.css";

/*
 Swiper default import
*/

/* SCSS styles are as separate styles for core version and modules (see package.json exports) */
@import "~swiper/scss"; // imports node_modules/swiper/swiper.scss
@import "~swiper/scss/navigation"; // imports node_modules/swiper/modules/navigation/navigation.scss
@import "~swiper/scss/pagination"; // imports node_modules/swiper/modules/pagination/pagination.scss

/**
 Custom Styles
 */

// anti-CLS
.product-images-swiper {
  .swiper-slide {
    aspect-ratio: 1;

    figure {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.swiper-controls-wrapper {
  // anti-CLS
  min-height: 37px;
}

// show slider only when initialized
[data-slider] {
  &:not([data-slider="product-images"], [data-slider="banner"], [data-slider="contentful-banner"], [data-slider="brandshop"]) {
    opacity: 0;
    transition: opacity 200ms;

    &.swiper-initialized {
      opacity: 1;
    }
  }
}

[data-slider="product-images"] {
  & .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    opacity: 1;
    background: $aco-white;
    border: $bullet-border-width solid $aco-dark-blue;

    &:hover {
      background-color: $aco-dark-blue;

      svg {
        color: $aco-white !important;
      }
    }

    &-active {
      background: $aco-light-blue;
      border: transparent;

      &:not(:hover) {
        border-color: $aco-light-blue;
      }
    }
  }
}

[data-slider="default-pagination"],
%default-pagination-swiper-slide {
  & .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.swiper-button-next {
  right: 3.5rem;
}

.swiper-button-prev {
  left: 3.5rem;
}

.swiper-button-next,
.swiper-button-disabled {
  opacity: 1 !important;
}

.swiper-button-prev,
.swiper-button-disabled {
  opacity: 1 !important;
}

.products-slider,
.products-slider-dark {
  padding: 3rem 1.5rem;

  .swiper-wrapper {
    height: auto;
  }

  & .product-card {
    max-width: none;

    & .card-body {
      & img {
        width: 100%;
        height: 100%;
        max-width: 180px;
        max-height: 180px;
      }
    }
  }

  &.debug-refill {
    position: relative;

    &::after {
      content: "debug-refill";
      position: absolute;
      z-index: 11111;
      width: 100%;
      height: auto;
      margin: 0 0 1rem;
      background-color: red;
      font-size: 8px;
      top: 0;
      left: 0;
    }
  }
}

.line-pagination-slider,
%line-pagination-slider {
  width: 100%;

  & .swiper-pagination-fraction,
  & .swiper-pagination-custom,
  & .swiper-horizontal > .swiper-pagination-bullets,
  & .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: auto;
    width: unset;
    flex-grow: 1;
  }

  .swiper-pagination-bullet:first-child {
    border-top-left-radius: 1px !important;
    border-bottom-left-radius: 1px !important;
  }

  .swiper-pagination-bullet:last-child {
    border-top-right-radius: 1px !important;
    border-bottom-right-radius: 1px !important;
  }

  & .swiper-slide {
    height: auto;
  }

  & .swiper-pagination {
    position: relative;
    height: 2px;
    display: flex;
    flex-grow: 1;
    align-items: center;

    & .swiper-pagination-bullet {
      width: 100%;
      margin: 0;
      border-radius: 0;
      height: 2px;
      background-color: $aco-gray-06;
      opacity: 1;

      &-active {
        background-color: $aco-blue;
      }
    }
  }

  & .swiper-controls-wrapper {
    position: relative;

    & .swiper-button-prev,
    & .swiper-button-next {
      position: static;
      width: 32px;
      height: 32px;
      border-radius: $border-radius-pill;
      background-color: $aco-light-blue;
      margin: 0;
    }

    & .swiper-button-prev::after,
    & .swiper-button-next::after {
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
      color: $aco-dark-blue;
    }

    & .swiper-controls {
      width: 75%;
      gap: map.get($spacers, "spacing-16");

      @media screen and (max-width: map.get($grid-breakpoints, "md")) {
        width: 100%;
      }
    }
  }
}

.line-pagination-slider-dark,
%line-pagination-slider-dark {
  @extend %line-pagination-slider;

  &,
  & .swiper {
    background-color: $aco-dark-blue;
  }

  & .swiper-pagination {
    & .swiper-pagination-bullet {
      background: $aco-dark-blue-disabled;
      opacity: 1;

      &-active {
        background-color: $aco-light-blue;
      }
    }
  }

  & .swiper-controls-wrapper {
    & .swiper-button-prev,
    & .swiper-button-next {
      background-color: $aco-white;
    }

    & .swiper-button-prev::after,
    & .swiper-button-next::after {
      color: $aco-dark-blue;
    }
  }
}

.products-slider,
%products-slider {
  @extend %line-pagination-slider;

  & [data-slider="products"],
  & [data-slider="productsDY"] {
    padding-left: $badge-ribbon-margin-outside;
    padding-right: $badge-ribbon-margin-outside;

    .secondary-gradient-circle {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
      transform: translate(40%, 35%);
      width: $secondary-gradient-circle-size;
      height: $secondary-gradient-circle-size;
      opacity: 0.6;
      border-radius: $border-radius-pill;
      background-image: line-gradient(to right, $aco-dark-blue 0%, $aco-blue 100%);

      @media screen and (max-width: map.get($grid-breakpoints, "lg")) {
        width: 44%;
        height: 44%;
        transform: translate(20%, 10%);
      }
    }
  }
}

.products-slider-dark {
  @extend %line-pagination-slider-dark;
  @extend %products-slider;

  & [data-slider="products"] {
    & .swiper-controls-wrapper {
      & .secondary-gradient-circle {
        background-image: line-gradient(to right, $aco-dark-blue 0%, $aco-blue 100%);
      }
    }
  }
}

.medication-reminders-slider {
  @extend %line-pagination-slider;
}

.medication-reminders-slider-dark {
  @extend %line-pagination-slider-dark;
}

[data-slider="default-pagination"],
%default-pagination {
  & .swiper-pagination,
  & .swiper-pagination-banner {
    & .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      opacity: 1;
      background: $aco-gray-04;

      &:hover {
        background-color: $aco-dark-blue;
      }

      &-active {
        background: $aco-light-blue;
        border: transparent;
      }
    }

    &.dark .swiper-pagination-bullet {
      background: $aco-white;

      &:hover {
        background-color: $aco-dark-blue;
      }

      &-active {
        background: $aco-light-blue;
      }
    }
  }
}

[data-slider="quicklinks"] {
  @extend %line-pagination-slider;

  $check-icon-size: 128px;
  $circle-icon-rotation: -60deg;

  padding-left: $badge-ribbon-margin-outside;
  padding-right: $badge-ribbon-margin-outside;

  .swiper-wrapper {
    max-height: 176px;
  }

  .swiper-slide {
    width: 220px;
    color: $aco-blue;

    span {
      transition: all 200ms;
    }

    a {
      color: currentcolor;
    }

    & .icon-circle svg {
      width: $check-icon-size;
      height: $check-icon-size;
      transform: scale(1);
      transition: transform 100ms ease-out;
    }

    &.aco-light-blue a {
      color: $aco-light-blue;

      .icon-circle {
        color: currentcolor;

        &::before {
          background-image: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 176 176' color='#{to-rgb($aco-light-blue)}' stroke-width='14' fill='none'><g><linearGradient id='spinner-secondHalf' gradientUnits='userSpaceOnUse' x1='-1393.3174' y1='1582.0088' x2='-1392.2252' y2='1582.0088' gradientTransform='matrix(170.1357 0 0 -85.0679 237048.1875 134585.125)'><stop  offset='0' stop-color='currentColor' stop-opacity='0' /><stop  offset='1' stop-color='currentColor' stop-opacity='0.5' /></linearGradient><linearGradient id='spinner-firstHalf' gradientUnits='userSpaceOnUse' x1='-1393.3174' y1='1581.1044' x2='-1392.2252' y2='1581.1044' gradientTransform='matrix(170.1357 0 0 -85.0679 237048.1875 134670.1875)'><stop  offset='0' stop-color='currentColor' /><stop  offset='1' stop-color='currentColor' stop-opacity='0.5' /></linearGradient><path stroke='url(#spinner-secondHalf)' d='M7,88C7,43.3,43.3,7,88,7s81,36.3,81,81'/><path stroke='url(#spinner-firstHalf)' d='M169,88c0,44.7-36.3,81-81,81S7,132.7,7,88'/><path stroke='currentColor' stroke-linecap='round' d='M7,88C7,86.3,7,84.6,7,82.8'/></g></svg>");
        }
      }
    }

    &.aco-orange a {
      color: $aco-orange;

      .icon-circle {
        color: currentcolor;

        &::before {
          background-image: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 176 176' color='#{to-rgb($aco-orange)}' stroke-width='14' fill='none'><g><linearGradient id='spinner-secondHalf' gradientUnits='userSpaceOnUse' x1='-1393.3174' y1='1582.0088' x2='-1392.2252' y2='1582.0088' gradientTransform='matrix(170.1357 0 0 -85.0679 237048.1875 134585.125)'><stop  offset='0' stop-color='currentColor' stop-opacity='0' /><stop  offset='1' stop-color='currentColor' stop-opacity='0.5' /></linearGradient><linearGradient id='spinner-firstHalf' gradientUnits='userSpaceOnUse' x1='-1393.3174' y1='1581.1044' x2='-1392.2252' y2='1581.1044' gradientTransform='matrix(170.1357 0 0 -85.0679 237048.1875 134670.1875)'><stop  offset='0' stop-color='currentColor' /><stop  offset='1' stop-color='currentColor' stop-opacity='0.5' /></linearGradient><path stroke='url(#spinner-secondHalf)' d='M7,88C7,43.3,43.3,7,88,7s81,36.3,81,81'/><path stroke='url(#spinner-firstHalf)' d='M169,88c0,44.7-36.3,81-81,81S7,132.7,7,88'/><path stroke='currentColor' stroke-linecap='round' d='M7,88C7,86.3,7,84.6,7,82.8'/></g></svg>");
        }
      }
    }

    &:hover {
      span {
        color: $aco-dark-blue;
      }
    }
  }

  .icon-circle {
    position: relative;
    height: $check-icon-size;
    width: $check-icon-size;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $aco-blue;
    fill: currentcolor;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      transform: rotate($circle-icon-rotation) scale(-1, 1);
      height: $check-icon-size;
      width: $check-icon-size;
      background-image: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 176 176' color='#{to-rgb($aco-blue)}' stroke-width='14' fill='none'><g><linearGradient id='spinner-secondHalf' gradientUnits='userSpaceOnUse' x1='-1393.3174' y1='1582.0088' x2='-1392.2252' y2='1582.0088' gradientTransform='matrix(170.1357 0 0 -85.0679 237048.1875 134585.125)'><stop  offset='0' stop-color='currentColor' stop-opacity='0' /><stop  offset='1' stop-color='currentColor' stop-opacity='0.5' /></linearGradient><linearGradient id='spinner-firstHalf' gradientUnits='userSpaceOnUse' x1='-1393.3174' y1='1581.1044' x2='-1392.2252' y2='1581.1044' gradientTransform='matrix(170.1357 0 0 -85.0679 237048.1875 134670.1875)'><stop  offset='0' stop-color='currentColor' /><stop  offset='1' stop-color='currentColor' stop-opacity='0.5' /></linearGradient><path stroke='url(#spinner-secondHalf)' d='M7,88C7,43.3,43.3,7,88,7s81,36.3,81,81'/><path stroke='url(#spinner-firstHalf)' d='M169,88c0,44.7-36.3,81-81,81S7,132.7,7,88'/><path stroke='currentColor' stroke-linecap='round' d='M7,88C7,86.3,7,84.6,7,82.8'/></g></svg>");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

[data-slider="modal"] {
  width: calc(100% - 3rem);
  padding: 0 0 1.5rem;

  &::after,
  &::before {
    content: "";
    position: absolute;
    width: 0.5rem;
    height: calc(100% - 67px - 1.5rem);
    top: 0;
    z-index: 2;
  }

  &::after {
    right: 0;
    background: linear-gradient(270deg, #039f 0%, #039f 13%, #0390 100%);
  }

  &::before {
    left: 0;
    background: linear-gradient(90deg, #039f 0%, #039f 13%, #0390 100%);
  }

  .swiper-slide {
    color: $aco-white;

    a {
      color: currentcolor;
    }

    &.aco-light-blue a {
      color: $aco-light-blue;

      .icon-circle {
        color: currentcolor;

        &::before {
          background-image: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 176 176' color='#{to-rgb($aco-light-blue)}' stroke-width='14' fill='none'><g><linearGradient id='spinner-secondHalf' gradientUnits='userSpaceOnUse' x1='-1393.3174' y1='1582.0088' x2='-1392.2252' y2='1582.0088' gradientTransform='matrix(170.1357 0 0 -85.0679 237048.1875 134585.125)'><stop  offset='0' stop-color='currentColor' stop-opacity='0' /><stop  offset='1' stop-color='currentColor' stop-opacity='0.5' /></linearGradient><linearGradient id='spinner-firstHalf' gradientUnits='userSpaceOnUse' x1='-1393.3174' y1='1581.1044' x2='-1392.2252' y2='1581.1044' gradientTransform='matrix(170.1357 0 0 -85.0679 237048.1875 134670.1875)'><stop  offset='0' stop-color='currentColor' /><stop  offset='1' stop-color='currentColor' stop-opacity='0.5' /></linearGradient><path stroke='url(#spinner-secondHalf)' d='M7,88C7,43.3,43.3,7,88,7s81,36.3,81,81'/><path stroke='url(#spinner-firstHalf)' d='M169,88c0,44.7-36.3,81-81,81S7,132.7,7,88'/><path stroke='currentColor' stroke-linecap='round' d='M7,88C7,86.3,7,84.6,7,82.8'/></g></svg>");
        }
      }
    }
  }

  .swiper-controls-wrapper {
    position: relative;
  }

  .swiper-controls {
    position: relative;
    flex-flow: row wrap;
    width: 100%;
    column-gap: 1.5rem;
    row-gap: 2.5rem;

    @media screen and (min-width: 768px) {
      column-gap: 1rem;
      row-gap: 2rem;
    }

    .btn {
      position: relative;
      left: unset;
      right: unset;
      flex: 1 1 auto;
      height: 1.5rem;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      content: none;
    }
  }

  .swiper-pagination {
    position: relative;
    justify-content: center;

    .swiper-pagination-bullet {
      background: $aco-white;
    }

    .swiper-pagination-bullet-active {
      background: $aco-light-blue;
    }
  }
}

[data-slider="banner"] {
  @extend %default-pagination;
  @extend %default-pagination-swiper-slide;

  &.banner-swiper {
    &:first-child {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  & .swiper-pagination-banner {
    display: flex;
  }
}

[data-slider="banner"] .swiper-slide {
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
  transition: all 300ms;
  display: block;
  opacity: 1;

  &.hidden {
    opacity: 0;
    display: none;
  }

  & > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & [data-bs-toggle="modal"] {
    height: 48px;
  }

  video {
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include media-breakpoint-up(sm) {
    padding-bottom: 73%;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 56%;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 42.7%;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 36.2%;
  }

  @include media-breakpoint-up(xxl) {
    padding-bottom: 42.5%;
  }
}

.account-reminder-wrapper {
  .swiper-pagination-bullet {
    background-color: $aco-white;

    &.swiper-pagination-bullet-active {
      background-color: $aco-light-blue;
    }
  }
}

@include z-index-list;
