/* Product */
@use "sass:map";

/* Variables */

$product-border-radius: map.get($radius-map, 24);
$bullet-border-width: map.get($radius-map, 2);
$bullet-point-modal-circle-gradient-size: 128px;
$bullet-point-modal-icon-size: 57px;
$bullet-point-modal-apo-punkte-overlay-icon-size: 26px;
$bullet-point-modal-apo-punkte-overlay-icon-border: 4px;
$bullet-point-popover-circle-gradient-size: 70px;
$score-circle-size: 105px;
$progress-bar-height: 12px;
$progress-bar-border-radius: calc($progress-bar-height / 2);
$product-review-btn-max-width: 120px;

/* Page specific CSS */
body[data-pagetype="pdp"] {
  margin: 0;
}

/* Custom Classes */

.product-detail-banner {
  position: relative;
  z-index: -1;
}

.price-badge-wrapper {
  min-height: 64px;
}

.product-detail-base {
  position: relative;
  z-index: -2;

  & ul {
    margin-bottom: 0;
  }
}

.move-up {
  margin-top: -$product-border-radius;
}

.move-down {
  margin-top: $product-border-radius;
}

.reviews-wrapper {
  & img {
    max-width: 250px;
    max-height: 250px;
  }
}

[data_success_header] {
  max-width: 1360px;

  .container {
    max-width: 550px;
  }
}

[data-selector="goto_create_review"] {
  max-width: 546px;
}

.product-summary-info {
  min-width: fit-content;
  color: rgba($aco-white, 80%);
}

.fixed-product-header,
[data-id="fixed-product-header"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transform: translateY(-100%);
  width: 100%;
  transition-duration: 0.3s;
  transition-property: transform;
  padding-top: map.get($spacers, "spacing-8");
  padding-bottom: map.get($spacers, "spacing-8");
  box-shadow: rgba($aco-black, 20%) map.get($spacers, "spacing-0") map.get($spacers, "spacing-8") map.get($spacers, "spacing-24") map.get($spacers, "spacing-0");

  & .badge {
    font-size: $font-size-base;
  }

  & .price-box {
    min-width: 210px;
  }

  &.visible {
    transform: translateY(0);
  }

  @media screen and (min-width: map.get($grid-breakpoints, "lg")) {
    top: 0;
  }

  @media screen and (min-width: map.get($grid-breakpoints, "md")) {
    align-items: center;
  }

  @media screen and (max-width: map.get($grid-breakpoints, "md")) {
    font-size: $font-size-xs;

    & .btn,
    & .badge {
      font-size: $font-size-xs;
    }
  }
}

.add-to-watch-list {
  &.btn.btn-circle {
    width: 50px;
    height: 50px;
    color: $aco-white;
    border-radius: $border-radius-pill;
  }
}

.product-bullet-point {
  width: fit-content;
}

#left-product-section {
  .popover {
    max-width: 300px;
  }

  .modal-body {
    font-size: $font-size-base;
  }
}

.bullet-point-icon,
%bullet-point-icon {
  @include circle-gradient(
    $size: $bullet-point-modal-circle-gradient-size,
    $border-main-color: $aco-light-blue,
    $border-secondary-color: transparent,
    $center-bg-color: $aco-blue,
    $border-size: 20%
  );

  & .content {
    display: flex;
    justify-content: center;
    align-items: center;

    &.apo-punkte {
      transform: translate(calc(-50% - ($bullet-point-modal-apo-punkte-overlay-icon-size / 2) + $bullet-point-modal-apo-punkte-overlay-icon-border), -50%) rotate(-$circle-gradient-rotation);
    }

    & svg:first-child {
      width: $bullet-point-modal-icon-size;
      height: $bullet-point-modal-icon-size;
      color: $aco-light-blue;
    }

    &.availability {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;

      & svg:first-child {
        width: $bullet-point-modal-icon-size;
        height: $bullet-point-modal-icon-size;
      }

      & svg.fa-pills {
        transform: translateX(25%) rotate(134deg);
      }
    }

    &.apo-punkte svg:last-child {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      width: $bullet-point-modal-apo-punkte-overlay-icon-size;
      height: $bullet-point-modal-apo-punkte-overlay-icon-size;
      color: $aco-light-blue;
      border: $bullet-point-modal-apo-punkte-overlay-icon-border solid $aco-blue;
      border-radius: $border-radius-pill;
    }
  }

  &.red-icon {
    @extend %bullet-point-icon;
    @include circle-gradient(
      $size: calc($bullet-point-modal-circle-gradient-size),
      $border-main-color: $aco-red,
      $border-secondary-color: transparent,
      $center-bg-color: $aco-blue,
      $border-size: 20%
    );

    & .content svg:first-child {
      color: $aco-red;
    }
  }
}

.bullet-point-modal {
  & .modal-content {
    max-width: 300px;
  }

  & .icon {
    @extend %bullet-point-icon;
  }
}

.bullet-point-popover-title {
  display: flex;
  align-items: center;
  padding-top: map.get($spacers, "spacing-16");

  & .icon {
    @extend %bullet-point-icon;
    @include circle-gradient(
      $size: calc($bullet-point-modal-circle-gradient-size / 2),
      $border-main-color: $aco-light-blue,
      $border-secondary-color: transparent,
      $center-bg-color: $aco-blue,
      $border-size: 20%
    );

    &.red-icon {
      @extend %bullet-point-icon;
      @include circle-gradient(
        $size: calc($bullet-point-modal-circle-gradient-size / 2),
        $border-main-color: $aco-red,
        $border-secondary-color: transparent,
        $center-bg-color: $aco-blue,
        $border-size: 20%
      );
    }

    & .content {
      &.apo-punkte {
        transform: translate(calc(-50% - ($bullet-point-modal-apo-punkte-overlay-icon-size / 4) + ($bullet-point-modal-apo-punkte-overlay-icon-border / 2)), -50%) rotate(-$circle-gradient-rotation);
      }

      & svg:first-child {
        width: calc($bullet-point-modal-icon-size / 2);
        height: calc($bullet-point-modal-icon-size / 2);
      }

      &.apo-punkte svg:last-child {
        width: calc($bullet-point-modal-apo-punkte-overlay-icon-size / 2);
        height: calc($bullet-point-modal-apo-punkte-overlay-icon-size / 2);
      }

      &.availability {
        & svg:first-child {
          width: calc($bullet-point-modal-icon-size / 2);
          height: calc($bullet-point-modal-icon-size / 2);
        }

        & svg:first-child + svg {
          width: calc($bullet-point-modal-apo-punkte-overlay-icon-size / 2);
          height: calc($bullet-point-modal-apo-punkte-overlay-icon-size / 2);
        }
      }
    }
  }

  &.reimport {
    & .icon {
      height: 128px;
      width: 128px;
      max-width: 128px;
      max-height: 128px;

      .fullsize {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.product-price {
  padding: 0.3rem 0;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  white-space: nowrap;

  &.strike {
    text-decoration: line-through;
    color: $aco-dark-blue-disabled;
  }

  &.small {
    font-size: $font-size-base;
  }
}

.select-prescription a {
  text-decoration: underline;
  font-size: $font-size-xs;
}

.select-floating-label.prescription-type.select-button .select-selected {
  padding: map.get($spacers, "spacing-12");
  color: $aco-dark-blue;
}

/* Extends prescription select in sm device */
@media screen and (max-width: map.get($grid-breakpoints, "sm")) {
  .select-prescription {
    position: relative;
    width: 100vw;
    left: calc(-50vw + 50%);
  }
}

[data-selector="was-price"] {
  font-weight: 400;
  color: $aco-gray-01;
}

.product-status {
  & span[style] {
    font-weight: $font-weight-normal !important;
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
  }

  & input {
    width: 100%;
    border: none;
    border-bottom: 1px solid $aco-gray-05;
    padding: 0.5rem;
    color: $aco-gray-01;
    margin-bottom: map.get($spacers, "spacing-12");
  }

  & input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-weight: $font-weight-bold;
    color: $aco-light-blue;
    opacity: 1;

    /* Firefox */
  }

  & input:focus {
    outline: none;
    border-bottom: 1px solid $aco-gray-01;
  }
}

.callback-service {
  & h5 {
    font-size: $font-size-base;
    color: $aco-dark-blue;
  }

  & p {
    font-size: $font-size-xs;
    color: $aco-gray-01;
  }

  & small sup {
    margin-top: 0.6875rem;
    margin-right: 0.5rem;
  }
}

.keyfacts {
  padding: map.get($spacers, "spacing-24") 0;

  &.mobile {
    @include media-breakpoint-up(md) {
      display: none;
    }

    @include media-breakpoint-down(md) {
      display: block;
      padding: map.get($spacers, "spacing-40") 0 map.get($spacers, "spacing-40") map.get($spacers, "spacing-16");
    }
  }

  &.desktop {
    @include media-breakpoint-up(md) {
      display: block;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

@media screen and (min-width: calc(map.get($grid-breakpoints, "lg") + 1px)) {
  .reviews-wrapper {
    padding-left: 16.32%;
    padding-right: 16.32%;
  }
}

.product-rating-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @include media-breakpoint-down(sm) {
    justify-content: center;
  }

  & .score-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 128px;

    & .score {
      @include circle-gradient(113px, 20%, $aco-blue, $aco-white, $aco-white);

      display: flex;
      justify-content: center;
      align-items: center;
      height: $score-circle-size;
      width: $score-circle-size;
      font-weight: $font-weight-bold;
      font-size: $h1-font-size;
      color: $aco-dark-blue;
      border-radius: $border-radius-pill;
      background-color: $aco-yellow;
    }

    & .message {
      padding-top: 1rem;
      font-weight: $font-weight-medium;
      font-size: $font-size-xs;
    }
  }

  & .overview-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 12px);
    gap: map.get($spacers, "spacing-16");
    column-gap: 0;
    justify-content: center;
    align-items: center;
    padding-left: map.get($spacers, "spacing-24");

    & .score {
      display: flex;
      font-size: $font-size-xs;

      & .sterne {
        min-width: 45px;
      }

      & .total-comments {
        padding-left: map.get($spacers, "spacing-4");
        color: $aco-gray-01;
      }
    }

    & .progress {
      height: $progress-bar-height;
      width: 128px;
      margin-right: map.get($spacers, "spacing-12");
      border-radius: $progress-bar-border-radius;
    }
  }
}

.product-review {
  display: flex;
  border-bottom: 1px solid $aco-gray-06;
  padding-top: map.get($spacers, "spacing-32");
  padding-bottom: map.get($spacers, "spacing-32");

  & .btn {
    max-width: $product-review-btn-max-width;
  }

  @media screen and (max-width: map.get($grid-breakpoints, "sm")) {
    flex-direction: column;
    padding-top: map.get($spacers, "spacing-32");
    padding-bottom: map.get($spacers, "spacing-32");

    & .customer-name {
      margin-bottom: map.get($spacers, "spacing-12");
    }

    &:first-child {
      padding-top: map.get($spacers, "spacing-20");
      padding-bottom: map.get($spacers, "spacing-32");
    }
  }
}

.package-size-header {
  padding-left: $list-group-item-selection-height;

  & :first-child {
    padding-left: $list-group-item-content-padding-x;
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }
}

.list-group.buy-package-size {
  .list-group-item {
    &:focus-within {
      border-radius: 9px;
      border: 2px solid $aco-light-blue;
    }

    & + .list-group-item {
      margin-top: map.get($spacers, "spacing-6");
    }
  }
}

[data-section="seo-text"] {
  scroll-margin-top: 90px;

  a {
    color: $aco-white;
    font-weight: bold;
  }
}
