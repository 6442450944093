@use "sass:map";

.rating {
  display: flex;
  flex-wrap: wrap;
  row-gap: map.get($spacers, "spacing-6");

  .rating-total {
    font-size: $font-size-sm;
  }

  & .amount {
    line-height: initial;
  }
}

[class^="rating-star-size-"] {
  display: inline-flex;
  flex-wrap: nowrap;
}
