// Content wrapper
.newsletter-content-wrapper {
  // Two column layout
  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px auto;
    column-gap: 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    grid-template-rows: 60px auto;
    margin: 0 auto 30px;
  }
}

// Module headline
.newsletter-headline {
  font-family: $font-family-headlines;
  font-size: $font-size-lg;
  font-weight: $font-weight-semibold;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-xl;
  }
}

// Module text
.newsletter-text {
  font-family: $font-family-base;
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    grid-row: 1 / 3;
  }

  ul li::marker {
    content: "✓   ";
    color: $aco-yellow;
    font-family: system-ui, sans-serif;
    margin-right: 10px;
    font-size: 1.1rem;
  }

  p {
    margin-bottom: 10px;
  }
}

// Module button
.newsletter-button {
  width: 100%;
  height: 48px;
  border-radius: 2rem !important;
  border-style: none;
  font-weight: $font-weight-bold;
  font-size: $font-size-base;

  &:hover {
    background-color: $aco-light-blue-hover !important;
  }

  @include media-breakpoint-up(sm) {
    width: 382px;
  }

  @include media-breakpoint-up(md) {
    width: 284px;
    grid-row: 2;
    grid-column: 1;
  }
}
