/* Pagination */
@use "sass:map";

/**
 Variables
 */
$pagination-padding-y: 0.375rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-color: $link-color;
$pagination-bg: $white;
$pagination-border-width: 0;
$pagination-border-radius: $border-radius-pill;
$pagination-margin-start: -$pagination-border-width;
$pagination-border-color: transparent;
$pagination-focus-color: $link-hover-color;
$pagination-focus-bg: $gray-200;
$pagination-focus-box-shadow: none;
$pagination-focus-outline: 0;
$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-300;
$pagination-active-color: $aco-dark-blue;
$pagination-active-bg: $aco-light-blue;
$pagination-active-border-color: transparent;
$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;
$pagination-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
$pagination-border-radius-sm: $border-radius-sm;
$pagination-border-radius-lg: $border-radius-lg;

/**
 BS default import
 */
@import "~bootstrap/scss/pagination";

.pagination {
  margin-bottom: 0;

  & .page-item {
    & .page-link {
      border-radius: $pagination-border-radius;
      color: $aco-blue;

      svg {
        width: 10px;
        height: 16px;
      }
    }

    &.active {
      & .page-link {
        border-radius: $pagination-border-radius;
        font-weight: $font-weight-bold;
      }
    }
  }

  &.pagination-md {
    & .page-item {
      height: 24px;
      min-width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      & .page-link {
        height: 100%;
        width: 100%;
        padding: 0;
        text-align: center;
        line-height: 1.6rem;
      }

      &.more {
        margin: 0 24px;

        & + li {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }
  }

  &.process-bar {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.3rem;

    & .page-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      & .page-link {
        padding: 0;
        border-radius: $border-radius-pill;
        height: 24px;
        width: 24px;
        border: 2px solid $aco-dark-blue-disabled;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 65%;
      }

      & .page-step {
        color: $aco-dark-blue-disabled;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-top: map.get($spacers, "spacing-12");
        font-size: $font-size-xs;
        font-weight: $font-weight-bold;
        transform: translateX(-50%);
      }

      &.completed {
        & .page-link {
          border-color: $aco-dark-blue;
          background-color: $aco-dark-blue;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-white)}" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>');
        }

        & .page-step {
          color: $aco-dark-blue;
          margin-top: map.get($spacers, "spacing-12");
          font-size: $font-size-xs;
          font-weight: $font-weight-bold;
        }
      }

      &.active {
        & .page-link {
          border-color: $aco-dark-blue;
        }

        & .page-step {
          color: $aco-dark-blue;
        }
      }

      &.error {
        & .page-link {
          background-size: 50%;
          background-color: $aco-dark-blue;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-white)}" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>');
        }
      }
    }

    & .page-separator {
      height: 2px;
      background-color: $aco-dark-blue-disabled;
      width: 100%;

      &.active {
        background-color: $aco-dark-blue;
      }
    }

    &.light {
      & .page-item {
        & .page-link {
          border-color: $aco-white;
        }

        & .page-step {
          color: $aco-white;
        }

        &.completed {
          & .page-link {
            border-color: $aco-light-blue;
            background-color: $aco-light-blue;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-dark-blue)}" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>');
          }

          & .page-step {
            color: $aco-light-blue;
          }
        }

        &.active {
          & .page-link {
            border-color: $aco-light-blue;
          }

          & .page-step {
            color: $aco-light-blue;
          }
        }

        &.error {
          & .page-link {
            background-color: $aco-light-blue;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-dark-blue)}" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>');
          }
        }
      }

      & .page-separator {
        background-color: $aco-white;

        &.active {
          background-color: $aco-light-blue;
        }
      }
    }
  }

  &.pagination-md .page-item:not(:first-child):not(.more) {
    margin-left: 48px;
  }
}

.slide-pagination {
  display: flex;
  align-items: center;

  &.aco-blue {
    color: $aco-blue;

    &.disabled {
      color: $aco-blue-disabled;
    }

    &:hover:not(.disabled) {
      color: $aco-dark-blue;
    }
  }

  &.aco-dark-blue {
    color: $aco-dark-blue;

    &.disabled {
      color: $aco-dark-blue-disabled;
    }

    &:hover:not(.disabled) {
      color: $aco-dark-blue;
    }
  }

  &.aco-white {
    color: $aco-white;

    &.disabled {
      color: $aco-gray-03;
    }

    &:hover:not(.disabled) {
      color: $aco-gray-03;
    }
  }

  & .slide-pagination-icon-start {
    margin-right: 10px;
  }

  & .slide-pagination-icon-end {
    margin-left: 10px;
  }
}
