@use "sass:map";

$header-nav-max-width: 382px;
$simulated-tab-height: 80px;
$simulated-tab-border-color: $aco-gray-05;
$simulated-tab-border-radius: map.get($radius-map, 32);
$simulated-tab-border-width: 2px;
$simulated-tab-section-padding: map.get($spacers, "spacing-16");
$simulated-tab-after-before-width: $simulated-tab-border-radius;
$acount-history-wrapper-border-radius: 25px;
$account-history-wrapper-margin-top-desktop: 24px;
$account-history-wrapper-margin-top-mobile: 6px;

.account-history-wrapper {
  position: relative;
  margin-top: $account-history-wrapper-margin-top-desktop;
  background: $aco-white;
  transform-style: preserve-3d;

  @media screen and (max-width: map.get($grid-breakpoints, "md")) {
    margin-top: $account-history-wrapper-margin-top-mobile;
    border-top-left-radius: $acount-history-wrapper-border-radius;
    border-top-right-radius: $acount-history-wrapper-border-radius;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: calc($acount-history-wrapper-border-radius + $account-history-wrapper-margin-top-mobile);
      top: 0;
      background: $header-bg-color;
      transform: translateY(-($account-history-wrapper-margin-top-mobile + 1px)) translateZ(-1px);
    }
  }
}

.header-nav {
  width: 100%;
  max-width: $header-nav-max-width;

  & .nav.nav-pills {
    width: 100%;

    & .nav-link {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}

.btn-base-font-size {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.simulated-tab {
  display: flex;
  align-items: start;
  width: 100%;
  height: $simulated-tab-height;

  & .section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    padding: $simulated-tab-section-padding $simulated-tab-after-before-width;
    height: 100%;
    border-style: solid;
    border-color: $simulated-tab-border-color;
    border-width: 0;
    position: relative;

    &:first-child {
      border-top: $simulated-tab-border-width solid $simulated-tab-border-color;
      border-top-right-radius: calc($simulated-tab-border-radius / 2);

      @media screen and (max-width: map.get($grid-breakpoints, "md")) {
        width: 100%;
        border-width: 0;
        justify-content: center;
        padding: $simulated-tab-section-padding;

        &.section-end {
          justify-content: center;
        }

        &::after {
          display: none;
        }
      }

      &::after {
        content: "";
        width: $simulated-tab-after-before-width;
        height: calc(50% + $simulated-tab-border-width);
        border-top-right-radius: calc($simulated-tab-border-radius / 2);
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-$simulated-tab-border-width);
        border-width: $simulated-tab-border-width $simulated-tab-border-width 0 0;
        border-style: solid;
        border-color: $simulated-tab-border-color;
      }
    }

    &:last-child {
      border-bottom: $simulated-tab-border-width solid $simulated-tab-border-color;
      border-bottom-left-radius: calc($simulated-tab-border-radius / 2);

      @media screen and (max-width: map.get($grid-breakpoints, "md")) {
        display: none;
      }

      &::before {
        content: "";
        width: $simulated-tab-after-before-width;
        height: calc(50% + $simulated-tab-border-width);
        border-bottom-left-radius: calc($simulated-tab-border-radius / 2);
        border-width: 0 0 $simulated-tab-border-width $simulated-tab-border-width;
        border-style: solid;
        border-color: $simulated-tab-border-color;
        position: absolute;
        transform: translateX(-$simulated-tab-border-width) translateY($simulated-tab-border-width);
        bottom: 0;
        left: 0;
      }
    }

    &.section-end {
      justify-content: flex-end;
    }
  }
}
