@use "sass:map";

$newsletter-card-size: 185px;
$newsletter-card-checkbox-size: 24px;

.newsletter-title {
  font-size: $h1-font-size;
  font-family: "Playfair Display", $font-family-base;
  font-weight: $font-weight-semibold;
  color: $aco-white;
}

.newsletter-description {
  color: $aco-dark-blue;

  & a {
    color: $aco-blue;
  }
}

.newsletter-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-base;
}

.newsletter-badge {
  position: absolute;
  top: 1rem;
  left: 0;

  & .price {
    margin: 0 !important;
  }
}

.newsletter-card-wrapper {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-items: center;
}

.newsletter-card {
  position: relative;
  width: $newsletter-card-size;
  height: $newsletter-card-size;
  background: $aco-gray-06;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: map.get($radius-map, 1);
  overflow: hidden;
  color: $aco-white;
  padding: 0;
}

.newsletter-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 21.6%;
  background: $aco-dark-blue;
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.newsletter-header-bg-image {
  min-height: 229px;
  position: relative;
  background-color: $aco-dark-blue;
  z-index: -1;
}

.newsletter-card-checkbox {
  position: absolute;
  top: 14px;
  right: 14px;

  & input {
    width: $newsletter-card-checkbox-size;
    height: $newsletter-card-checkbox-size;
  }
}

.newsletter-link-with-icon {
  @extend %link-aco-blue;

  &.underline {
    text-decoration: none;
    line-height: 1px;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: $aco-blue;
    padding-bottom: $line-height-base;

    &:hover {
      border-color: $aco-dark-blue;
    }

    &.inactive {
      border-color: $aco-dark-blue-disabled;
    }

    & .text-aco-white,
    &.text-aco-white {
      border-color: $aco-white;
    }
  }

  & svg {
    margin-right: 0.2rem;
  }
}

.select-floating-label.newsletter-unsubscribe-reason-select.select-button.select-large {
  max-width: none;
  color: $aco-dark-blue;
}

.newsletter-lg-button {
  font-size: $font-size-base;
}
