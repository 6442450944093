.product-overview {
  &:not(.no-border-bottom) {
    border-bottom: 1px solid $aco-gray-05;
  }

  & .original-price {
    color: $aco-gray-01;
    text-decoration: line-through;
    font-size: $font-size-xs;
  }

  & .price {
    color: $aco-dark-blue;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
  }

  & .discounted-price {
    color: $aco-orange;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
  }
}
