@use "sass:map";

/* Dropdowns */

/**
 Variables
 */
$dropdown-border-filter: 2px solid $aco-gray-06;
$dropdown-min-width: 10rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: map.get($spacers, "spacing-8");
$dropdown-spacer: 0.125rem;
$dropdown-font-size: $font-size-base;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius;
$dropdown-border-width: $border-width;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg: $dropdown-border-color;
$dropdown-divider-margin-y: $spacer * 0.5;
$dropdown-box-shadow: $box-shadow;
$dropdown-link-color: $gray-900;
$dropdown-link-hover-color: shade-color($dropdown-link-color, 10%);
$dropdown-link-hover-bg: $gray-200;
$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;
$dropdown-link-disabled-color: $gray-500;
$dropdown-item-padding-y: $spacer * 0.25;
$dropdown-item-padding-x: $spacer;
$dropdown-header-color: $gray-600;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x;

// dropdown dark mode
$dropdown-dark-color: $gray-300;
$dropdown-dark-bg: $gray-800;
$dropdown-dark-border-color: $dropdown-border-color;
$dropdown-dark-divider-bg: $dropdown-divider-bg;
$dropdown-dark-box-shadow: null;
$dropdown-dark-link-color: $dropdown-dark-color;
$dropdown-dark-link-hover-color: $white;
$dropdown-dark-link-hover-bg: rgba($white, 0.15);
$dropdown-dark-link-active-color: $dropdown-link-active-color;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg;
$dropdown-dark-link-disabled-color: $gray-500;
$dropdown-dark-header-color: $gray-500;

/**
 BS default import
 */
@import "~bootstrap/scss/dropdown";

.datalist-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: map.get($spacers, "spacing-16") map.get($spacers, "spacing-16");
  list-style: none;

  /* stylelint-disable-next-line */
  .svg-inline--fa {
    width: 15px;
    height: 24px;
  }
}

.dropdown.dropdown-to-left {
  .dropdown-menu {
    border-top-right-radius: 0;

    &::before {
      border-left: $dropdown-border-filter;
      left: -2px;
      border-top-left-radius: map.get($radius-map, 24);
    }
  }

  .dropdown-menu-margin {
    border-right: $dropdown-border-filter;
    right: 0;
  }

  .button-wrapper-dropdown {
    border-right: $dropdown-border-filter;
    border-top-right-radius: map.get($radius-map, 24);
    border-top-left-radius: map.get($radius-map, 24);

    &::after {
      right: 99%;
      border-right: $dropdown-border-filter;
      border-bottom-right-radius: map.get($radius-map, 24);
    }
  }
}

.dropdown.dropdown-to-right {
  .dropdown-menu {
    border-top-left-radius: 0;

    &::before {
      border-right: $dropdown-border-filter;
      right: -2px;
      border-top-right-radius: map.get($radius-map, 24);
    }
  }

  .dropdown-menu-margin {
    border-left: $dropdown-border-filter;
  }

  .button-wrapper-dropdown {
    border-left: $dropdown-border-filter;
    border-top-right-radius: map.get($radius-map, 24);
    border-top-left-radius: map.get($radius-map, 24);

    &::after {
      left: 99%;
      border-left: $dropdown-border-filter;
      border-bottom-left-radius: map.get($radius-map, 24);
    }
  }
}

.dropdown.dropdown-to-right,
.dropdown.dropdown-to-left {
  .dropdown-toggle {
    max-width: 100%;
  }

  .dropdown-menu {
    width: calc(200% + 16px);
    border: $dropdown-border-filter;
    border-top: none;
    flex-direction: column;
    margin-top: 12px !important;

    &.show {
      display: flex;
      justify-content: space-between;
      row-gap: 28px;
    }

    .dropdown-menu-item,
    %dropdown-menu-item {
      white-space: nowrap;
      padding: map.get($spacers, "spacing-4") map.get($spacers, "spacing-20");
    }

    &::before {
      content: "";
      height: 18px;
      border-top: $dropdown-border-filter;
      width: 22px;
      position: absolute;
      top: -2px;
    }
  }

  .dropdown-menu-button {
    @extend %dropdown-menu-item;

    display: flex;
    justify-content: space-between;
    gap: map.get($spacers, "spacing-16");
  }

  .dropdown-menu-margin {
    z-index: 1;
    width: 99%;
    position: absolute;
    margin-top: 2px;
    height: 50px;
    background-color: $aco-white;
  }

  .button-wrapper-dropdown {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    height: 50px;

    &::after {
      content: "";
      width: 100%;
      position: absolute;
      top: 20px;
      z-index: -1;
      height: 42px;
      border-bottom: $dropdown-border-filter;
    }
  }
}
