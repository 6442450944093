.address-modal {
  .modal-content {
    padding: 20px 50px;
  }

  .modal-header {
    padding: 1rem 0;
    margin-bottom: 20px;
  }

  .modal-title, .modal-body {
    padding: 0;
  }

  .modal-title h5 {
    margin-bottom: 0;
  }

  .select-floating-label {
    max-width: unset;
  }

  .address-modal-buttons .btn {
    max-width: unset;
  }

  // Hide number input spinners for Chrome, Safari, Edge, Opera
  input[type="number"] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // For Firefox
    -moz-appearance: textfield;
  }
}
