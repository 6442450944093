/* Accordion */
@use "sass:map";
@import "~bootstrap/scss/buttons";
@import "../atoms/buttons";

$answers-padding-x: map.get($spacers, "spacing-16");
$answers-padding-y: map.get($spacers, "spacing-20");

[data-selector="omqContact"] {
  .omq-help-navigation {
    color: $aco-dark-blue;
  }

  .omq-search {
    border-radius: 20px;
    color: $aco-blue;
    box-shadow: none;

    [class*="omq-search__wrapper"] {
      padding: 0.5rem 12px;
    }

    [class*="omq-search__icon"] {
      background-image: url('data:image/svg+xml,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="#{to-rgb($aco-blue)}" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"></path></svg>');
      background-size: $font-size-base;
    }

    input[type="text"] {
      margin: 0 map.get($spacers, "spacing-12");
      border-radius: 20px;
      font-size: $font-size-base;

      &::placeholder {
        color: $aco-blue;
        font-size: $font-size-base;
      }
    }
  }

  .omq-category-grid {
    justify-content: center;

    [class*="__category"] {
      flex-basis: 100%;
      flex-direction: row;
      justify-content: flex-start;
      height: 80px;
      border-radius: $border-radius-lg;
      border: 2px solid $aco-gray-04;
      padding: map.get($spacers, "spacing-16");
      margin-bottom: map.get($spacers, "spacing-16");

      @media screen and (min-width: map.get($grid-breakpoints, "sm")) {
        flex-basis: calc(100% / 2 - 2%);
        flex-direction: column;
        justify-content: center;
        height: 166px;
        margin-left: 1%;
        margin-right: 1%;
        margin-bottom: 2%;
      }

      @media screen and (min-width: map.get($grid-breakpoints, "md")) {
        flex-basis: calc(100% / 3 - 2%);
      }

      @media screen and (min-width: map.get($grid-breakpoints, "xl")) {
        flex-basis: calc(100% / 4 - 2%);
      }

      i[class*="__icon"] {
        flex: none;
        width: 48px;
        height: 48px;
        background-size: contain;
        background-position: center;
        margin-bottom: 0;
        margin-right: map.get($spacers, "spacing-16");

        @media screen and (min-width: map.get($grid-breakpoints, "sm")) {
          margin-bottom: map.get($spacers, "spacing-12");
          margin-right: 0;
        }
      }

      span[class*="__label"] {
        flex: 1;
        max-height: none;
        font-family: $font-family-base;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        color: $aco-dark-blue;
        line-height: $line-height-base;
        text-align: left;

        @media screen and (min-width: map.get($grid-breakpoints, "sm")) {
          flex: none;
          text-align: center;
        }
      }

      &:hover {
        background-color: $aco-gray-06;
      }

      &:focus {
        border-color: $aco-light-blue;
      }
    }
  }

  .omq-question-list-headline {
    font-family: $font-family-headlines;
    font-size: $h2-font-size;
    font-weight: 600;
    color: $aco-dark-blue;
    text-align: left;
  }

  .omq-question-list {
    border: none;
    box-shadow: none;

    [class*="__question"] {
      margin: 0;
      border-width: 0 0 1px;
      border-style: solid;
      border-color: $aco-gray-06;

      &:focus {
        border: 2px solid $aco-light-blue;
      }

      [class*="__link"] {
        color: $aco-blue;
        padding: 1.25rem 1rem;

        & [class*="__toggle-icon"] {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-blue)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');
          background-size: 0.95rem;
          background-repeat: no-repeat;
          transition: all 0.15s ease-in-out;
        }

        &:hover {
          color: $aco-dark-blue;

          [class*="__toggle-icon"] {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-dark-blue)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');
          }
        }

        & h3 {
          font-family: $font-family-base;
          font-weight: $font-weight-bold;
          font-size: $font-size-base;
        }
      }

      [class*="__answers"] {
        padding: $answers-padding-x $answers-padding-y;
        border: none;
      }

      [class*="__answer"] {
        .omq-answer-item {
          color: $aco-dark-blue;
        }

        .link {
          a::after {
            content: "";
            display: inline-block;
            width: 14px;
            height: 16px;
            background-image: url('data:image/svg+xml,<svg class="svg-inline--fa fa-square-arrow-up-right" aria-hidden="true" focusable="false" data-prefix="far" data-icon="square-arrow-up-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="#{$aco-blue}" d="M312 144H156.7c-13.25 0-24 10.75-24 24S143.5 192 156.7 192h97.34l-135 135c-9.375 9.375-9.375 24.56 0 33.94C123.7 365.7 129.8 368 136 368s12.28-2.344 16.97-7.031L288 225.9v97.34c0 13.25 10.75 24 24 24s24-10.75 24-24V168C336 154.8 325.3 144 312 144zM384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM400 416c0 8.82-7.178 16-16 16H64c-8.822 0-16-7.18-16-16V96c0-8.82 7.178-16 16-16h320c8.822 0 16 7.18 16 16V416z"></path></svg>');
            background-size: 100%;
            background-repeat: no-repeat;
            margin-left: 10px;
            vertical-align: middle;
          }
        }

        a {
          color: $aco-blue;
          text-decoration: none;
        }
      }

      &[class*="--active"] {
        [class*="__link"] {
          [class*="__toggle-icon"] {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  .omq-more-questions {
    @extend %btn;
    @extend %btn-secondary;
    @extend %btn-lg;
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);

    display: block;
    width: 100%;
    margin-top: map.get($spacers, "spacing-32");
    margin-left: auto;
    margin-right: auto;
    font-size: $btn-lg-font-size;
    line-height: $btn-lg-line-height;
    text-decoration: none;
  }

  #omq-contact-container {
    margin-bottom: map.get($spacers, "spacing-48");

    .omq-question-list {
      [class*="__answer"] {
        .omq-answer-item {
          color: $aco-dark-blue;
        }

        .link {
          a::after {
            content: "";
            display: inline-block;
            width: 14px;
            height: 16px;
            background-image: url('data:image/svg+xml,<svg class="svg-inline--fa fa-square-arrow-up-right" aria-hidden="true" focusable="false" data-prefix="far" data-icon="square-arrow-up-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="#{$aco-blue}" d="M312 144H156.7c-13.25 0-24 10.75-24 24S143.5 192 156.7 192h97.34l-135 135c-9.375 9.375-9.375 24.56 0 33.94C123.7 365.7 129.8 368 136 368s12.28-2.344 16.97-7.031L288 225.9v97.34c0 13.25 10.75 24 24 24s24-10.75 24-24V168C336 154.8 325.3 144 312 144zM384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM400 416c0 8.82-7.178 16-16 16H64c-8.822 0-16-7.18-16-16V96c0-8.82 7.178-16 16-16h320c8.822 0 16 7.18 16 16V416z"></path></svg>');
            background-size: 100%;
            background-repeat: no-repeat;
            margin-left: 10px;
            vertical-align: middle;
          }
        }

        a {
          color: $aco-blue;
          text-decoration: none;
        }
      }

      .omq-question-list-item {
        color: $aco-blue;
        padding: 1.25rem 1rem;
        margin: 0;
        border-width: 0 0 1px;
        border-style: solid;
        border-color: $aco-gray-06;

        &:focus {
          border: 2px solid $aco-light-blue;

          & + [class*="__answers"] {
            border: none;
          }
        }

        & [class*="__icon"] {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-blue)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');
          background-size: 0.95rem;
          background-repeat: no-repeat;
          transition: all 0.15s ease-in-out;
        }

        &[class*="--is-active"] {
          [class*="__icon"] {
            transform: rotate(-180deg);
          }
        }

        &:hover {
          color: $aco-dark-blue;

          [class*="__icon"] {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-dark-blue)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');
          }
        }

        p {
          font-family: $font-family-base;
          font-weight: $font-weight-bold;
          font-size: $font-size-base;
        }
      }
    }
  }

  .omq-footer {
    display: none;
  }
}
