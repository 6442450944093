.spinner-icon svg {
  width: 80px;
  animation: rotation 1.3s infinite linear;
  color: #039;
}

div[data-selector="preloader"] {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.ais-preloader-filter {
  background-image: url("../img/resource/images/preloader_search/skeleton-filter-and-sort.webp");
  height: 125px;
  background-size: 50%;
  background-repeat: no-repeat;

  @include media-breakpoint-up(md) {
    background-size: 291px;
  }
}

.ais-preloader-hitlist {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  column-gap: 1.5rem;
  row-gap: 2rem;

  .ais-preloader-hit {
    background-image: url("../img/resource/images/preloader_search/ais-preloader-image__block.webp");
    width: 100%;
    aspect-ratio: 89/206;
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

.ais-preloader-headline {
  display: flex;
  justify-content: center;

  p {
    font-size: 44px;
    line-height: 150%;
    color: #666;
    text-align: center;
    margin: 0;
    padding: 0 0 0.75rem;
  }
}

.ais-preloader-searchresult-container {
  display: flex;
  position: relative;
  margin: 0;
  overflow: hidden;
  width: 100%;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.ais-preloader-searchresult-container .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ais-preloader-searchresult-container .overlay.has-transition {
  background-image:
    linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.4) 10px,
      rgba(#fff, 0.8) 60px,
      rgba(#fff, 0) 90px
    );
  animation: shimmer 1.5s infinite;
  mix-blend-mode: overlay;
  transform: translateX(-100%);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
