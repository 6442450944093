/* Cart */
@use "sass:map";

$send-coupon-code-width: 32px;
$send-coupon-code-height: 32px;
$input-group-addon-max-height-mb: 32px;
$input-group-addon-max-width-mb: 109px;
$apo-punkte-icon-padding: 12px;

.form-apply-coupon-code,
.form-remove-coupon-code {
  position: relative;

  & .apply-coupon-code-button {
    position: absolute;
    bottom: 1rem;
    right: 0;
    font-size: $font-size-base;
    transform: translateY(-6.25%);
    width: $send-coupon-code-width;
    height: $send-coupon-code-height;

    &.btn.btn-ghost:hover {
      background-color: transparent;
    }
  }
}

.input-group.stepper.cart {
  @media screen and (max-width: 576px) {
    height: $input-group-addon-max-height-mb;
    width: $input-group-addon-max-width-mb;

    & .input-group {
      flex-wrap: nowrap;
    }
  }
}

.shopping-cart-item {
  .cart-product-name-font-size {
    @include media-breakpoint-down(sm) {
      font-size: $font-size-sm;
      line-height: $line-height-sm;
    }
  }

  &-image-wrapper {
    border: 2px solid $aco-gray-06;
    border-radius: $border-radius;
  }

  &-delete {
    color: $aco-dark-blue;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      font-size: $font-size-base;
    }
  }

  &-available {
    font-size: $font-size-base;

    @include media-breakpoint-down(md) {
      font-size: $font-size-sm;
      line-height: $line-height-base;
    }

    & svg {
      font-size: $font-size-base;
      color: $aco-blue;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 24px;
      min-height: 24px;
      max-width: 24px;
      max-height: 24px;
      background-color: $aco-light-blue;
      border-radius: $border-radius-pill;
    }
  }

  &-price {
    font-size: 17px;
    color: $aco-dark-blue;
    font-weight: $font-weight-bold;
  }
}

.apo-punkte-icon {
  position: relative;
  width: fit-content;
  padding: 0 $apo-punkte-icon-padding;
  margin-top: 1rem;

  &.popup {
    margin-top: -2px;
    margin-left: -5px;
  }

  & svg {
    width: 24px;
    height: 24px;
    color: $aco-white;
  }

  & svg:last-child {
    position: absolute;
    top: 0;
    right: $apo-punkte-icon-padding;
    transform: translate(50%, -50%);
    width: 14px;
    height: 14px;
    color: $aco-white;
    border: 4px solid $aco-blue;
    border-radius: $border-radius-pill;
  }
}

.list-unstyled.cart {
  color: $aco-gray-01;

  svg {
    font-size: $font-size-base;
  }

  @include media-breakpoint-down(md) {
    font-size: $font-size-xs;
    line-height: $line-height-base;
  }
}

[data-type="error"],
[data-type="fatal_error"],
[data-type="removed"] {
  color: $aco-red;
}

[data-type="success"] {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  color: $aco-gray-02;

  & .value-format {
    min-width: fit-content;
    padding-left: 1rem;
    color: $aco-dark-blue;
    font-weight: $font-weight-medium;
  }
}

.apo-punkte-opacity-text {
  color: $aco-white;
  opacity: 0.6;
}

.apo-punkte-button-as-link {
  display: block;
  width: fit-content;
  text-decoration: underline;
  text-align: start;

  &.no-underline {
    text-decoration: none;
  }
}

.apo-punkte-amount-input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    appearance: textfield;
  }
}

.apo-punkte-divider {
  width: 100%;
  min-width: 100%;
  height: 1px;
  min-height: 1px;
  opacity: 0.2;
  margin: 2px 0;
  background-color: $aco-white;
}

.remove-bonus-point-button {
  color: $aco-white !important;
  background-color: transparent !important;

  &:hover {
    background-color: $aco-white !important;
    color: $aco-blue !important;
  }

  &:focus {
    outline: 2px solid $aco-light-blue !important;
  }
}

.cart-item-list {
  & + & {
    border-top: 2px solid $aco-gray-06;
  }
}

.red-gradient {
  @include circle-gradient(
    $border-main-color: $aco-red,
    $center-bg-color: $aco-blue,
    $border-secondary-color: $aco-blue
  );
}

.blue-gradient {
  @include circle-gradient(
    $border-main-color: $aco-light-blue,
    $center-bg-color: $aco-blue,
    $border-secondary-color: $aco-blue
  );
}

.small-icon {
  min-height: 50px;
  min-width: 50px;
  max-height: 50px;
  max-width: 50px;
  width: 50px;
  height: 50px;
}

.apo-punkte-icon-small {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 6px;
  right: 6px;
  transform: translate(50%, -50%);
  border: 4px solid #039;
  border-radius: 50rem;
}

.cart-totals {
  margin-top: map.get($spacers, "spacing-40");

  &-container {
    margin: 0;
  }

  &-total-price {
    padding-top: map.get($spacers, "spacing-16");

    & .order-totals {
      position: relative;
    }

    & .order-totals::after {
      content: "";
      background: $aco-yellow;
      position: absolute;
      bottom: 0;
      right: map.get($spacers, "spacing-12");
      height: 1px;
      width: 90px;
    }
  }
}

.cart-overlay {
  .check-icon-blue {
    @include circle-gradient(
      $center-bg-color: $aco-blue,
      $size: 168px,
      $border-main-color: $aco-light-blue,
      $border-secondary-color: transparent,
      $border-size: 20%
    );

    &::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{to-rgb($aco-light-blue)}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 47%;
    }
  }
  /* stylelint-disable-next-line */
  .loading_spinner {
    clip-path: polygon(100% 50%, 100% 100%, 0 100%, 0 0, 100% 0, 100% 50%, 89% 50%, 88.8062% 46.1169%, 88.2267% 42.2723%, 87.2673% 38.5045%, 85.9376% 34.851%, 84.2506% 31.3481%, 82.2233% 28.0305%, 79.8757% 24.9313%, 77.2312% 22.0812%, 74.3161% 19.5086%, 71.1593% 17.239%, 67.7922% 15.295%, 64.2483% 13.6959%, 60.5628% 12.4577%, 56.7723% 11.5925%, 52.9145% 11.1091%, 49.0277% 11.0121%, 45.1506% 11.3027%, 41.3217% 11.9778%, 37.579% 13.0308%, 33.9598% 14.4513%, 30.5% 16.225%, 27.234% 18.3344%, 24.1943% 20.7585%, 21.411% 23.4733%, 18.9118% 26.4516%, 16.7217% 29.664%, 14.8622% 33.0785%, 13.352% 36.6612%, 12.206% 40.3765%, 11.4356% 44.1874%, 11.0485% 48.056%, 11.0485% 51.944%, 11.4356% 55.8127%, 12.206% 59.6235%, 13.352% 63.3388%, 14.8622% 66.9215%, 16.7217% 70.336%, 18.9118% 73.5484%, 21.411% 76.5267%, 24.1943% 79.2415%, 27.234% 81.6656%, 30.5% 83.775%, 33.9598% 85.5487%, 37.579% 86.9692%, 41.3217% 88.0222%, 45.1506% 88.6973%, 49.0277% 88.9879%, 52.9145% 88.8909%, 56.7723% 88.4075%, 60.5628% 87.5423%, 64.2483% 86.3041%, 67.7922% 84.705%, 71.1593% 82.761%, 74.3161% 80.4914%, 77.2312% 77.9188%, 79.8757% 75.0687%, 82.2233% 71.9695%, 84.2506% 68.6519%, 85.9376% 65.149%, 87.2673% 61.4955%, 88.2267% 57.7277%, 88.8062% 53.8832%, 89% 50%);

    &.dark {
      background: conic-gradient(from 180deg at 50% 50%, transparent 0deg, rgb(98 214 252 / 50%) 210deg, #62d6fc 360deg);
    }
  }

  .modal-footer {
    background-color: $aco-dark-blue;
  }

  @media (max-width: map.get($grid-breakpoints, "sm")) {
    .check-icon-blue {
      @include circle-gradient(
        $center-bg-color: $aco-blue,
        $size: 128px,
        $border-main-color: $aco-light-blue,
        $border-secondary-color: transparent,
        $border-size: 20%
      );
    }
  }

  cart-button::part(button) {
    font-size: 0.875rem;
    line-height: 1.5 !important;
    width: 100%;
    min-height: 33px;
    height: 33px;
    padding: 0.375rem 0.75rem;
  }
}

.cart-add-to-cart-sticky {
  position: sticky;
  right: 0;
  bottom: -0.5rem;
  left: 0;
  z-index: 1030;
  width: 100%;
}
