@use "sass:map";

$list-account-delete-item-margin: map.get($spacers, "spacing-16");
$list-group-icon-size: 16px;

.list-account-delete {
  & li {
    margin-top: map.get($spacers, "spacing-8");

    &:first-child {
      margin-top: 0;
    }
  }

  &.list-style-type-none {
    padding-left: calc(2rem - $list-group-icon-size);

    & li [class*="svg-inline"] {
      width: $list-group-icon-size;
      height: $list-group-icon-size;
      margin-right: map.get($spacers, "spacing-8");
      margin-left: - calc($list-group-icon-size / 2);
    }
  }
}
