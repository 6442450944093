*[data-name="webSEOModule"] {
  display: flex;
  flex-flow: row wrap;
  padding: 3rem 1.5rem;
  column-gap: 1.5rem;
  row-gap: 3rem;

  [data-content="rich-text-content"] {
    h3 {
      margin-bottom: 24px;
    }

    p {
      color: $aco-dark-blue;
    }
  }

  .seo-column {
    flex: 1 1 100%;

    p:last-of-type {
      margin: 0;
    }
  }

  @include media-breakpoint-up(md) {
    .seo-column {
      flex: 1 1 calc(50% - 1.5rem);
    }
  }
}
