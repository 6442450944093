/* Colors */

/**
 Variables
 */

// apo colors variables
$aco-dark-blue-hover: #010533;
$aco-dark-blue: #01063f;
$aco-dark-blue-disabled: #8b91b5;
$aco-blue: #039;
$aco-blue-disabled: #99a6dc;
$aco-light-blue-hover: #56bcdd;
$aco-light-blue: #62d6fc;
$aco-chatbot-blue: #b0eafd;
$aco-red: #ea3400;
$aco-dark-orange: #9e5308;
$aco-orange: #ea7b0c;
$aco-yellow-hover: #e7cd00;
$aco-yellow: #eae43d;
$aco-yellow-disabled: #f2ef91;
$aco-green: #2dad4d;
$colors: (
  "dark-blue-hover": $aco-dark-blue-hover,
  "dark-blue": $aco-dark-blue,
  "dark-blue-disabled": $aco-dark-blue-disabled,
  "blue": $aco-blue,
  "blue-disabled": $aco-blue-disabled,
  "light-blue-hover": $aco-light-blue-hover,
  "light-blue": $aco-light-blue,
  "chatbot-blue": $aco-chatbot-blue,
  "red": $aco-red,
  "dark-orange": $aco-dark-orange,
  "orange": $aco-orange,
  "yellow-hover": $aco-yellow-hover,
  "yellow": $aco-yellow,
  "yellow-disabled": $aco-yellow-disabled,
  "green": $aco-green,
);

// apo gray color variables
$aco-white: #fff;
$aco-gray-01: #636366;
$aco-gray-02: #8e8e93;
$aco-gray-03: #a6a8bc;
$aco-gray-04: #d1d1d6;
$aco-gray-05: #e5e5ea;
$aco-gray-06: #f2f2f7;
$aco-black: #000;

// apo gray colors map
$grays: (
  "100": $aco-gray-01,
  "200": $aco-gray-02,
  "300": $aco-gray-03,
  "400": $aco-gray-04,
  "500": $aco-gray-05,
  "600": $aco-gray-06,
);

// apo theme color variables
$primary: $aco-light-blue;
$secondary: $aco-gray-06;
$success: $aco-green;
$info: $aco-white;
$warning: $aco-dark-blue;
$danger: $aco-red;
$light: $aco-white;
$dark: $aco-blue;

// apo theme colors map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "aco-dark-blue-hover": $aco-dark-blue-hover,
  "aco-dark-blue": $aco-dark-blue,
  "aco-dark-blue-disabled": $aco-dark-blue-disabled,
  "aco-blue": $aco-blue,
  "aco-blue-disabled": $aco-blue-disabled,
  "aco-light-blue-hover": $aco-light-blue-hover,
  "aco-light-blue": $aco-light-blue,
  "aco-chatbot-blue": $aco-chatbot-blue,
  "aco-red": $aco-red,
  "aco-dark-orange": $aco-dark-orange,
  "aco-orange": $aco-orange,
  "aco-yellow-hover": $aco-yellow-hover,
  "aco-yellow": $aco-yellow,
  "aco-yellow-disabled": $aco-yellow-disabled,
  "aco-green": $aco-green,
  "aco-white": $aco-white,
  "aco-gray-01": $aco-gray-01,
  "aco-gray-02": $aco-gray-02,
  "aco-gray-03": $aco-gray-03,
  "aco-gray-04": $aco-gray-04,
  "aco-gray-05": $aco-gray-05,
  "aco-gray-06": $aco-gray-06,
  "aco-black": $aco-black,
);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$body-bg: $aco-white;
$body-color: $aco-gray-01;
$component-active-color: $aco-white;
$component-active-bg: $primary;
$color-contrast-light: $aco-white;
$color-contrast-dark: $aco-black;
$min-contrast-ratio: 4.5;
