.auto-suggest-wrapper.algolia {
  padding: 0;

  form {
    > div {
      &:focus-within {
        outline: 2px solid #62D6FC;
      }
    }
  }

  button svg {
    position: relative;
  }

  input {
    outline: none;
    padding: 0.5rem 20px 0.5rem 0;
  }

  .auto-suggest-list {
    display: none;
    overflow-y: scroll;
    max-height: 80vh;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    h3 {
      padding: 0.125rem 0 0.125rem 2rem;
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    ul {
      padding-top: 0;
    }

    .auto-suggest-keywords {
      .show-all-results-link {
        position: relative;
        width: 100%;
        padding: 24px 50px 24px 16px;
        color: $aco-yellow;
        word-break: break-word;

        &::after {
          @include use-link-icon($aco-white);

          content: "";
          position: absolute;
          top: 50%;
          right: -0.25rem;
          transform: translateY(-50%);
          width: 15px;
          height: 100%;
        }
      }
    }

    .autosuggest-highlight {
      .highlighted {
        color: $aco-yellow;
      }
    }
  }

  &:focus-within {
    .auto-suggest-list {
      display: block;
    }

    .auto-suggest-backdrop {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgb(0 0 0 / 50%);
      z-index: -20;
    }
  }
}

.filter-wrapper {
  .datalist-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.algolia-label {
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 8px;
}

.algolia-input {
  border-radius: 20px;
  box-sizing: border-box;
  left: 0;
  padding: 0.5rem 20px 0.5rem 42px;
  position: absolute;
  top: -10px;
  width: 100%;
  z-index: -1;
  margin-bottom: 20px;
}

.algolia-count {
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem;
  color: #3A4570;
  background-color: #DFE2EE;
  border-radius: 22px;
  margin-left: 8px;
  display: none;
}

.algolia-list {
  list-style-type: none;
  padding: 0;
}

.algolia-checkbox {
  width: 20px;
  height: 20px;
  vertical-align: sub;
  flex-shrink: 0;
  color: $aco-dark-blue;
}

.algolia-item {
  margin-bottom: 8px;
}

.algolia-priceinput {
  height: 32px;
  color: #01063F;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-radius: 24px;
  border: none;
  background-color: #F2F2F7;
  padding: 4px 10px;
  margin-right: 8px;
}

.algolia-rangeform {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.algolia-submit {
  border: none;
  border-radius: 24px;
  height: 32px;
  padding: 0 15px;
}

.algolia-searchbox {
  margin-bottom: 16px;

  & .ais-SearchBox-form {
    position: relative;
    background-color: $aco-gray-06;
    border-radius: 24px;
    display: flex;
    padding: 8px 0 8px 20px;

    &:focus-within {
      outline: 2px solid $aco-blue;
    }
  }

  & .ais-SearchBox-input {
    order: 1;
    width: calc(100% - 38px);
    border: none;
    background-color: #F2F2F7;
    outline: none;
  }

  & .ais-SearchBox-submit {
    order: 3;
    border: none;
    height: 32px;
    background-color: #F2F2F7;
  }

  & .ais-SearchBox-reset {
    order: 2;
    background-color: #F2F2F7;
    border: none;
    border-radius: 0 24px 24px 0;
    height: 24px;
    position: absolute;
    right: 38px;
    top: 3px;
  }
}

.refinement-root {
  .refinement-list {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
  }

  .refinement-item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    background-color: $aco-white;
    padding: 4px 4px 4px 12px;
    border: 2px solid $aco-gray-06;
    border-radius: 20px;
  }

  .refinement-label {
    font-size: 14px;
    font-weight: 700;
  }

  .refinement-category {
    width: fit-content;
    max-width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    background-color: $aco-gray-06;
    border-radius: 20px;
    padding: 4px 12px;
    max-height: 32px;
  }

  .refinement-category-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
  }

  .refinement-delete {
    background-size: 14px;
    font-size: 0;
  }
}

.refinement-count {
  position: absolute;
  right: 48px;
}

.ais-CurrentRefinements--noRefinement,
.ais-CurrentRefinements-list--noRefinement {
  margin-bottom: 0 !important;
}

.ais-CurrentRefinements-list {
  &.refinement-list {
    margin-bottom: 32px;
  }
}

.sidebar-filter {
  .show {
    display: block;
  }

  .hide {
    display: none;
  }
}

.react-slider {
  &.slider {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background-color: $aco-gray-05;

    .thumb {
      background-color: $aco-light-blue;
      border-color: transparent;
      border-radius: 50rem;
      box-shadow: none;
      height: 32px;
      right: -19px;
      top: -12px;
      width: 32px;
    }

    .track-1 {
      background: linear-gradient(90deg, $aco-blue, $aco-light-blue);
      height: 8px;
      border-radius: 4px;
    }
  }
}

.react-slider-input {
  color: $aco-gray-01;
}

.rating-filter {
  padding: 12px 16px 12px 12px;
  align-items: baseline;
}

.rating-count {
  font-size: 14px;
  line-height: 21px;
  font-weight: $font-weight-base;
  color: $aco-gray-01;
  position: absolute;
  bottom: 12px;
}

.accordion-body {
  & .ais-ClearRefinements {
    display: flex;
    justify-content: end;

    & .clear-single-refinement {
      background: none !important;
      border: none;
      padding: 0 !important;
      color: $aco-blue;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: $aco-dark-blue-hover;
      }
    }
  }
}

.clear-all-refinements {
  &.ais-ClearRefinements-button--disabled {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .refinement-count {
    right: 36px;
  }
}

@include media-breakpoint-up(xl) {
  .refinement-count {
    right: 48px;
  }
}

.algolia-hits-list {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.algolia-accordion {
  border-bottom: 1px solid $aco-gray-06;
}

@include media-breakpoint-down(lg) {
  .algolia-hits-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@include media-breakpoint-down(sm) {
  .algolia-hits-list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.search-result-products-list {
  list-style: none;

  .hit-item {
    border: 2px solid $aco-gray-06;
    border-radius: 24px;
    padding: 1em;

    .card {
      border: none;
    }

    .card-body {
      padding: 0;

      .eyecatcher {
        margin-left: -24px;
      }

      img {
        max-height: 162px;
      }
    }

    .rating {
      .amount {
        color: $aco-gray-01;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }

    .unit-price-section {
      min-height: auto;
      line-height: 14px;
    }

    .product-bullet-point {
      min-height: auto;
    }

    .status-text {
      span {
        font-weight: 400 !important;
      }
    }

    .product-price {
      font-weight: 400;
    }
  }
}

/* stylelint-disable */

#category-heading-title {
  line-height: 64px;
  font-size: 48px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #01063F;
}

#category-collapse-text {
  font-size: 16px;
  text-align: left;
  padding: 0 5px 0 0;
  max-width: 1500px;
  margin: 0 auto;
  color: #01063F;
  float: left;
}


#category-anchor-text {
  color: #003399;
  margin-top: 8px;
  display: block;
  cursor: pointer;
}

#subcategory-name {
  font-size: 16px;
  font-weight: 700;
  color: #003399;
  margin: 0;
}

#subcategory-row {
  font-weight: bold;
}
