/* Offcanvas */
@use "sass:map";

/**
 Variables
 */
$offcanvas-padding-y: $modal-inner-padding;
$offcanvas-padding-x: $modal-inner-padding;
$offcanvas-horizontal-width: 400px;
$offcanvas-vertical-height: 30vh;
$offcanvas-transition-duration: 0.3s;
$offcanvas-border-color: $modal-content-border-color;
$offcanvas-border-width: $modal-content-border-width;
$offcanvas-title-line-height: $modal-title-line-height;
$offcanvas-bg-color: $aco-dark-blue;
$offcanvas-color: $aco-white;
$offcanvas-box-shadow: none;
$offcanvas-backdrop-bg: $modal-backdrop-bg;
$offcanvas-backdrop-opacity: $modal-backdrop-opacity;

/**
 BS default import
 */
@import "~bootstrap/scss/offcanvas";

.offcanvas {
  z-index: $modal-z-index;

  &.offcanvas-bottom {
    border-radius: $border-radius-lg $border-radius-lg 0 0;
  }

  &.offcanvas-top {
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
  }

  &.offcanvas-start {
    border-radius: 0 $border-radius-lg $border-radius-lg 0;
  }

  &.offcanvas-end {
    border-radius: $border-radius-lg 0 0 $border-radius-lg;
  }

  & .offcanvas-header {
    & .btn-close {
      margin: 0;
    }

    & .offcanvas-title {
      font-weight: $font-weight-medium;
      font-size: $font-size-base;
    }
  }

  &.offcanvas-blue {
    background-color: $aco-dark-blue;
  }

  &.offcanvas-gray {
    color: $aco-dark-blue;
    background-color: $aco-gray-06;
  }

  &.offcanvas-white {
    color: $aco-dark-blue;
    background-color: $aco-white;
  }

  &.filter {
    background-color: $aco-white;

    & .offcanvas-header {
      & .offcanvas-title {
        font-weight: $font-weight-medium;
        font-size: $font-size-base;
      }
    }

    & .offcanvas-body {
      .accordion-body {
        border-bottom-right-radius: map.get($radius-map, 24);
        border-bottom-left-radius: map.get($radius-map, 24);
      }
    }
  }
}

@media screen and (max-width: map.get($grid-breakpoints, "md")) {
  .offcanvas {
    &.filter {
      right: 0;
      left: 0;
      height: 110vh;
      max-height: 100%;
      border-top: 0 solid transparent;
      transform: translateY(100%);

      &.show {
        transform: none;
      }

      .offcanvas-body {
        flex-grow: 0;
        border-bottom: 0;
        padding: 0 1rem;

        .accordion-body {
          max-height: 356px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: $aco-gray-04;
            border-radius: 20px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $aco-light-blue;
            border-radius: 20px;
          }
        }
      }

      .offcanvas-footer {
        margin: map.get($spacers, "spacing-32") 0;
      }
    }
  }
}

@media screen and (min-width: calc(map.get($grid-breakpoints, "md") + 1px)) {
  .offcanvas {
    &.filter {
      top: 0;
      right: 0;
      width: 100%;
      max-width: 800px;
      border-left: 0 solid transparent;
      transform: translateX(100%);
      border-radius: 0;

      &.show {
        transform: none;
      }

      & .offcanvas-header {
        .offcanvas-title {
          font-size: $h1-font-size;
        }
      }

      .btn-close {
        padding: $spacer;
      }

      .offcanvas-body {
        width: 100%;
        max-width: 564px;
        padding: 0 $offcanvas-padding-x 0 5rem;
        margin: $offcanvas-padding-y 0;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: $aco-gray-04;
          border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $aco-light-blue;
          border-radius: 20px;
        }
      }
    }
  }
}
