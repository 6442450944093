/**
 Variables
 */
$rating-stars-color: $primary;
$rating-stars-size-sm: 18px;
$rating-stars-size-md: 22px;
$rating-stars-size-lg: 27px;
$rating-stars-size-xl: 34px;

/**
 Custom Styles
 */
rating-stars {
  padding-right: 0.5rem;
  color: $rating-stars-color;
  line-height: 1rem;

  // anti-CLS
  &[size="sm"] {
    min-width: 98px;
  }

  &[size="md"] {
    min-width: 121px;
  }

  &[size="lg"] {
    min-width: 143px;
  }

  &[size="xl"] {
    min-width: 177px;
  }

  svg {
    height: auto;
  }

  .rating-star-size-sm {
    font-size: $rating-stars-size-sm;

    svg {
      width: $rating-stars-size-sm;
    }
  }

  .rating-star-size-md {
    font-size: $rating-stars-size-md;

    svg {
      width: $rating-stars-size-md;
    }
  }

  .rating-star-size-lg {
    font-size: $rating-stars-size-lg;

    svg {
      width: $rating-stars-size-lg;
    }
  }

  .rating-star-size-xl {
    font-size: $rating-stars-size-xl;

    svg {
      width: $rating-stars-size-xl;
    }
  }
}
