/* Popovers */

@use "sass:map";

/**
 Variables
 */
$popover-font-size: $font-size-sm;
$popover-bg: $aco-blue;
$popover-max-width: 276px;
$popover-border-width: 0;
$popover-border-color: transparent;
$popover-border-radius: map.get($radius-map, 8);
$popover-inner-border-radius: subtract(
  $popover-border-radius,
  $popover-border-width
);
$popover-box-shadow: $box-shadow;
$popover-header-bg: $aco-blue;
$popover-header-color: $aco-white;
$popover-header-padding-y: map.get($spacers, "spacing-8");
$popover-header-padding-x: $spacer;
$popover-body-color: $aco-white;
$popover-body-padding-y: $spacer;
$popover-body-padding-x: $spacer;
$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-color: $popover-bg;
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);
$popover-z-index: 100;
$btn-close-width: 16px;
$btn-close-height: $btn-close-width;

/**
 BS default import
 */
@import "~bootstrap/scss/popover";

.popover {
  pointer-events: all;
  position: relative;
  z-index: $popover-z-index;
  color: $aco-white;

  .popover-body {
    color: currentcolor;
  }

  & button.close {
    position: absolute;
    top: calc($popover-body-padding-y / 2);
    right: calc($popover-body-padding-x / 2);
    padding: 0;
    border-radius: 100px;
    overflow: hidden;
    min-height: auto;
    line-height: 0;
    width: $btn-close-width;
    height: $btn-close-height;
  }

  &-light {
    background-color: $aco-white;
    color: $aco-dark-blue;
  }

  & .cms-main-section:not(.apply-to-last) {
    padding-bottom: 0;
  }

  & .popover-header {
    padding-bottom: 0.25rem;
  }

  .popover-arrow {
    &::after {
      border-top-color: $aco-white;
    }
  }
}
