@use "sass:map";

.code-section {
  position: relative;

  & pre.code {
    width: 100%;
    background: $aco-gray-06;
    color: $aco-blue;
    border-radius: map.get($radius-map, 16);
  }

  & button[data-copy] {
    position: absolute;
    top: map.get($spacers, "spacing-4");
    right: map.get($spacers, "spacing-4");
  }

  & .snippet {
    display: none;
  }
}
