/* List group */

@use "sass:map";

/**
 Variables
 */
$list-group-color: $aco-blue;
$list-group-bg: $aco-white;
$list-group-border-color: $aco-gray-06;
$list-group-border-width: $border-width;
$list-group-focus-border-width: 2px;
$list-group-focus-border-color: $aco-light-blue;
$list-group-border-radius: 0;
$list-group-item-padding-y: $spacer * 0.5;
$list-group-item-padding-x: $spacer;
$list-group-item-bg-scale: 0;
$list-group-item-color-scale: 0;
$list-group-hover-bg: transparent;
$list-group-hover-color: $aco-dark-blue;
$list-group-hover-aco-blue-color: $aco-light-blue;
$list-group-hover-aco-dark-blue-color: $aco-light-blue;
$list-group-aco-blue-color: $aco-white;
$list-group-aco-dark-blue-color: $aco-white;
$list-group-aco-blue-bg: $aco-blue;
$list-group-aco-dark-blue-bg: $aco-dark-blue;
$list-group-aco-gray-bg: $aco-gray-06;
$list-group-active-color: transparent;
$list-group-active-bg: transparent;
$list-group-active-border-color: transparent;
$list-group-disabled-color: $aco-dark-blue-disabled;
$list-group-disabled-bg: $aco-white;
$list-group-action-color: transparent;
$list-group-action-hover-color: transparent;
$list-group-action-active-color: transparent;
$list-group-action-active-bg: transparent;
$list-group-link-icon-size: 1rem;
$list-group-item-height: 4rem;
$list-group-link-icon-margin-y: 0.75rem;
$list-group-link-icon-margin-x: 0.75rem;
$list-group-back-link-icon-margin-right: 1.5rem;
$list-group-link-thumbnail-margin-y: $list-group-link-icon-margin-y;
$list-group-link-thumbnail-margin-x: $list-group-link-icon-margin-x;
$list-group-item-headline-height: 5.25rem;
$list-group-item-headline-font-size: 24px;
$list-group-item-headline-font-family: "Playfair Display", $font-family-base;
$list-group-item-selection-color: $aco-dark-blue;
$list-group-item-selection-border-radius: map.get($radius-map, 8);
$list-group-item-radio-width: 3rem;
$list-group-item-radio-checked-bg: $aco-yellow;
$list-group-item-radio-checked-disabled-bg: $aco-yellow-disabled;
$list-group-item-radio-input-checked-bg: $aco-dark-blue;
$list-group-item-radio-input-checked-size: 1.5rem;
$list-group-item-radio-input-size: 1rem;
$list-group-item-radio-input-dark-bordercolor: $aco-white;
$list-group-item-radio-input-dark-bg: transparent;
$list-group-item-selection-disabled-color: $list-group-disabled-color;
$list-group-item-selection-height: 48px;
$list-group-item-radio-hover-color: $aco-yellow;
$list-group-item-radio-checked-hover-color: $aco-yellow-hover;
$list-group-item-radio-invalid-color: $aco-red;
$list-group-item-content-choice-gray-color: $aco-gray-01;
$list-group-item-content-choice-disabled-gray-color: $aco-gray-04;
$list-group-item-content-padding-x: 0.75rem;

/**
 BS default import
 */
@import "~bootstrap/scss/list-group";

@mixin use-checkbox-icon($color) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($color)}' stroke='#{to-rgb($color)}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin use-list-group-item-content-xs($pattern) {
  #{$pattern} {
    & .section {
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;

      &:first-child {
        align-items: flex-start;
      }

      &:last-child {
        align-items: flex-end;
        flex-direction: column-reverse;
      }
    }
  }
}

%list-group,
.list-group {
  font-weight: $font-weight-bold;

  & .list-group-item {
    display: flex;
    align-items: center;
    border-width: 0 0 $list-group-border-width 0;
    height: $list-group-item-height;
    line-height: normal;
    color: $list-group-color;

    &.list-group-item-action {
      padding-right: calc($list-group-item-padding-x + $list-group-link-icon-size + $list-group-link-icon-margin-x);
      position: relative;
      outline: none;
      cursor: pointer;

      & .start-icon {
        margin-right: $list-group-link-icon-margin-x;
      }

      &.list-group-item-back {
        padding-left: calc($list-group-item-padding-x + $list-group-link-icon-size + $list-group-back-link-icon-margin-right);

        &::before {
          @include use-back-icon($list-group-color);

          content: "";
          position: absolute;
          top: 50%;
          left: $list-group-item-padding-x;
          width: $list-group-link-icon-size;
          height: $list-group-link-icon-size;
          transform: translateY(-50%);
        }
      }

      &:not(.list-group-item-back)::after {
        @include use-link-icon($list-group-color);

        content: "";
        position: absolute;
        top: 50%;
        right: $list-group-item-padding-x;
        transform: translateY(-50%);
        width: $list-group-link-icon-size;
        height: $list-group-link-icon-size;
        display: none;

        @media screen and (max-width: map.get($grid-breakpoints, "xl")) {
          right: calc($list-group-item-padding-x / 2);
        }
      }

      .account-menu & {
        padding: 0.5rem calc($list-group-item-padding-x + $list-group-link-icon-size + $list-group-link-icon-margin-x) 0.5rem 0;

        .icon-with-badge .badge-icon {
          right: unset;
          left: 10px;
        }

        .start-icon {
          display: flex;
          position: relative;
          margin-right: $list-group-link-icon-margin-x;
          width: 40px;
          font-size: 1.3rem;

          &.fa-xl {
            font-size: 2.2rem;
          }
        }
      }

      &:focus {
        border-width: $list-group-focus-border-width;
        border-color: $list-group-focus-border-color;
      }
    }
  }

  & .list-group-item:hover {
    &.list-group-item-back {
      &::before {
        @include use-back-icon($list-group-hover-color);
      }
    }

    &.list-group-item-action {
      color: $list-group-hover-color;

      &:not(.list-group-item-back)::after {
        @include use-link-icon($list-group-hover-color);
      }
    }
  }

  & .list-group-item-multiple {
    &.list-group-item {
      & .list-group-item-body {
        display: flex;
        flex-direction: column;
      }

      & .content {
        color: $aco-gray-01;
        font-size: $font-size-xs;
        font-weight: $font-weight-normal;
        padding-top: map.get($spacers, "spacing-2");
      }
    }

    &.list-group-item-aco-blue {
      & .content {
        color: $aco-gray-04;
      }
    }

    &.list-group-item-thumbnail {
      border-width: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;

      & .thumbnail {
        height: 100%;
        border-style: solid;
        border-width: 1px;
        border-color: $aco-gray-06;
        border-radius: map.get($radius-map, 8);
        margin-right: $list-group-link-thumbnail-margin-x;
      }
    }
  }

  & .list-group-item-headline {
    font-weight: $font-weight-semibold;
    font-size: $list-group-item-headline-font-size;
    height: $list-group-item-headline-height;
    font-family: $list-group-item-headline-font-family;
    background-color: $list-group-aco-gray-bg;
    border-color: $aco-gray-05;
  }

  & .list-group-item-selection {
    display: flex;
    align-items: center;
    padding: 0;
    overflow: hidden;
    color: $list-group-item-selection-color;
    background: $list-group-bg;
    border: none;
    height: $list-group-item-selection-height;

    &:focus-within {
      border-color: $list-group-focus-border-color;
      border-width: $list-group-focus-border-width;
    }

    & .list-group-item-radio {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $list-group-item-radio-width;
      height: 100%;
      border-style: solid;
      border-radius: $list-group-item-selection-border-radius 0 0 $list-group-item-selection-border-radius;
      border-width: $list-group-border-width 0 $list-group-border-width $list-group-border-width;
      border-color: $list-group-border-color;

      &.input-checked {
        background-color: $list-group-item-radio-checked-bg;
        border: none;
      }

      & input.form-check-input {
        width: $list-group-item-radio-input-size;
        height: $list-group-item-radio-input-size;
        padding: 0.1rem;

        &:checked {
          @include use-checkbox-icon($list-group-item-radio-checked-bg);

          width: $list-group-item-radio-input-checked-size;
          height: $list-group-item-radio-input-checked-size;
          background-origin: content-box;
          background-color: $list-group-item-radio-input-checked-bg;
          border-color: transparent;
        }

        &:disabled {
          &:not(:checked) {
            border-color: $list-group-item-selection-disabled-color;
          }

          &:checked {
            @include use-checkbox-icon($list-group-item-radio-checked-disabled-bg);

            background-color: $list-group-item-selection-disabled-color;
          }
        }
      }
    }

    &.disabled-item-selection .list-group-item-radio.input-checked {
      background-color: $list-group-item-radio-checked-disabled-bg;
    }

    &:not(.disabled-item-selection):hover {
      & .list-group-item-radio {
        background: $list-group-item-radio-hover-color;

        & input.form-check-input:not(:checked) {
          background: $list-group-item-radio-hover-color;
        }

        &.input-checked {
          background: $list-group-item-radio-checked-hover-color;

          & input.form-check-input:checked {
            @include use-checkbox-icon($list-group-item-radio-checked-hover-color);
          }
        }
      }
    }

    &.invalid-item-selection {
      border-width: $list-group-focus-border-width;
      border-color: $list-group-item-radio-invalid-color;
    }

    & .list-group-item-content {
      display: flex;
      align-items: center;
      flex-grow: 1;
      height: 100%;
      padding-left: $list-group-item-content-padding-x;
      border-style: solid;
      border-radius: 0 $list-group-item-selection-border-radius $list-group-item-selection-border-radius 0;
      border-width: $list-group-border-width $list-group-border-width $list-group-border-width 0;
      border-color: $list-group-border-color;

      & .section {
        display: flex;
        align-items: center;
        flex-grow: 1;

        &:first-child {
          justify-content: flex-start;
        }

        &:last-child {
          justify-content: flex-end;
        }

        & .choice {
          color: $list-group-item-selection-color;
          margin-right: $list-group-item-content-padding-x;
          font-weight: $font-weight-normal;

          &.choice-bold {
            font-weight: $font-weight-bold;
          }

          &.choice-gray {
            color: $list-group-item-content-choice-gray-color;
            font-size: $font-size-sm;
          }
        }
      }

      &.list-group-item-content-xs {
        @include use-list-group-item-content-xs("&");
      }
    }

    &.disabled-item-selection .list-group-item-content {
      color: $list-group-item-selection-disabled-color;

      & .section {
        & .choice {
          color: $list-group-item-selection-disabled-color;

          &.choice-gray {
            color: $list-group-item-content-choice-disabled-gray-color;
          }
        }
      }
    }

    & .list-group-item-logo {
      width: fit-content;
      max-width: 30%;
      height: 100%;
      padding: 0 1rem;
    }

    & .list-group-item-content .list-group-item-logo {
      width: fit-content;
      max-width: 30%;
      height: 100%;
      padding: 0 1rem;
    }
  }
}

.list-group.dark {
  & .list-group-item {
    &.list-group-item-selection {
      background-color: transparent;
      color: $aco-white;

      & .list-group-item-radio {
        border-color: rgba($list-group-border-color, 40%);

        &:not(.input-checked) input {
          border-color: $list-group-item-radio-input-dark-bordercolor;
          background-color: $list-group-item-radio-input-dark-bg;

          &:hover {
            border-color: $list-group-hover-color;
          }
        }
      }

      & .list-group-item-content {
        border-color: rgba($list-group-border-color, 40%);
      }
    }
  }
}

.list-group:last-child .list-group-item {
  border-bottom: none !important;
}

.list-style-type-none {
  list-style-type: none;
}

@media screen and (max-width: map.get($grid-breakpoints, "sm")) {
  @include use-list-group-item-content-xs(".list-group .list-group-item-selection .list-group-item-content");
}

@include list-group-item-variant-custom("aco-blue", $list-group-aco-blue-bg, $list-group-aco-blue-color, $list-group-hover-aco-blue-color);
@include list-group-item-variant-custom("aco-gray-06", $list-group-aco-gray-bg, $list-group-color, $list-group-hover-color);
@include list-group-item-variant-custom("aco-dark-blue", $list-group-aco-dark-blue-bg, $list-group-aco-dark-blue-color, $list-group-hover-aco-dark-blue-color);
