@use "sass:map";

/* Article Card */

.article-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "md")} + 1px)) {
    flex-direction: column;
  }

  & .article-card-image {
    min-height: 100px;
    min-width: 100px;
    width: 100px;
    height: 100px;
    border-radius: 24px;
    overflow: hidden;

    @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "md")} + 1px)) {
      width: 100%;
      height: auto;
    }

    & .article-card-image-wrapper {
      object-fit: cover;
      aspect-ratio: 1;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1;
    }
  }

  & .article-card-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2px;
    padding-top: map.get($spacers, "spacing-24");
    padding-right: map.get($spacers, "spacing-24");

    @media only screen and (max-width: calc(#{map.get($grid-breakpoints, "md")} + 1px)) {
      padding-top: 0;
      padding-left: map.get($spacers, "spacing-24");
    }

    & .article-card-title {
      color: $aco-gray-01;
      font-weight: $font-weight-bold;
      font-size: $font-size-xs;
      line-height: 1.5rem;
      text-transform: uppercase;
    }

    & .article-card-subtitle {
      color: $aco-blue;
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      line-height: 1.5rem;
    }
  }

  &:hover {
    cursor: pointer;

    & .article-card-body {
      & .article-card-subtitle {
        color: $aco-dark-blue;
      }
    }
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;

    & .article-card-image {
      border: 2px solid $aco-dark-blue;
    }
  }
}
