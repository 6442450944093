@use "sass:color";

/* ab-test styles */
.swiper-button-thumb.swiper-button-lock {
  display: none;
}

.swiper-button-thumb {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: $aco-gray-06;
  transition: background-color 100ms;
  cursor: pointer;

  &:hover {
    background-color: color.scale($aco-gray-06, $lightness: -10%);
  }

  &.swiper-button-disabled {
    opacity: 0.2 !important;
    cursor: default;
  }
}

.swiper-button-thumb-prev {
  margin-bottom: 12px;
}

.swiper-button-thumb-next {
  margin-top: 12px;
}

.swiper-button-thumb::after {
  content: "";
  display: block;
  width: 0.95rem;
  height: 0.95rem;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-dark-blue)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-button-thumb-prev::after {
  transform: rotate(-180deg);
}

[data-slider="product-images-thumbs"] {
  @include media-breakpoint-up(lg) {
    height: 264px;
  }

  @include media-breakpoint-up(xl) {
    height: 352px;
  }

  @include media-breakpoint-up(xxl) {
    height: 440px;
  }

  .video-player-thumb {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: fit-content;
    height: fit-content;

    .controls {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: flex-end;
      width: 100%;

      & .overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      & .control-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
        border-radius: $video-player-button-border-radius;
        background-color: $video-player-primary-color;
        width: 40px;
        height: 40px;
        min-height: 40px;
        min-width: 40px;

        &.play-button {
          @extend %control-button-player;
        }
      }
    }
  }

  .video-thumbnail {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    /* stylelint-disable-next-line */
    .svg-inline--fa {
      position: absolute;
    }
  }

  .swiper-wrapper {
    flex-direction: column;
  }

  .swiper-slide {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;

    figure,
    .video-player-thumb {
      position: relative;
      margin-bottom: 0;
      border: 2px solid transparent;
      border-radius: 6px;
      overflow: hidden;
    }
  }

  .swiper-slide-thumb-active {
    figure,
    .video-player-thumb {
      border: 2px solid $aco-dark-blue;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}

[data-slider="product-images-large"] {
  @include media-breakpoint-up(lg) {
    border-radius: 6px;
    border: 1px solid $aco-gray-05;
  }

  .swiper-slide {
    figure {
      overflow: visible;

      &.zoom {
        background-repeat: no-repeat;
        background-position-x: -200%;
      }
    }

    img {
      height: auto;
      width: 100%;
    }
  }

  wishlist-button {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 10001;

    span {
      svg {
        color: $aco-white;
      }

      &.active {
        svg {
          color: $aco-dark-blue;
        }
      }
    }
  }

  .gallery-bonus-points {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 120px;
    right: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $aco-blue;
    border-radius: 50%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: $aco-white;
    z-index: 10001;

    span {
      display: block;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }

  & .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    opacity: 1;
    background: $aco-white;
    border: 1px solid $aco-dark-blue;

    &:hover {
      background-color: $aco-dark-blue;

      svg {
        color: $aco-white !important;
      }
    }

    &-active {
      background: $aco-light-blue;
      border: transparent;

      &:not(:hover) {
        border-color: $aco-light-blue;
      }
    }
  }
}

.gallery-eyecatcher {
  position: absolute;
  top: 38px;
  left: 2px;
  z-index: 1000;

  @include media-breakpoint-down(md) {
    top: 10%;
  }

  .overlay-badge {
    position: relative;
    overflow: visible;
  }
}

/* product area styles */
.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
  font-weight: 500;
}

.fs-20 {
  font-size: 20px;
  font-weight: 700 !important;
}

.fs-24 {
  font-size: 24px;
  font-weight: 700 !important;
}

.fw-400 {
  font-weight: 400;
}

.fw-700 {
  font-weight: 700;
}

.pointer {
  cursor: pointer;
}

.border-rounded {
  border-radius: 4px;

  &.bg-aco-green-disabled {
    background-color: #9cd5a6;
  }
}

.margin-top-fix {
  margin-top: -23px;
}

/* pricing */
.pricing-box {
  .saving-badge-variation {
    background-color: $aco-orange;
    padding: 2px 8px;
    border-radius: 0.25rem;
  }

  .price {
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
  }
}

.product-price {
  .strike-through-price-variation {
    color: $aco-gray-02;
    font-size: $font-size-base;
    font-style: normal;
    font-weight: 500;
    text-decoration: line-through;
  }
}

.saving-calc {
  font-size: $font-size-base;
  font-weight: 500;
  line-height: 24px;
}

/* keyfacts */
.keyfacts {
  &.ab-variation {
    padding: 0;
  }
}

/* alternative sizes component */
.package-size-selection {
  text-align: center;
  font-size: $font-size-base * 1.25;
  padding: 1rem;
  border: 2px solid $aco-light-blue;
  border-radius: 8px;
  min-height: 160px;
  box-shadow:
    0 0 0 0 #0037370d,
    0 1px 2px #0037370d,
    0 3px 3px 0 #0037370a,
    0 7px 4px 0 #00373708,
    0 12px 5px 0 #00373708,
    0 19px 5px 0 #00373708;

  input {
    color: $aco-dark-blue;

    &:checked {
      @include use-checkbox-icon($aco-white);

      width: $list-group-item-radio-input-checked-size;
      height: $list-group-item-radio-input-checked-size;
      background-origin: content-box;
      background-color: $list-group-item-radio-input-checked-bg;
      border-color: transparent;
      appearance: none;
      border-radius: 20rem;
    }
  }

  &.active-package {
    border: 2px solid $aco-dark-blue;
  }

  .package-size-information {
    font-weight: $font-weight-bold;

    .ingredients {
      color: $aco-dark-blue;
    }

    .price {
      color: $aco-orange;
    }

    del {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

[data-test="variation"] {
  .fixed-product-header,
  [data-id="fixed-product-header"] {
    top: auto;
    bottom: 0;
    transform: translateY(100%);
    padding: 9px 1rem 1rem;
    border-top: 1px solid $aco-gray-05;

    &.visible {
      transform: translateY(0);
    }

    .container {
      padding: 0;
    }

    img {
      width: 65px;
      height: 67px;
      object-fit: contain;
    }

    [data-selector="price-cart-wrap"] {
      display: flex;
      column-gap: 0.5rem;
    }

    cart-button {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 295px;
      }
    }

    cart-button::part(button) {
      width: 100%;
      min-height: 40px;
    }

    .badge.badge-price-discount {
      border-radius: 0;
    }

    .pe-spacing-4 {
      font-size: 14px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }

    .badge.badge-price-discount .discount,
    .badge-price-discount.overlay-badge .discount {
      border-radius: 4px;
      padding: 0 4px;
      font-size: 14px;
    }

    .badge.badge-price-discount .price,
    .badge-price-discount.overlay-badge .price {
      background-color: transparent;
      border-radius: 0;
      color: $aco-orange;
      font-size: 20px;

      @include media-breakpoint-up(md) {
        font-size: 24px;
      }
    }

    [data-selector="product-name"] {
      font-size: 14px;
    }

    [data-selector="product-delivery"] {
      position: relative;
      flex-flow: column nowrap;
      padding-left: 20px;
      font-size: 14px;

      svg {
        position: absolute;
        left: 0;
        margin-top: 3px;
        margin-right: 5px;
        color: $aco-green;
      }
    }
  }

  [data-selector="was-price"] {
    height: 30px;
  }

  [data-selector="save-price"] {
    height: 21px;
  }
}

[data-pagetype="pdp"] {
  .button-userlike-chat span.btn-circle.fixed-header-active {
    bottom: 240px;

    @include media-breakpoint-up(md) {
      bottom: 170px;
    }
  }

  [data-selector="docyetPreloadButton"].docyet-preload-button.btn.fixed-header-active {
    bottom: 170px;

    @include media-breakpoint-up(md) {
      bottom: 110px;
    }
  }
}
