@use "sass:map";

$auto-suggest-grid-breakpoint-lg: map.get($grid-breakpoints, "xl");

.auto-suggest-wrapper {
  background-color: $aco-gray-06;
  color: $aco-white;
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 0 map.get($spacers, "spacing-16");
  z-index: 1001;

  @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
    background-color: transparent;
    position: relative;
    z-index: 1001;
    height: 20px;
  }

  & .icon-start {
    color: $aco-blue;
    margin-right: 8px;
    padding-top: 2px;
    position: absolute;
    top: -2px;
    left: 0;
    padding-left: map.get($spacers, "spacing-12");
  }

  & auto-suggest,
  %auto-suggest {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  & .auto-suggest-input {
    width: 100%;
    all: unset;
    background-color: transparent;
    border: none;
    padding: map.get($spacers, "spacing-8") 0;
    color: $aco-blue;

    @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
      background-color: $aco-gray-06;
      width: 100%;
      position: absolute;
      left: 0;
      top: -10px;
      z-index: -1;
      border-radius: 20px;
      padding: 0.5rem 20px 0.5rem 42px;
      box-sizing: border-box;
    }

    &::placeholder {
      color: $aco-gray-01;
    }
  }

  & .auto-suggest-list {
    position: absolute;
    left: 0;
    top: 124px;
    width: 100%;
    background-color: $aco-blue;

    & b {
      color: $aco-yellow;
      font-weight: $font-weight-bold;
    }

    @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
      border-top: none;
      border-radius: 0 0 map.get($radius-map, 20) map.get($radius-map, 20);
      z-index: -12;
      margin-top: -17px;
      padding-top: 20px;
      top: 26px;
      box-shadow: inset 0 0 0 1px $aco-blue-disabled;
    }

    & h3 {
      font-family: $font-family-base;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      background-color: rgba($aco-white, 0.1);
      padding: map.get($spacers, "spacing-8");
      padding-left: map.get($spacers, "spacing-32");
      text-transform: uppercase;
    }

    & ul {
      padding: map.get($spacers, "spacing-8") map.get($spacers, "spacing-32");
      list-style: none;
      margin: 0;

      & li {
        & a {
          color: $aco-white;
          height: 100%;
          align-items: center;
          flex-grow: 1;
          position: relative;
          padding-right: 50px;
          word-break: break-word;
          display: inline;
          line-height: $line-height-sm;

          &:hover {
            background-color: rgba($aco-white, 0.1);
          }

          &::after {
            @include use-link-icon($aco-white);

            content: "";
            position: absolute;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
            width: 15px;
            height: 100%;
          }

          & img {
            width: 64px;
            height: 64px;
            border-radius: map.get($radius-map, 8);
            margin-right: map.get($spacers, "spacing-12");
          }

          & span {
            color: $aco-white;
          }
        }
      }

      &.auto-suggest-keywords {
        padding-left: map.get($spacers, "spacing-16");

        & li {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $aco-blue-disabled;

          & a {
            padding: 24px 50px 24px 16px;
            word-break: break-word;
          }
        }
      }

      &.auto-suggest-products {
        & li {
          margin-bottom: map.get($spacers, "spacing-24");

          & a {
            word-break: break-word;
            display: flex;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.auto-suggest-categories,
      &.auto-suggest-content {
        & li {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $aco-blue-disabled;
          min-height: map.get($spacers, "spacing-64");

          & a {
            padding: map.get($spacers, "spacing-12") map.get($spacers, "spacing-48") map.get($spacers, "spacing-12") map.get($spacers, "spacing-16");
            word-break: break-word;

            span {
              font-size: $font-size-xs;
              color: $aco-gray-04;
            }
          }
        }

        @media only screen and (max-width: calc($auto-suggest-grid-breakpoint-lg - 1px)) {
          padding: map.get($spacers, "spacing-8") map.get($spacers, "spacing-16");
        }
      }

      &.auto-suggest-content {
        padding-left: map.get($spacers, "spacing-16");

        & li {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $aco-blue-disabled;

          & a {
            padding: map.get($spacers, "spacing-12") map.get($spacers, "spacing-48") map.get($spacers, "spacing-12") map.get($spacers, "spacing-16");
            word-break: break-word;
          }
        }
      }

      & li:last-child {
        border-bottom: none;
      }
    }
  }

  & .icon-end {
    color: $aco-blue;
    margin-left: 8px;

    @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
      position: absolute;
      top: 0;
      right: 20px;
    }
  }

  &:focus-within,
  :focus-visible {
    @media only screen and (max-width: $auto-suggest-grid-breakpoint-lg) {
      background-color: $aco-white;
    }

    & .icon-start,
    & .icon-end {
      color: $aco-blue;
    }

    & auto-suggest,
    %auto-suggest {
      & .auto-suggest-input {
        outline: none;
        color: $aco-blue;

        @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
          background-color: $aco-white;
        }

        &::placeholder {
          color: $aco-blue;
        }
      }
    }
  }

  &.white {
    @media only screen and (max-width: $auto-suggest-grid-breakpoint-lg) {
      background-color: $aco-gray-06;
    }

    & auto-suggest,
    %auto-suggest {
      & .auto-suggest-input {
        color: $aco-blue;

        &::placeholder {
          color: $aco-gray-01;
          opacity: 1;
        }

        @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
          background-color: $aco-gray-06;
        }
      }

      & .auto-suggest-list {
        background-color: $aco-white;

        & b {
          color: $aco-orange;
        }
      }
    }

    & .icon-start,
    & .icon-end {
      color: $aco-blue;
    }
  }

  &.gray {
    @media only screen and (max-width: $auto-suggest-grid-breakpoint-lg) {
      background-color: $aco-white;
    }

    & auto-suggest {
      & .auto-suggest-input {
        color: $aco-blue;

        @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
          background-color: $aco-white;
        }

        &::placeholder {
          color: $aco-gray-01;
        }
      }

      & .auto-suggest-list {
        background-color: $aco-white;

        & b {
          color: $aco-orange;
        }
      }
    }

    & .icon-start {
      color: $aco-blue;
    }

    & .icon-end {
      color: $aco-blue;
    }
  }

  &.active {
    @media only screen and (max-width: $auto-suggest-grid-breakpoint-lg) {
      background-color: $aco-white;
    }

    & .icon-start,
    & .icon-end {
      color: $aco-blue;
    }

    & auto-suggest,
    %auto-suggest {
      & .auto-suggest-input {
        color: $aco-blue;

        @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
          background-color: $aco-white;
        }

        &::placeholder {
          color: $aco-blue;
        }
      }

      & .auto-suggest-list {
        background-color: $aco-blue;
        max-height: calc(100vh - (124px + 37px));
        overflow-y: auto;

        @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
          box-shadow: inset 0 0 0 1px $aco-blue-disabled;
          overflow-y: auto;
          max-height: 80vh;

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: $aco-gray-04;
            border-radius: 20px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $aco-light-blue;
            border-radius: 20px;
          }
        }
      }
    }

    &.white,
    &.gray {
      @media only screen and (max-width: $auto-suggest-grid-breakpoint-lg) {
        box-shadow: inset 0 0 0 1px $aco-gray-05;
      }

      & auto-suggest,
      %auto-suggest {
        & .auto-suggest-input {
          @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
            box-shadow: inset 0 0 0 1px $aco-gray-05;
          }
        }

        & .auto-suggest-list {
          color: $aco-blue;
          background-color: $aco-white;

          @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
            box-shadow: inset 0 0 0 1px $aco-gray-05;
          }

          & li {
            & a {
              color: $aco-blue;

              &:hover {
                background-color: $aco-gray-06;
              }
            }

            & span {
              color: $aco-blue;
            }
          }
        }
      }
    }
  }

  &.white:focus-within,
  &.white:focus-visible,
  &.gray:focus-within,
  &.gray:focus-visible {
    @media only screen and (max-width: $auto-suggest-grid-breakpoint-lg) {
      background-color: $aco-white;
      box-shadow: inset 0 0 0 1px $aco-gray-05;
    }

    & auto-suggest,
    %auto-suggest {
      & .auto-suggest-input {
        @media only screen and (min-width: calc($auto-suggest-grid-breakpoint-lg + 1px)) {
          background-color: $aco-white;
          box-shadow: inset 0 0 0 1px $aco-gray-05;
        }
      }
    }
  }

  .auto-suggest {
    @extend %auto-suggest;
  }
}

[data-selector="auto-suggest-backdrop"] {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: block;

  @media only screen and (max-width: calc($auto-suggest-grid-breakpoint-lg - 1px)) {
    display: none !important;
  }
}

/* stylelint-disable no-descending-specificity */
[data-test="variation"].auto-suggest-wrapper {
  .auto-suggest-list {
    display: flex;
    flex-flow: column nowrap;

    .auto-suggest-keywords {
      order: 0;
    }

    .auto-suggest-products {
      order: 4;
    }

    .auto-suggest-categories {
      order: 2;
    }

    .auto-suggest-content {
      order: 5;
    }

    h3 {
      &:nth-of-type(1) {
        order: 3;
      }

      &:nth-of-type(2) {
        order: 1;
      }
    }
  }
}
