[data-selector="docyetPreloadButton"].docyet-preload-button.btn {
  position: fixed !important;
  right: 16px;
  bottom: 20px;
  z-index: 50;
  appearance: none;
  border: none;
  box-shadow: 0 2px 6px rgba(0 0 0 / 30%);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  width: 50px;
  height: 50px;
}
