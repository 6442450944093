@use "sass:map";

$video-player-accent-color: $aco-dark-blue;
$video-player-primary-color: $aco-light-blue;
$video-player-button-border-radius: $border-radius-pill;
$video-player-controls-padding-bottom: map.get($spacers, "spacing-8");
$video-player-controls-color: $aco-white;
$video-player-controls-padding-between: map.get($spacers, "spacing-8");

.video-player {
  position: relative;
  width: fit-content;
  height: fit-content;

  & video {
    display: block;
    width: 100%;
    height: 100%;
    background: $aco-black;

    &::-webkit-media-controls {
      display: none;
    }
  }

  .controls {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;

    & .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & .controllers {
      position: relative;
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0 map.get($spacers, "spacing-12") $video-player-controls-padding-bottom;
      background-image: linear-gradient(0deg, #01063f 0%, transparent 100%);

      & .progress-wrapper {
        flex-grow: 1;
        padding: $video-player-controls-padding-between;
        position: relative;

        &::before {
          content: attr(current-time);
          position: absolute;
          left: $video-player-controls-padding-between;
          bottom: 0;
          transform: translateY(50%);
          display: flex;
          align-items: center;
          width: 20%;
          height: 100%;
          font-size: $font-size-xs;
          color: $aco-white;
          padding-top: map.get($spacers, "spacing-2");
        }

        &::after {
          content: attr(total-duration);
          position: absolute;
          right: $video-player-controls-padding-between;
          bottom: 0;
          transform: translateY(50%);
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 20%;
          height: 100%;
          font-size: $font-size-xs;
          color: $aco-gray-03;
          padding-top: map.get($spacers, "spacing-2");
        }
      }

      & .volume-range {
        display: none;
        padding: map.get($spacers, "spacing-12") $video-player-controls-padding-between;
        min-width: 76px;

        & [data-rangeslider="single"] {
          margin-bottom: 0;
        }

        & input {
          display: none;
        }

        & .noUi-handle {
          width: 16px;
          height: 16px;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        & .noUi-target {
          height: 2px;
        }
      }
    }

    & .control-button-player,
    %control-button-player {
      background-color: $video-player-primary-color;
    }

    & .control-button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      border-radius: $video-player-button-border-radius;
      background-color: transparent;
      width: 40px;
      height: 40px;
      min-height: 40px;
      min-width: 40px;

      &-sm {
        width: 16px;
        height: 16px;
        min-height: 16px;
        min-width: 16px;
        background-size: contain;
      }

      &-lg {
        width: 48px;
        height: 48px;
        min-height: 48px;
        min-width: 48px;
      }

      &.play-button {
        @extend %control-button-player;
      }

      &.pause-button {
        @extend %control-button-player;
      }

      &.sound-button,
      &.fullscreen-button {
        color: $aco-white;
      }
    }
  }
}
