@use "sass:map";
@import "../atoms/colors";

/* Login */

[data-selector="login_error"] {
  &.form-control {
    border: 0;
    color: $aco-red;
  }
}

.login-form {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .btn {
    max-width: none;
  }

  input:not(.select-arrow-active, #account-edit-gender-select) ~ .form-label {
    color: $aco-light-blue;
  }

  input:-webkit-autofill {
    background-color: $aco-dark-blue !important;
    -webkit-box-shadow: 0 0 0 1000px $aco-dark-blue inset !important;
    color: white !important;
    -webkit-text-fill-color: white !important;
  }

  &__tab-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 700px;
    padding: 1rem 2rem 2rem;
  }
}

[data-selector="login-modal"] {
  & .modal-dialog {
    align-items: center;
    justify-content: center;
  }

  & .modal-content {
    max-width: 420px;

    @include media-breakpoint-down(sm) {
      max-width: 300px;
    }
  }

  & .modal-title {
    @extend %headline;

    font-size: $h2-font-size;
  }

  & .modal-header {
    & .btn-close {
      width: 24px;
      height: 24px;
      padding: 0;
      background-size: 10px;
      transform: translate(-50%, 50%);
      min-height: fit-content;
      aspect-ratio: 1;
    }
  }
}

body[data-pagetype="register"] {
  .page-header {
    .header-white {
      display: none;
    }

    @include media-breakpoint-down(md) {
      .header-top {
        padding: 0.5rem 0 !important;

        .header-logo {
          flex: 0 0 auto;
          width: auto;
        }
      }
    }
  }

  [data-selector="userlikeButton"],
  [data-selector="docyetPreloadButton"] {
    display: none;
  }

  .login-form {
    .nav.nav-tabs .nav-link {
      height: auto;
    }
  }
}
