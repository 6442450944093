/* Image Select */

@use "sass:map";

/**
 Variables
 */

$image-select-small-height: 162px;
$image-select-large-height: 245px;
$image-select-checkbox-height: 24px;
$image-select-checkbox-width: 24px;
$image-select-checkbox-checked-bg: $aco-light-blue;
$image-select-checkbox-check-size: $font-size-base;
$image-select-checkbox-check-color: $aco-dark-blue;
$image-select-border-radius: $border-radius-lg;
$image-select-footer-default-bg-color: #010641cc; // cc adds 0.8 opacity
$image-select-footer-hover-bg-color: $aco-light-blue;
$image-select-footer-small-font-size: $font-size-xs;
$image-select-footer-large-font-size: $font-size-base;
$image-select-footer-font-weight: $font-weight-medium;
$image-select-footer-text-color: $aco-white;
$image-select-footer-text-hover-color: $aco-dark-blue;

/**
 BS default import
 */

@mixin use-checkbox-icon($color) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($color)}' stroke='#{to-rgb($color)}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: $image-select-checkbox-check-size;
}

/**
 Custom Styles
 */

.image-select-small {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: $image-select-border-radius;
  outline: 2px solid transparent;
  overflow: hidden;
  max-width: 162px;
  height: $image-select-small-height;
  aspect-ratio: 1;

  &-image {
    flex-grow: 1;
  }

  &:hover {
    & div {
      background-color: $aco-light-blue;
      color: $aco-dark-blue;
      transition: 0.15s ease-in-out;
    }
  }

  &:focus-within {
    outline: 2px solid $aco-light-blue;
  }

  &-checkbox {
    appearance: none;
    position: absolute;
    top: 16px;
    right: 16px;
    width: $image-select-checkbox-width;
    height: $image-select-checkbox-height;
    background-color: $aco-white;
    border-radius: 5px;
    outline: none;

    &-hidden {
      appearance: none;
      background-color: transparent;
      cursor: default;
    }

    &:checked {
      @include use-checkbox-icon($image-select-checkbox-check-color);

      background-color: $image-select-checkbox-checked-bg;
      transition: 0.15s ease-in-out;
    }
  }

  &-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $image-select-footer-default-bg-color;
    text-align: center;
    font-size: $image-select-footer-small-font-size;
    font-weight: $image-select-footer-font-weight;
    line-height: 20px;
    color: $image-select-footer-text-color;
    padding: map.get($spacers, "spacing-6") map.get($spacers, "spacing-16");
    border-bottom-left-radius: $image-select-border-radius;
    border-bottom-right-radius: $image-select-border-radius;
  }
}

.image-select-large {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: $image-select-border-radius;
  outline: 2px solid transparent;
  overflow: hidden;
  max-width: 245px;
  height: $image-select-large-height;
  aspect-ratio: 1;

  &-image {
    flex-grow: 1;
  }

  &:focus-within {
    outline: 2px solid $aco-light-blue;
  }

  &:hover {
    & div {
      background-color: $aco-light-blue;
      color: $aco-dark-blue;
      transition: 0.15s ease-in-out;
    }
  }

  &-checkbox {
    appearance: none;
    position: absolute;
    top: 16px;
    right: 16px;
    width: $image-select-checkbox-width;
    height: $image-select-checkbox-height;
    background-color: $aco-white;
    border-radius: 5px;
    outline: none;

    &-hidden {
      appearance: none;
      background-color: transparent;
      cursor: default;
    }

    &:checked {
      @include use-checkbox-icon($image-select-checkbox-check-color);

      background-color: $image-select-checkbox-checked-bg;
      transition: 0.15s ease-in-out;
    }
  }

  &-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $image-select-footer-default-bg-color;
    text-align: center;
    font-size: $image-select-footer-large-font-size;
    font-weight: $image-select-footer-font-weight;
    line-height: 24px;
    color: $image-select-footer-text-color;
    padding: map.get($spacers, "spacing-12") map.get($spacers, "spacing-16");
    border-bottom-left-radius: $image-select-border-radius;
    border-bottom-right-radius: $image-select-border-radius;
  }
}

.image-select-label {
  max-width: fit-content;
}
