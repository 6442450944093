@use "sass:map";

/* Alerts */

/**
 Variables
 */
$alert-padding-y: $spacer;
$alert-padding-x: map.get($spacers, "spacing-24");
$alert-padding-x-sm: $spacer;
$alert-margin-bottom: map.get($spacers, "spacing-16");
$alert-border-radius: map.get($radius-map, 12);
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;
$alert-bg-scale: -80%;
$alert-border-scale: -70%;
$alert-color-scale: 40%;
$alert-dismissible-padding-r: $alert-padding-x * 3;
$alert-icon-padding: 30px;
$alert-close-btn-width: 10px;
$alert-close-btn-height: 10px;
$alert-content-gap-close-btn: map.get($spacers, "spacing-8");
$alert-content-min-width: 356px;
$alert-min-height: 80px;
$alert-negative-padding-bottom: 82px;

/**
 Import BS default
 */
@import "~bootstrap/scss/alert";

.alert {
  display: flex;
  min-height: $alert-min-height;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: map.get($spacers, "spacing-16");
  color: black;
  margin-bottom: 0;
  border-radius: 0 0 $alert-border-radius $alert-border-radius;

  & .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: fit-content;
    min-width: $alert-content-min-width;
    gap: map.get($spacers, "spacing-16");
    padding: 0;
  }

  &-icon {
    @include circle-gradient(
      $center-bg-color: $aco-gray-06,
      $size: 48px,
      $border-main-color: $aco-blue,
      $border-secondary-color: transparent,
      $border-size: 20%
    );

    &::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{to-rgb($aco-blue)}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 47%;
    }
  }

  &-headline,
  & .boxContentTitle {
    font-weight: $font-weight-bold;
  }

  &-headline,
  &-copy {
    padding-right: calc($alert-close-btn-width + $alert-content-gap-close-btn);
  }

  & .btn-close {
    display: flex;
    align-self: flex-start;
    background-size: 100%;
    margin-top: map.get($spacers, "spacing-8");
    min-height: $alert-close-btn-width;
    min-width: $alert-close-btn-height;
    width: $alert-close-btn-width;
    height: $alert-close-btn-height;
  }

  @media screen and (max-width: map.get($grid-breakpoints, "sm")) {
    padding-left: $alert-padding-x-sm;
    padding-right: $alert-padding-x-sm;
  }
}

.alert.aco-blue {
  background: $aco-blue;

  & .alert-icon {
    @include circle-gradient(
      $center-bg-color: $aco-blue,
      $size: 48px,
      $border-main-color: $aco-light-blue,
      $border-secondary-color: transparent,
      $border-size: 20%
    );

    &::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{to-rgb($aco-light-blue)}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 47%;
    }
  }

  & .btn-close {
    filter: $btn-close-white-filter;
  }
}

.alert.is-info .alert-icon::after {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' fill='#{to-rgb($aco-blue)}' viewBox='-140 0 448 512'><path d='M160 448h-32V224c0-17.69-14.33-32-32-32L32 192c-17.67 0-32 14.31-32 32s14.33 31.1 32 31.1h32v192H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32S177.7 448 160 448zM96 128c26.51 0 48-21.49 48-48S122.5 32.01 96 32.01s-48 21.49-48 48S69.49 128 96 128z'/></svg>");
}

.alert.is-warning .alert-icon::after {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' fill='#{to-rgb($aco-blue)}' viewBox='-140 0 448 512'><path d='M64 352c17.69 0 32-14.32 32-31.1V64.01c0-17.67-14.31-32.01-32-32.01S32 46.34 32 64.01v255.1C32 337.7 46.31 352 64 352zM64 400c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1s40-17.9 40-39.1S86.09 400 64 400z'/></svg> ");
}

.alerts.aco-blue {
  background: $aco-blue;

  & .alert-icon {
    @include circle-gradient(
      $center-bg-color: $aco-blue,
      $size: 48px,
      $border-main-color: $aco-light-blue,
      $border-secondary-color: transparent,
      $border-size: 20%
    );

    &::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{to-rgb($aco-light-blue)}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 47%;
    }
  }

  & .btn-close {
    filter: $btn-close-white-filter;
  }
}

.alert.is-danger {
  & .alert-icon {
    @include circle-gradient(
      $center-bg-color: $aco-gray-06,
      $size: 48px,
      $border-main-color: $aco-red,
      $border-secondary-color: transparent,
      $border-size: 20%
    );

    &::after {
      background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' fill='#{to-rgb($aco-red)}' viewBox='-140 0 448 512'><path d='M64 352c17.69 0 32-14.32 32-31.1V64.01c0-17.67-14.31-32.01-32-32.01S32 46.34 32 64.01v255.1C32 337.7 46.31 352 64 352zM64 400c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1s40-17.9 40-39.1S86.09 400 64 400z'/></svg> ");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 47%;
    }
  }
}

.alert.aco-blue.is-info .alert-icon::after {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' fill='#{to-rgb($aco-light-blue)}' viewBox='-140 0 448 512'><path d='M160 448h-32V224c0-17.69-14.33-32-32-32L32 192c-17.67 0-32 14.31-32 32s14.33 31.1 32 31.1h32v192H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32S177.7 448 160 448zM96 128c26.51 0 48-21.49 48-48S122.5 32.01 96 32.01s-48 21.49-48 48S69.49 128 96 128z'/></svg>");
}

.alert,
.alerts {
  background: $aco-gray-06;
}

.alerts {
  display: flex;
  padding: map.get($spacers, "spacing-16") 0;
  width: fit-content;
  gap: map.get($spacers, "spacing-16");

  .alert {
    border-radius: 0;

    & + .alert {
      border-left: 1px solid $aco-blue-disabled;
    }
  }
}

.alerts.aco-blue .alert {
  background: $aco-blue;

  & .alert-icon {
    @include circle-gradient(
      $center-bg-color: $aco-blue,
      $size: 48px,
      $border-main-color: $aco-light-blue,
      $border-secondary-color: transparent,
      $border-size: 20%
    );

    &::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{to-rgb($aco-light-blue)}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 47%;
    }
  }

  & .btn-close {
    filter: $btn-close-white-filter;
  }
}

.alert.is-danger.aco-blue {
  & .alert-icon {
    @include circle-gradient(
      $center-bg-color: $aco-blue,
      $size: 48px,
      $border-main-color: $aco-red,
      $border-secondary-color: transparent,
      $border-size: 20%
    );

    &::after {
      background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' fill='#{to-rgb($aco-red)}' viewBox='-140 0 448 512'><path d='M64 352c17.69 0 32-14.32 32-31.1V64.01c0-17.67-14.31-32.01-32-32.01S32 46.34 32 64.01v255.1C32 337.7 46.31 352 64 352zM64 400c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1s40-17.9 40-39.1S86.09 400 64 400z'/></svg> ");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 47%;
    }
  }
}

.alert.is-warning.aco-blue,
.alerts.aco-blue .alert.is-warning {
  & .alert-icon {
    &::after {
      background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' fill='#{to-rgb($aco-light-blue)}' viewBox='-140 0 448 512'><path d='M64 352c17.69 0 32-14.32 32-31.1V64.01c0-17.67-14.31-32.01-32-32.01S32 46.34 32 64.01v255.1C32 337.7 46.31 352 64 352zM64 400c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1s40-17.9 40-39.1S86.09 400 64 400z'/></svg> ");
    }
  }
}

.alerts.aco-blue .alert.is-danger {
  & .alert-icon {
    @include circle-gradient(
      $center-bg-color: $aco-blue,
      $size: 48px,
      $border-main-color: $aco-red,
      $border-secondary-color: transparent,
      $border-size: 20%
    );

    &::after {
      background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' fill='#{to-rgb($aco-red)}' viewBox='-140 0 448 512'><path d='M64 352c17.69 0 32-14.32 32-31.1V64.01c0-17.67-14.31-32.01-32-32.01S32 46.34 32 64.01v255.1C32 337.7 46.31 352 64 352zM64 400c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1s40-17.9 40-39.1S86.09 400 64 400z'/></svg> ");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 47%;
    }
  }
}

.alerts.aco-blue .alert.is-info .alert-icon::after {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' fill='#{to-rgb($aco-light-blue)}' viewBox='-140 0 448 512'><path d='M160 448h-32V224c0-17.69-14.33-32-32-32L32 192c-17.67 0-32 14.31-32 32s14.33 31.1 32 31.1h32v192H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32S177.7 448 160 448zM96 128c26.51 0 48-21.49 48-48S122.5 32.01 96 32.01s-48 21.49-48 48S69.49 128 96 128z'/></svg>");
}

.alerts.aco-blue .alert,
.alert.aco-blue {
  color: white;
}

@media screen and (max-width: map.get($grid-breakpoints, "sm")) {
  .alerts {
    flex-direction: column;
    padding: 0 map.get($spacers, "spacing-16");

    & .alert + .alert {
      border-left: initial;
      border-top: 1px solid $aco-blue-disabled;
    }
  }
}

.alerts-wrapper {
  position: relative;
  z-index: 1;

  & .alert:not(:last-child) {
    border-radius: 0;
  }
}
