/* Form select fields */

@use "sass:map";

/**
Variables
*/
$menu-z-index: 75;
$input-z-index: $menu-z-index - 1;
$input-z-index-active: $menu-z-index + 1;
$bg-color: $aco-white;
$bg-color-button: $aco-gray-06;
$bg-color-hover-button: $aco-gray-05;
$select-font-size: $font-size-base;
$select-font-size-small: $font-size-sm;
$label-font-size-small: $select-font-size-small;
$label-font-size: $select-font-size;
$label-font-size-active: $font-size-xs;
$label-font-weight-active: $font-weight-normal;
$select-base-font-weight: $font-weight-medium;
$select-font-weight: $select-base-font-weight;
$select-font-weight-button: $font-weight-bold;
$select-label-font-weight-button: $font-weight-bold;
$select-text-color: $aco-dark-blue;
$select-text-color-dark: $aco-white;
$select-text-color-disabled: $aco-dark-blue-disabled;
$select-text-color-disabled-dark: $aco-gray-01;
$select-text-color-readonly: $aco-blue-disabled;
$select-text-color-readonly-dark: $aco-gray-01;
$select-label-color: $aco-blue;
$select-label-color-button: $aco-dark-blue;
$select-label-color-dark: $aco-light-blue;
$select-label-color-disabled: $aco-dark-blue-disabled;
$select-label-color-disabled-button: $aco-dark-blue-disabled;
$select-label-color-readonly: $aco-dark-blue-disabled;
$select-label-color-disabled-dark: $aco-gray-01;
$select-label-color-readonly-dark: $aco-gray-01;
$select-items-padding: map.get($spacers, "spacing-16");
$select-items-padding-top-button: calc($select-font-size + 1rem);
$select-items-padding-bottom-button: 1rem;
$select-padding-left: 0;
$select-padding-underline: 0.7rem;
$select-padding-underline-button: 0;
$select-padding-left-button: 1rem;
$select-padding-left-active: map.get($spacers, "spacing-20");
$select-padding-left-active-button: $select-padding-left-button;
$select-padding-right: $select-padding-left + map.get($spacers, "spacing-16");
$select-padding-right-active: $select-padding-left-active + map.get($spacers, "spacing-16");
$select-padding-right-button: $select-padding-left-button + map.get($spacers, "spacing-16");
$select-padding-right-active-button: $select-padding-right-button;
$select-padding-left-button: 1rem;
$select-padding-left-button-with-icon: calc($select-padding-left-button + 1.5rem);
$option-font-weight: $select-base-font-weight;
$option-text-color: $aco-dark-blue;
$option-hover-color: $aco-gray-06;
$invalid-decoration-color: $aco-red;
$invalid-padding-right: calc($select-padding-right + 3rem);
$invalid-padding-right-active: calc($select-padding-right-active + 3rem);
$underline-color-dark: $aco-gray-04;
$underline-color-dark-hover: $aco-white;
$underline-color: $aco-gray-04;
$underline-height: 0.0625rem;
$underline-height-active: 0.125rem;
$scrollbar-container-width: 18px;
$scrollbar-container-width-small: 10px;
$border-color: $aco-gray-05;
$focus-decoration-color: $aco-light-blue;
$decoration-border-width: 2px;
$select-line-height: 1.2;

@mixin dropdown-icon($color) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{to-rgb($color)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  pointer-events: none;
  transition: transform 0.2s ease-in-out;
}

@mixin start-icon($color) {
  background-image: url("data:image/svg+xml,<svg class='svg-inline--fa fa-arrow-up-arrow-down select-icon' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='arrow-up-arrow-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' data-fa-i2svg=''><path fill='#{to-rgb($color)}' d='M18.38 183.3C31.97 195.8 52.17 193.8 63.6 181.3L96 145.9v302C96 465.7 110.3 480 128 480s32-14.33 32-32.03V145.9L192.4 181.3c11.95 13.01 32.2 13.92 45.22 1.973c13.03-11.95 13.9-32.22 1.969-45.27L151.6 41.95c-12.12-13.26-35.06-13.26-47.19 0l-87.1 96.09C4.473 151.1 5.348 171.4 18.38 183.3zM210.4 328.7c-13.03 11.95-13.9 32.22-1.969 45.27l87.1 96.09c12.12 13.26 35.06 13.26 47.19 0l87.1-96.09c11.94-13.05 11.06-33.31-1.969-45.27c-13.59-12.46-33.8-10.48-45.22 1.969L352 366.1V64.03C352 46.33 337.7 32 320 32s-32 14.33-32 32.03v302l-32.4-35.4C243.6 317.7 223.4 316.8 210.4 328.7z'></path></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin invalid-icon() {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($invalid-decoration-color)}' d='M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.was-validated .select-selected.form-control:valid {
  border: 0;
  border-radius: 0;
  background-position: right 50px center;
}

.floating-label,
%floating-label {
  left: 0;
  position: absolute;
  font-size: $font-size-base;
  user-select: none;
  font-weight: $font-weight-medium;
  color: $select-label-color;
  border-left: $decoration-border-width solid transparent;
  pointer-events: none;

  @at-root :not(.select-button) .floating-label {
    padding-left: $select-padding-left;

    &.active {
      transform: translateY(-1.2rem);
      transition-property: all;
      transition-duration: 0.2s;
      font-size: $label-font-size-active;
      font-weight: $label-font-weight-active;
      color: $select-label-color;
    }
  }

  @at-root .select-button {
    & .floating-label {
      top: 50%;
      padding-left: $select-padding-left-button;
      transform: translateY(-50%);
      height: $select-font-size;
      z-index: $input-z-index;

      &.active {
        transform: translateY(-($select-font-size + $select-padding-underline-button));
        transition-property: all;
        transition-duration: 0.2s;
        font-size: $label-font-size-active;
        font-weight: $label-font-weight-active;
        color: $select-label-color;
      }
    }

    & .select-arrow-active ~ .floating-label {
      z-index: $input-z-index-active;
    }

    &.select-small .floating-label {
      font-size: $label-font-size-small;

      &.active {
        font-size: $label-font-size-active;
        font-weight: $label-font-weight-active;
        color: $select-label-color;
      }
    }

    &.select-large {
      &.select-icon {
        & .floating-label {
          padding-left: $select-padding-left-button-with-icon;

          &.active {
            padding-left: $select-padding-left-button-with-icon;
            font-weight: $label-font-weight-active;
            color: $select-label-color;
          }
        }
      }

      &:not(.select-icon) {
        & .floating-label {
          padding-left: $select-padding-left-button;

          &.active {
            padding-left: $select-padding-left-button;
            font-weight: $label-font-weight-active;
            color: $select-label-color;
          }
        }
      }
    }
  }
}

.form-label.floating-label {
  margin: 0;
  line-height: $select-line-height;
}

.select-floating-label {
  max-width: $input-max-width;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: fit-content;
  width: 100%;
  cursor: pointer;
  user-select: none;

  @at-root .dark {
    & .select-floating-label,
    &.select-floating-label {
      &.select-button {
        & .select-selected {
          background: $aco-white;
        }
      }

      &.is-invalid {
        .select-selected {
          border-radius: 0;
          border: 0;
          border-bottom: 2px solid #ea3400;
        }

        .dropdown-icon {
          top: 34%;
        }

        .floating-label {
          bottom: auto;
          top: 18px;
        }
      }

      &:not(.select-button) {
        & .select-selected {
          background-color: transparent;
          color: $select-text-color-dark;
          border-bottom: 1px solid $aco-gray-04;

          &.select-arrow-active {
            background: $bg-color;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
          }

          &.is-invalid {
            background-position: right 57px bottom 7px;
            border-bottom: 2px solid $aco-red;
          }

          & ~ .underline-bar {
            background-color: $underline-color-dark;
          }

          &:not(.select-arrow-active) ~ .dropdown-icon {
            @include dropdown-icon($select-label-color-dark);
          }

          &:not(.select-arrow-active) ~ label {
            color: $select-label-color-dark;
          }

          &:read-only,
          &.read-only {
            & ~ .underline-bar {
              background-color: $select-text-color-readonly-dark;
            }

            & ~ .dropdown-icon {
              @include dropdown-icon($select-text-color-readonly-dark);
            }
          }

          &:disabled,
          &.disabled {
            color: $select-text-color-disabled-dark;

            & ~ .underline-bar {
              background-color: $select-label-color-disabled-dark;
            }

            & ~ .dropdown-icon {
              @include dropdown-icon($select-label-color-disabled-dark);
            }

            & ~ label {
              color: $select-label-color-disabled-dark;
            }
          }
        }

        &:hover {
          & .select-selected:not(.select-arrow-active) {
            &:not(:focus-within):not(:invalid):not(:disabled):not(.disabled):not(:read-only):not(.read-only) {
              & ~ .underline-bar {
                background-color: $underline-color-dark-hover;
              }

              & ~ .dropdown-icon {
                @include dropdown-icon($select-label-color-dark);
              }
            }
          }
        }
      }
    }
  }

  & .select-selected {
    display: flex;
    align-items: center;
    background-color: $bg-color;
    height: 100%;
    min-width: 100%;
    min-height: calc(3 * $select-font-size);
    color: $select-text-color;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-weight: $select-font-weight;
    font-size: $select-font-size;
    outline: none;
    position: relative;
    cursor: pointer;
    padding: map.get($spacers, "spacing-16") 2.2rem 0 $select-padding-left;
    line-height: $select-line-height;
    caret-color: transparent;
    user-select: none;
    border-color: transparent;
    border-bottom: 0;

    &::selection {
      text-decoration: none;
    }

    & label {
      text-decoration: none;
      cursor: pointer;
    }

    & ~ .dropdown-icon {
      position: absolute;
      content: "";
      top: 50%;
      right: $select-padding-right;
      transform: translateY(-(calc($select-padding-underline / 2)));
      width: map.get($spacers, "spacing-24");
      height: map.get($spacers, "spacing-16");

      @include dropdown-icon($select-label-color);
    }

    & ~ .underline-bar {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: $underline-height;
      width: 100%;
      background-color: $underline-color;
    }

    &:read-only,
    &.read-only {
      & ~ .dropdown-icon {
        @include dropdown-icon($select-label-color-readonly);
      }

      & ~ .underline-bar {
        background-color: $border-color;
      }
    }

    &:disabled,
    &.disabled {
      color: $select-text-color-disabled;

      & ~ .dropdown-icon {
        @include dropdown-icon($select-label-color-disabled-dark);
      }

      & ~ label {
        color: $select-label-color-disabled-dark;
      }

      & ~ .underline-bar {
        background-color: $border-color;
      }
    }

    &.select-arrow-active {
      background: $bg-color;
      border-style: solid;
      border-width: $border-width $border-width 0 $border-width;
      border-color: $border-color;
      color: transparent;

      & ~ .underline-bar {
        background-color: $underline-color;
        width: calc(100% - ($select-padding-left-active * 2));
      }
    }

    &.select-arrow-active ~ .dropdown-icon {
      transform: rotate(-180deg) translateY(6px);
    }

    &.select-arrow-active ~ label {
      padding-left: $select-padding-left-active;
      top: 20px;
    }
  }

  & .select-items {
    position: absolute;
    background-color: $bg-color;
    top: 100%;
    left: 0;
    right: 0;
    z-index: $menu-z-index;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-style: solid;
    border-width: 0 $border-width $border-width $border-width;
    border-color: $border-color;
    overflow: hidden;
    font-weight: $option-font-weight;
    font-size: $select-font-size;
    padding-top: $select-items-padding;
    padding-bottom: $select-items-padding;

    & .select-items-internal {
      max-height: 20rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: $scrollbar-container-width;
      }

      &::-webkit-scrollbar-thumb {
        @include use-scrollbar-size($scrollbar-container-width);

        background: $aco-dark-blue;
      }

      &::-webkit-scrollbar-track {
        @include use-scrollbar-size($scrollbar-container-width);

        margin-top: 1rem;
        margin-bottom: 1rem;
        box-shadow: inset 0 0 5px $aco-gray-05;
      }

      & div {
        cursor: pointer;
        user-select: none;
        padding: 0.6rem 2.2rem 0.6rem $select-padding-left-active;
        width: 100%;
        color: $option-text-color;
        outline: none;
        text-align: left;

        &:last-child {
          border-bottom-width: $border-width;
        }

        &:hover {
          background-color: $option-hover-color;
        }

        &:focus:not(.disabled) {
          border: 1px solid $focus-decoration-color;
        }

        &.disabled {
          color: $select-text-color-disabled;
          pointer-events: none;
        }
      }
    }
  }

  &.select-button.select-large {
    max-width: 546px;

    & .select-icon {
      @include start-icon($select-label-color-button);

      position: absolute;
      left: 0;
      top: 50%;
      width: $select-font-size;
      height: $select-font-size;
      transform: translateY(-50%);
      z-index: $input-z-index;
      margin-left: $select-padding-left-button;
    }

    & .select-arrow-active ~ .select-icon {
      z-index: $input-z-index-active;
    }
  }

  &.select-button .select-selected {
    background-color: $bg-color-button;
    border-radius: $border-radius-lg;
    padding-left: $select-padding-left-button;
    z-index: $input-z-index;
    border-style: solid;
    border-width: $decoration-border-width;
    border-color: transparent;
    padding-bottom: $select-padding-underline-button;
    font-weight: $select-font-weight-button;

    & ~ .dropdown-icon {
      @include dropdown-icon($select-label-color-button);

      right: $select-padding-right-button;
      transform: translateY(-50%);
      z-index: $input-z-index;
    }

    & ~ label {
      color: $select-label-color-button;
      font-weight: $select-label-font-weight-button;
    }

    &.select-arrow-active {
      background-color: $bg-color-button;
      padding-left: $select-padding-left-active-button;
      z-index: $input-z-index-active;

      & ~ .dropdown-icon {
        right: $select-padding-right-active-button;
        transform: rotate(180deg) translateY(50%);
        z-index: $input-z-index-active;
      }
    }

    & ~ .underline-bar {
      background-color: transparent;
    }

    &:disabled,
    &.disabled {
      & ~ .select-icon {
        @include start-icon($select-label-color-disabled-button);
      }

      & ~ .dropdown-icon {
        @include dropdown-icon($select-label-color-disabled-button);
      }

      & ~ label {
        color: $select-label-color-disabled-button;
      }
    }

    &:read-only,
    &.read-only {
      & ~ .select-icon {
        @include start-icon($select-label-color-disabled-button);
      }

      & ~ .dropdown-icon {
        @include dropdown-icon($select-label-color-disabled-button);
      }
    }
  }

  &.select-button {
    &.select-small {
      height: 43px;
      max-width: 190px;

      & .select-items .select-items-internal {
        &::-webkit-scrollbar {
          width: $scrollbar-container-width-small;
        }

        &::-webkit-scrollbar-thumb {
          @include use-scrollbar-size($scrollbar-container-width-small);

          background: $aco-dark-blue;
        }

        &::-webkit-scrollbar-track {
          @include use-scrollbar-size($scrollbar-container-width-small);

          margin-top: 1rem;
          margin-bottom: 1rem;
          box-shadow: inset 0 0 5px $aco-gray-05;
        }
      }
    }

    &.select-small .select-selected {
      font-size: $select-font-size-small;
      font-weight: $select-font-weight;

      & ~ label {
        font-weight: $select-font-weight;
      }
    }

    & .select-items {
      top: 50%;
      padding-top: $select-items-padding-top-button;
      padding-bottom: $select-items-padding-bottom-button;
    }

    &.select-small .select-items {
      font-size: $select-font-size-small;
    }
  }

  &.select-button.select-large.select-icon .select-selected {
    padding-left: $select-padding-left-button-with-icon;
  }

  &.select-button.select-large:not(.select-icon) .select-selected {
    padding-left: $select-padding-left-button;
  }

  &.invalid .select-selected:invalid:not(:disabled):not(.disabled):not(:read-only):not(.read-only) {
    & ~ .underline-bar {
      background-color: $invalid-decoration-color;
      height: $underline-height-active;
    }
  }

  &:hover .select-selected:not(.select-arrow-active):not(:disabled):not(.disabled):not(:read-only):not(.read-only) {
    &:not(:focus-within):not(:invalid) ~ .underline-bar {
      background-color: $aco-dark-blue-hover;
    }

    &:not(:focus-within):not(:invalid) ~ .dropdown-icon {
      @include dropdown-icon($aco-dark-blue-hover);
    }
  }

  &:not(.invalid):focus-within .select-selected.select-selected:not(.select-arrow-active):not(:invalid):not(:disabled):not(.disabled):not(:read-only):not(.read-only) {
    & ~ .underline-bar {
      background-color: $focus-decoration-color;
      height: $underline-height-active;
    }
  }

  &.select-button.invalid {
    & .select-selected:invalid:not(:disabled):not(.disabled):not(:read-only):not(.read-only) {
      border-style: solid;
      border-color: $invalid-decoration-color;
      border-width: $decoration-border-width;
    }

    &::after {
      display: none;
    }
  }

  &.select-button .select-selected:hover:not(:invalid):not(:disabled):not(.disabled):not(:read-only):not(.read-only) {
    background-color: $bg-color-hover-button;
  }

  &.select-button .select-selected:focus:not(:invalid):not(:disabled):not(.disabled):not(:read-only):not(.read-only) {
    border-style: solid;
    border-width: $decoration-border-width;
    border-color: $focus-decoration-color;
  }

  &.invalid::after {
    @include invalid-icon;

    position: absolute;
    content: "";
    top: 50%;
    right: $invalid-padding-right;
    transform: translateY(-($select-padding-underline));
    width: 1.5rem;
    height: 1.5rem;
  }

  & .select-hide {
    display: none;
  }

  & .same-as-selected {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 1rem;
      height: 1rem;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      right: 2.4rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.select-floating-label select {
  display: none;
}
