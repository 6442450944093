/* Site */

// Note: This template represent the page structure. This step is just a canvas for our organisms.
// The template will be the model of our page, so the page is an instance of a template.

@use "sass:map";

.page-padding-bottom {
  padding-bottom: map.get($spacers, "spacing-64") !important;

  @include media-breakpoint-up(md) {
    padding-bottom: map.get($spacers, "spacing-80") !important;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: map.get($spacers, "spacing-96") !important;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: map.get($spacers, "spacing-112") !important;
  }

  @include media-breakpoint-up(xxl) {
    padding-bottom: map.get($spacers, "spacing-128") !important;
  }
}
