@use "sass:map";

@mixin section-rounded-top($bgColor: $aco-white, $paddingTop: 0, $borderRadius: map.get($radius-map, 12), $paddingBottom: 0) {
  $padding-bottom-result: $borderRadius;

  @if $paddingBottom != 0 {
    $padding-bottom-result: calc($borderRadius + $paddingBottom);
  }

  position: relative;
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
  margin-top: - $borderRadius;
  padding-top: $paddingTop;
  padding-bottom: $padding-bottom-result;
  background-color: $bgColor;
}
