@use "sass:map";

[data-selector="prescription_intro_wrapper"] {
  max-width: 736px;
}

[data-selector="prescription_intro_btn"] {
  position: relative;
  cursor: pointer;
  border: 2px solid white !important;
  width: 100%;

  label {
    cursor: pointer;
  }

  use,
  svg {
    color: $aco-blue;
  }

  &:hover {
    .h5 {
      color: $aco-dark-blue !important;
    }

    use,
    svg {
      color: $aco-dark-blue !important;
    }
  }

  &:focus {
    border: 2px solid $aco-light-blue !important;
  }
}

#qr-upload-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  cursor: pointer;
}

.modal-dialog {
  .btn-close {
    background-size: 12px;
  }

  .modal-white {
    .modal-title {
      font-size: 28px;
      line-height: 42px;
      max-width: 360px;
      font-weight: 400;
    }

    .modal-body {
      p {
        font-size: 16px;
      }

      a {
        font-size: 16px;
      }
    }
  }
}

#egk-howto-modal {
  .modal-dialog {
    max-width: 500px;
  }
}

#erx-workflow {
  display: none;

  .modal-dialog {
    max-width: 500px;
    margin: 1rem auto;
    width: 100%;

    .modal-body {
      font-size: $font-size-xs;
      font-weight: 400;

      h3 {
        font-size: $font-size-xl;
        line-height: 36px;
        font-weight: 600;
      }
    }

    .modal-header {
      padding: 1rem 1.5rem 0;
    }

    [data-selector="additional_payment_sum"] {
      font-size: $font-size-base;
    }
  }

  &.small {
    .modal-dialog {
      max-width: 300px;
    }
  }

  .check-icon-blue {
    @include circle-gradient($center-bg-color: $aco-blue, $size: 118px, $border-main-color: $aco-light-blue, $border-secondary-color: transparent, $border-size: 20%);

    &::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{to-rgb($aco-light-blue)}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 47%;
    }
  }

  .error-icon-red {
    @include circle-gradient($center-bg-color: $aco-blue, $size: 128px, $border-main-color: $aco-red, $border-secondary-color: transparent, $border-size: 20%);

    &::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{to-rgb($aco-red)}' d='M64 352c17.69 0 32-14.32 32-31.1V64.01c0-17.67-14.31-32.01-32-32.01S32 46.34 32 64.01v255.1C32 337.7 46.31 352 64 352zM64 400c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1s40-17.9 40-39.1S86.09 400 64 400z'/></svg>");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 64%;
      background-position-x: 42px;
      background-color: $aco-white;
    }
  }

  #reader {
    overflow: hidden;
    height: 360px;
    width: 360px;
  }

  [data-selector="headline_scan_result"] {
    font-size: $font-size-base;
  }

  [data-selector="erx_scan_product_card"] {
    background-color: $aco-white;
    overflow: hidden;
    margin: auto;

    img {
      height: 75px;
      width: 75px;
      object-fit: contain;
      border-radius: 10px;
      border: 1px solid $aco-gray-06;
    }
  }

  [data-selector="error_modal"] {
    max-width: 300px;
    margin: auto;
  }
}

.qrcode-sized {
  max-height: 136px;
}

#loading-indicator {
  width: 300px;
  top: 40vh;
  background-color: $white;
  left: 0;
  right: 0;
  z-index: 10000;
  margin: auto;
  border-radius: 24px;
  min-height: 148px;
  height: auto;

  .modal-content {
    background-color: $aco-white;
  }
}
