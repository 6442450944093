@use "sass:map";

$account-bonus-points-main-content-color: $aco-dark-blue;
$account-bonus-points-header-border-radius: 12px;
$account-bonus-points-header-min-height: 232px;
$account-bonus-points-header-logo-width: 117px;
$account-bonus-points-header-logo-height: 28px;
$account-bonus-points-header-z-index: 5;
$account-bonus-points-header-color: $aco-white;
$account-bonus-points-header-background-color: $aco-dark-blue;
$account-bonus-points-card-header-z-index: $account-bonus-points-header-z-index;
$account-bonus-points-main-content-z-index: $account-bonus-points-header-z-index - 1;
$account-bonus-points-info-z-index: $account-bonus-points-header-z-index - 1;
$account-bonus-points-info-background-color: $aco-gray-06;
$account-bonus-points-status-z-index: $account-bonus-points-info-z-index - 1;
$account-bonus-points-status-background-color: $aco-white;
$account-bonus-points-history-z-index: $account-bonus-points-status-z-index - 1;
$account-bonus-points-history-background-color: $aco-blue;
$account-bonus-points-history-color: $aco-white;
$account-bonus-points-header-section-bottom-padding-y: 32px;
$account-bonus-points-header-section-bottom-padding-x: 16px;
$account-bonus-points-content-border-radius: $account-bonus-points-header-border-radius;
$account-bonus-points-content-padding-y: $account-bonus-points-header-section-bottom-padding-y;
$account-bonus-points-content-padding-x: $account-bonus-points-header-section-bottom-padding-x;
$account-bonus-points-status-border-radius: $account-bonus-points-header-border-radius;
$account-bonus-points-card-min-height: 245px;
$account-bonus-points-card-max-width: map.get($grid-breakpoints, "sm");
$account-bonus-points-card-background-color: $aco-white;
$account-bonus-points-card-background-color-active: $aco-dark-blue;
$account-bonus-points-card-padding-x: 24px;
$account-bonus-points-card-padding-y: 40px;
$account-bonus-points-card-color: $aco-dark-blue;
$account-bonus-points-card-color-active: $aco-white;
$account-bonus-points-card-border-radius: 1rem;
$account-bonus-points-card-border-width: 2px;
$account-bonus-points-card-border-color: $aco-gray-06;
$account-bonus-points-card-border-color-active: $account-bonus-points-card-background-color-active;
$account-bonus-points-card-header-width: 162px;
$account-bonus-points-card-header-height: 50px;
$account-bonus-points-card-header-padding-y: 8px;
$account-bonus-points-card-header-padding-x: 24px;
$account-bonus-points-card-header-border-radius: 1.5625rem;
$account-bonus-points-card-header-background-color: $aco-blue;
$account-bonus-points-card-header-background-color-active: $aco-yellow;
$account-bonus-points-card-header-apopunkte-size: 138px;
$account-bonus-points-card-union-line-height: 28px;
$account-bonus-points-card-union-line-width: 6px;
$account-bonus-points-card-union-circle-size: 24px;
$account-bonus-points-card-union-circle-icon-active: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($account-bonus-points-card-color-active)}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
$account-bonus-points-card-margin-y: $account-bonus-points-card-union-line-height + $account-bonus-points-card-union-circle-size - ((calc($account-bonus-points-card-border-width / 2)) * 3);
$account-bonus-points-apopunkte-size: 128px;

.account-bonus-points-header {
  position: relative;
  z-index: $account-bonus-points-header-z-index;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $account-bonus-points-header-min-height;
  color: $account-bonus-points-header-color;
  background-color: $account-bonus-points-header-background-color;
  border-bottom-left-radius: $account-bonus-points-header-border-radius;
  border-bottom-right-radius: $account-bonus-points-header-border-radius;

  & .apo-basis-light {
    width: $account-bonus-points-header-logo-width;
    height: $account-bonus-points-header-logo-height;
  }

  & .apoPunkte {
    @include circle-gradient($size: $account-bonus-points-apopunkte-size);

    & .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .points {
        font-size: $h4-font-size;
        font-weight: $font-weight-bold;
      }

      & .label {
        font-size: $font-size-xs;
        font-weight: $font-weight-bold;
      }
    }
  }
}

.account-bonus-points-info {
  position: relative;
  z-index: $account-bonus-points-info-z-index;
  padding: $account-bonus-points-header-border-radius $account-bonus-points-header-section-bottom-padding-x $account-bonus-points-header-section-bottom-padding-y $account-bonus-points-header-section-bottom-padding-x;
  background-color: $account-bonus-points-info-background-color;
  border-bottom-left-radius: $account-bonus-points-header-border-radius;
  border-bottom-right-radius: $account-bonus-points-header-border-radius;

  @include media-breakpoint-down(sm) {
    padding: 32 $account-bonus-points-header-section-bottom-padding-x $account-bonus-points-header-section-bottom-padding-y $account-bonus-points-header-section-bottom-padding-x;
  }
}

.account-bonus-points-status {
  position: relative;
  z-index: $account-bonus-points-status-z-index;
  padding: 32px $account-bonus-points-content-padding-x $account-bonus-points-content-padding-y;
  background-color: $account-bonus-points-status-background-color;
  border-bottom-left-radius: $account-bonus-points-status-border-radius;
  border-bottom-right-radius: $account-bonus-points-status-border-radius;
}

.account-bonus-points-history {
  position: relative;
  z-index: $account-bonus-points-history-z-index;
  padding: calc($account-bonus-points-content-border-radius + $account-bonus-points-content-padding-y) $account-bonus-points-content-padding-x $account-bonus-points-content-padding-y;
  margin-top: -$account-bonus-points-status-border-radius;
  color: $account-bonus-points-history-color;
  background: $account-bonus-points-history-background-color;
}

.account-bonus-points-main-content {
  position: relative;
  z-index: $account-bonus-points-main-content-z-index;
  margin-top: -$account-bonus-points-header-border-radius;
  color: $account-bonus-points-main-content-color;
}

.account-bonus-points-card {
  position: relative;
  width: 100%;
  max-width: $account-bonus-points-card-max-width;
  min-height: $account-bonus-points-card-min-height;
  padding: $account-bonus-points-card-padding-y $account-bonus-points-card-padding-x;
  color: $account-bonus-points-card-color;
  background-color: $account-bonus-points-card-background-color;
  border-radius: $account-bonus-points-card-border-radius;
  border-style: solid;
  border-width: $account-bonus-points-card-border-width;
  border-color: $account-bonus-points-card-border-color;

  & ul {
    margin-bottom: 0;

    & li:not(:last-child) {
      padding-bottom: 1rem;
    }
  }

  & .card-header {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: $account-bonus-points-card-header-z-index;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $account-bonus-points-card-header-width;
    height: $account-bonus-points-card-header-height;
    transform: translate(-50%, -50%);
    padding: $account-bonus-points-card-header-padding-y $account-bonus-points-card-header-padding-x;
    background-color: $account-bonus-points-card-header-background-color;
    border-radius: $account-bonus-points-card-header-border-radius;
    border-style: solid;
    border-width: $account-bonus-points-card-border-width;
    border-color: $account-bonus-points-card-header-background-color;
  }

  &:not(:first-child) {
    margin-top: $account-bonus-points-card-margin-y;

    & .card-header {
      transform-style: preserve-3d;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(-100% - ($account-bonus-points-card-border-width / 2))) translateZ(-1px);
        width: $account-bonus-points-card-union-line-width;
        height: $account-bonus-points-card-union-line-height;
        background-color: $account-bonus-points-card-border-color;
        border-style: solid;
        border-left-width: $account-bonus-points-card-border-width;
        border-right-width: $account-bonus-points-card-border-width;
        border-color: $account-bonus-points-card-border-color;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: $account-bonus-points-card-margin-y;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: $account-bonus-points-card-union-line-width;
      height: $account-bonus-points-card-union-line-height;
      transform: translate(-50%, 100%);
      background-color: $account-bonus-points-card-border-color;
      border-style: solid;
      border-left-width: $account-bonus-points-card-border-width;
      border-right-width: $account-bonus-points-card-border-width;
      border-color: $account-bonus-points-card-border-color;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: $account-bonus-points-header-z-index + 1;
      transform: translate(-50%, calc(100% + $account-bonus-points-card-union-line-height - ($account-bonus-points-card-border-width / 2)));
      width: $account-bonus-points-card-union-circle-size;
      height: $account-bonus-points-card-union-circle-size;
      text-align: center;
      background-color: $account-bonus-points-card-background-color;
      border-style: solid;
      border-width: $account-bonus-points-card-border-width;
      border-color: $account-bonus-points-card-border-color;
      border-radius: 50%;
    }
  }

  &.active {
    color: $account-bonus-points-card-color-active;
    background-color: $account-bonus-points-card-background-color-active;
    border-color: $account-bonus-points-card-background-color-active;

    &::after {
      background-color: $account-bonus-points-card-background-color-active;
      border-color: $account-bonus-points-card-background-color-active;
    }

    &:not(:last-child) {
      &::after {
        background-image: $account-bonus-points-card-union-circle-icon-active;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60%;
      }
    }

    & .card-header {
      background-color: $account-bonus-points-card-header-background-color-active;
      border-color: $account-bonus-points-card-header-background-color-active;

      &::before {
        border-color: $account-bonus-points-card-border-color-active;
        background-color: $account-bonus-points-card-border-color-active;
      }
    }

    &::before {
      background-color: $account-bonus-points-card-background-color-active;
      border-color: $account-bonus-points-card-background-color-active;
    }
  }
}
