/* Advanced Search */
@use "sass:map";

/* Variables */
$search-result-products-list-grid-columns: 4;
$search-result-products-list-grid-columns-md: 3;
$search-result-products-list-grid-columns-sm: 1;

/* Custom Styles */

.search-result {
  &-no-product {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $aco-blue;
    padding-top: map.get($spacers, "spacing-24");
    padding-bottom: map.get($spacers, "spacing-112");

    @include media-breakpoint-down(md) {
      padding-top: map.get($spacers, "spacing-20");
      padding-bottom: map.get($spacers, "spacing-64");
    }

    &-container {
      color: $aco-white;
      text-align: center;
      font-size: 17px;
    }

    &-icon {
      @include circle-gradient($size: 160px, $border-main-color: $aco-red, $border-secondary-color: transparent, $center-bg-color: $aco-blue, $border-size: 20%);

      margin-bottom: map.get($spacers, "spacing-24");
    }

    &-icon-algolia {
      @include circle-gradient($size: 160px, $border-main-color: $aco-blue, $border-secondary-color: transparent, $center-bg-color: $aco-blue, $border-size: 20%);

      margin-bottom: map.get($spacers, "spacing-24");

      &::after {
        background: $aco-white;
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom-left-radius: map.get($spacers, "spacing-32");
    border-bottom-right-radius: map.get($spacers, "spacing-32");
    padding-top: map.get($spacers, "spacing-112");
    padding-bottom: map.get($spacers, "spacing-128");
    background-image: url("/releases/current/templates/apo_com_responsive/assets/img/backgrounds/category/search_result_header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    & .headline {
      color: $aco-white;
      font-size: 3.875rem;
      font-family: $font-family-headlines;
      max-width: 460px;
      padding-bottom: map.get($spacers, "spacing-16");

      @media screen and (max-width: map.get($grid-breakpoints, lg)) {
        font-size: $h1-font-size;
        max-width: 260px;
      }
    }
  }

  &-products-list {
    display: grid;
    grid-template-columns: repeat($search-result-products-list-grid-columns, minmax(0, 1fr));
    width: 100%;
    column-gap: map.get($spacers, "spacing-24");
    row-gap: map.get($spacers, "spacing-32");

    & .full-grid {
      grid-column: span $search-result-products-list-grid-columns;
    }

    & .product-card,
    & .product-card-sponsored {
      width: 100%;
      max-width: 100%;
    }

    & .popover {
      max-width: 400px;
    }
  }

  &-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: map.get($spacers, "spacing-40");

    &-title {
      color: $aco-dark-blue;
      line-height: 21px;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;

      &-container {
        min-height: 48px;
      }
    }

    &-mandatory {
      display: flex;
      align-items: center;
      gap: map.get($spacers, "spacing-8");
      font-size: $font-size-sm;

      &.light {
        color: $aco-light-blue;

        &:hover {
          text-decoration: underline;
          text-underline-position: under;
        }
      }
    }

    &-button {
      & a,
      & input,
      &.shadow-root::part(button) {
        font-size: $btn-font-size;
        line-height: $btn-line-height !important;
        width: 100%;
        min-height: $btn-min-height;
        height: $btn-min-height;
        padding: $btn-padding-y $btn-padding-x;
      }

      & .btn {
        max-width: none;
      }
    }
  }

  &-image-wrapper {
    width: 100%;
    height: fit-content;
    border: 2px solid $aco-gray-05;
    border-radius: 15px;
    padding: map.get($spacers, "spacing-32");
    margin-bottom: 10px;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  &-categories-description {
    h2 {
      color: $aco-dark-blue;
      font-size: 34px;
      line-height: 51px;
      letter-spacing: 0.36px;
      margin-bottom: 14px;
    }

    h3 {
      font-size: $font-size-base;
      line-height: 24px;
      color: $aco-dark-blue;
    }

    p {
      font-size: $font-size-base;
      line-height: 24px;
      color: $aco-dark-blue;

      &:last-child {
        margin-bottom: 0;
      }
    }

    li {
      color: $aco-dark-blue;
      padding: map.get($spacers, "spacing-16") 0;
    }
  }

  @include media-breakpoint-down(md) {
    &-categories-description {
      h2 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: map.get($spacers, "spacing-20");
      }

      li {
        color: $aco-dark-blue;
        padding: map.get($spacers, "spacing-16") 0;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .search-result {
    &-products-list {
      grid-template-columns: repeat($search-result-products-list-grid-columns-md, minmax(0, 1fr));
      place-content: center;
    }
  }
}

@include media-breakpoint-down(lg) {
  .search-result {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    &-products-list {
      grid-template-columns: repeat($search-result-products-list-grid-columns-md, minmax(0, 1fr));
      place-content: center;
    }

    &-card {
      display: flex;
      flex-direction: column;
      height: 100%;

      &-button {
        width: 100%;
      }
    }

    &-image-wrapper {
      width: 100%;
      height: 100%;
      padding: map.get($spacers, "spacing-24");

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .search-result {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    &-products-list {
      grid-template-columns: repeat($search-result-products-list-grid-columns-sm, minmax(0, 1fr));
      place-content: center;
      row-gap: map.get($spacers, "spacing-24");

      & .full-grid {
        grid-column: span $search-result-products-list-grid-columns-sm;
      }
    }

    &-card {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: 162px;

      &-button {
        width: 100%;
      }
    }

    &-image-wrapper {
      width: 162px;
      height: 162px;
      padding: map.get($spacers, "spacing-24");

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
