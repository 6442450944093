[data-selector="text_register_small"] {
  font-size: $input-btn-font-size-sm;
}

[data-selector="date_input"] {
  &.form-input-wrapper {
    &.has-value {
      .form-control {
        & ~ .form-label {
          font-weight: 400;
          font-size: $font-size-xs;
          top: 5%;
          transition: all 0.1s linear;
        }
      }
    }
  }
}

#login-register {
  .form-check-input.is-valid ~ .form-check-label,
  .was-validated .form-check-input:valid ~ .form-check-label {
    color: $aco-white;
  }

  .form-input-wrapper {
    &[data-selector="register_password"] {
      position: relative;

      [data-selector="password_hint"] {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &.has-value {
      .form-control {
        & ~ .form-label {
          font-weight: 400;
          font-size: $font-size-xs;
          top: 5%;
          transition: all 0.1s linear;
        }
      }
    }
  }

  .select-floating-label {
    max-width: 100%;

    .select-items {
      top: 40px;
    }
  }
}

[data-selector="toggle_visibility_password"] {
  top: 13px;
  right: 32px;
}

.fa-eye,
.fa-eye-slash {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 10px;
}

.fa-eye {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='#{to-rgb($aco-light-blue)}' d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z'/><path fill='#{to-rgb($aco-light-blue)}' d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z'/></svg> ");
}

.fa-eye-slash {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'> <path fill='#{to-rgb($aco-light-blue)}' d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z'/> <path fill='#{to-rgb($aco-light-blue)}' d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z'/> <path fill='#{to-rgb($aco-light-blue)}' d='M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z'/> </svg> ");
}

.login-form__tab-container {
  input[type="number"] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // For Firefox
    -moz-appearance: textfield;
  }
}

.login-form {
  [data-selector="address_packing_station_wrapper"] {
    margin-bottom: 50px;
  }
}
