/* set colored-links according to the aco-color-scheme */
@mixin set-theme-colors($color-map) {
  @each $color, $value in $color-map {
    // colorize spinners
    .spinner-border.text-#{$color} {
      background:
        conic-gradient(
          from 180deg at 50% 50%,
          rgba($aco-white, 1) 0deg,
          rgba($value, 0.5) 210deg,
          rgba($value, 1) 360deg
        );

      &::after {
        background-color: $value;
      }
    }
  }
}

/* set colored-links according to the aco-color-scheme */
