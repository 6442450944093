/* Buttons */

@use "sass:map";

/**
 Variables
 */
$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;
$input-btn-focus-width: 0;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: rgba(
  $component-active-bg,
  $input-btn-focus-color-opacity
);
$input-btn-focus-blur: 0;
$input-btn-focus-box-shadow: none;
$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-font-size-xs: $font-size-xs;
$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;
$input-btn-border-width: $border-width;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $font-size-sm;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null;
$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $font-size-base;
$btn-border-width: $input-btn-border-width;
$btn-box-shadow: none;
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 1;
$btn-active-box-shadow: none;
$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;
$btn-link-disabled-color: $gray-600;
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
$btn-hover-bg-shade-amount: 100%;
$btn-hover-bg-tint-amount: 100%;
$btn-hover-border-shade-amount: 100%;
$btn-hover-border-tint-amount: 100%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;
$btn-close-width: 24px;
$btn-close-height: $btn-close-width;
$btn-close-color: $black;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
$btn-close-focus-shadow: $input-btn-focus-box-shadow;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;
$btn-close-focus-opacity: 1;
$btn-close-disabled-opacity: 1;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
$btn-min-height: 33px;
$btn-max-width: 382px;
$btn-color: $aco-dark-blue;
$btn-font-weight: $font-weight-bold;
$btn-lg-font-size: $font-size-base;
$btn-lg-min-height: 48px;
$btn-lg-line-height: $line-height-base;
$btn-circle-size: 40px;
$btn-circle-lg-size: 48px;
$btn-circle-sm-size: 24px;
$btn-primary-color: $aco-dark-blue;
$btn-primary-background-color: $aco-light-blue;
$btn-primary-background-color-hover: $aco-light-blue-hover;
$btn-primary-outline-focus: 2px solid $aco-dark-blue;
$btn-primary-color-disabled: $aco-dark-blue-disabled;
$btn-primary-background-color-disabled: $aco-chatbot-blue;
$btn-secondary-color: $aco-dark-blue;
$btn-secondary-background-color: $aco-gray-06;
$btn-secondary-background-color-hover: $aco-gray-05;
$btn-secondary-outline-focus: 2px solid $aco-light-blue;
$btn-secondary-color-disabled: $aco-dark-blue-disabled;
$btn-secondary-background-color-disabled: rgba($aco-gray-06, 0.5);
$btn-secondary-color-dark: $aco-dark-blue;
$btn-secondary-background-color-dark: $aco-white;
$btn-secondary-background-color-hover-dark: $aco-gray-06;
$btn-secondary-background-color-disabled-dark: rgba($aco-white, 0.5);
$btn-ghost-color: $aco-dark-blue;
$btn-ghost-background-color: transparent;
$btn-ghost-background-color-hover: $aco-gray-06;
$btn-ghost-outline-focus: 2px solid $aco-light-blue;
$btn-ghost-color-disabled: rgba($aco-dark-blue, 0.5);
$btn-ghost-color-dark: $aco-white;
$btn-ghost-background-color-hover-dark: rgba($aco-white, 0.2);
$btn-ghost-outline-focus-dark: 2px solid $aco-light-blue;
$btn-ghost-color-disabled-dark: rgba($aco-white, 0.5);

/**
 Import BS default
 */
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/transitions";

/**
 Custom Styles
 */

@include set-theme-colors($theme-colors);

.btn,
button,
[role="button"] {
  &.inactive {
    opacity: 0;
    pointer-events: none;
  }
}

.btn,
%btn {
  border-radius: $btn-border-radius;
  min-height: $btn-min-height;
  max-width: $btn-max-width;
  font-weight: $btn-font-weight;
  color: $btn-color;
  border: none;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  & .btn-end-icon {
    margin-left: map.get($spacers, "spacing-8");
  }

  & .btn-start-icon {
    margin-right: map.get($spacers, "spacing-8");
  }

  &.btn-lg,
  %btn-lg {
    @media (max-width: map.get($grid-breakpoints, "sm")) {
      & {
        font-size: $btn-lg-font-size;
        line-height: $btn-lg-line-height;
      }
    }

    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: $btn-lg-min-height;
    border-radius: $btn-border-radius-lg;
  }

  &.btn-circle {
    height: $btn-circle-size;
    width: $btn-circle-size;
    border-radius: $btn-border-radius-lg;
    padding: 0;
    min-width: 0;
    min-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $input-btn-font-size;

    &.btn-lg {
      height: $btn-circle-lg-size;
      width: $btn-circle-lg-size;
      border-radius: $btn-border-radius-lg;
      font-size: $input-btn-font-size;
    }

    &.btn-sm {
      height: $btn-circle-sm-size;
      width: $btn-circle-sm-size;
      min-height: $btn-circle-sm-size;
      min-width: $btn-circle-sm-size;
      border-radius: $btn-border-radius-sm;
      font-size: $input-btn-font-size;
    }
  }
}

.btn.btn-primary,
%btn-primary {
  color: $btn-primary-color;
  background-color: $btn-primary-background-color;

  &.advertisement-obligatory-text-trigger {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border-top-right-radius: 1rem;
  }

  &:hover {
    background-color: $btn-primary-background-color-hover;
  }

  &:focus {
    outline: $btn-primary-outline-focus;
  }

  &.disabled,
  &:disabled {
    color: $btn-primary-color-disabled;
    background-color: $btn-primary-background-color-disabled;
  }
}

.btn.btn-primary-big {
  @extend %btn-primary;

  min-height: 48px;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 700;
}

.btn.btn-secondary,
%btn-secondary {
  color: $btn-secondary-color;
  background-color: $btn-secondary-background-color;

  &:hover {
    background-color: $btn-secondary-background-color-hover;
  }

  &:focus {
    outline: $btn-secondary-outline-focus;
  }

  &.disabled,
  &:disabled {
    color: $btn-secondary-color-disabled;
    background-color: $btn-secondary-background-color-disabled;
  }

  @at-root .dark {
    & .btn.btn-secondary:not(.no-dark) {
      background-color: $btn-secondary-background-color-dark;
      color: $btn-secondary-color-dark;

      &:hover:not(.not-dark) {
        background-color: $btn-secondary-background-color-hover-dark;
      }

      &.disabled:not(.not-dark),
      &:disabled:not(.not-dark) {
        background-color: $btn-secondary-background-color-disabled-dark;
      }
    }
  }
}

.btn.btn-ghost {
  color: $btn-ghost-color;
  background-color: $btn-ghost-background-color;

  &:hover {
    background-color: $btn-ghost-background-color-hover;
  }

  &:focus {
    outline: $btn-ghost-outline-focus;
  }

  &.disabled,
  &:disabled {
    color: $btn-ghost-color-disabled;
  }

  @at-root .dark {
    & .btn.btn-ghost:not(.not-dark) {
      color: $btn-ghost-color-dark;

      &:hover:not(.not-dark) {
        background-color: $btn-ghost-background-color-hover-dark;
      }

      &:focus:not(.not-dark) {
        outline: $btn-ghost-outline-focus-dark;
      }

      &.disabled:not(.not-dark),
      &:disabled:not(.not-dark) {
        color: $btn-ghost-color-disabled-dark;
      }
    }
  }
}

.btn-close-icon-light,
%btn-close-icon-light {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' class='svg-inline--fa fa-xmark' data-icon='xmark' data-prefix='fas' viewBox='0 0 320 512'%3E%3Cpath fill='#{to-rgb($aco-white)}' d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3l105.4 105.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256l105.3-105.4z'/%3E%3C/svg%3E");

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' class='svg-inline--fa fa-xmark' data-icon='xmark' data-prefix='fas' viewBox='0 0 320 512'%3E%3Cpath fill='#{to-rgb($aco-light-blue)}' d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3l105.4 105.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256l105.3-105.4z'/%3E%3C/svg%3E");
  }
}

.btn-close-icon-dark,
%btn-close-icon-dark {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' class='svg-inline--fa fa-xmark' data-icon='xmark' data-prefix='fas' viewBox='0 0 320 512'%3E%3Cpath fill='#{to-rgb($aco-dark-blue)}' d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3l105.4 105.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256l105.3-105.4z'/%3E%3C/svg%3E");

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' class='svg-inline--fa fa-xmark' data-icon='xmark' data-prefix='fas' viewBox='0 0 320 512'%3E%3Cpath fill='#{to-rgb($aco-blue)}' d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3l105.4 105.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256l105.3-105.4z'/%3E%3C/svg%3E");
  }
}

.btn-trash-icon-dark,
%btn-trash-icon-dark {
  background-image: url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-trash-can fa-sm' aria-hidden='true' focusable='false' data-prefix='far' data-icon='trash-can' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' data-fa-i2svg=''%3E%3Cpath fill='currentColor' d='M160 400C160 408.8 152.8 416 144 416C135.2 416 128 408.8 128 400V192C128 183.2 135.2 176 144 176C152.8 176 160 183.2 160 192V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V192C208 183.2 215.2 176 224 176C232.8 176 240 183.2 240 192V400zM320 400C320 408.8 312.8 416 304 416C295.2 416 288 408.8 288 400V192C288 183.2 295.2 176 304 176C312.8 176 320 183.2 320 192V400zM317.5 24.94L354.2 80H424C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94H317.5zM151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1C174.5 48 171.1 49.34 170.5 51.56L151.5 80zM80 432C80 449.7 94.33 464 112 464H336C353.7 464 368 449.7 368 432V128H80V432z'%3E%3C/path%3E%3C/svg%3E");
}

.btn-close {
  width: $btn-close-width;
  height: $btn-close-height;
  border-radius: 0;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-attachment: auto;
  background-position: center;
  background-size: 16px;
  padding: 0;

  &-desktop-menu {
    background-size: 10px;
    margin-top: -0.5rem;
  }

  &:focus-visible {
    border-color: $aco-light-blue;
  }

  &.btn-close-aco-white {
    @extend %btn-close-icon-dark;

    background-color: $aco-white;
  }

  &.btn-close-aco-blue {
    @extend %btn-close-icon-light;

    background-color: $aco-blue;
  }

  &.btn-close-aco-dark-blue {
    @extend %btn-close-icon-light;

    background-color: $aco-dark-blue;
  }

  &.btn-close-aco-gray-06 {
    @extend %btn-close-icon-dark;

    background-color: $aco-gray-06;
  }

  &.btn-trash-aco-gray-06 {
    @extend %btn-trash-icon-dark;

    background-color: $aco-gray-06;
  }
}

cart-button {
  width: auto;
}

cart-button::part(button) {
  font-family: $font-family-base;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  text-align: center;
  min-height: $btn-lg-min-height;
  color: $btn-primary-color;
  background-color: $btn-primary-background-color;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  position: relative;
  cursor: pointer;

  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);

  &:hover {
    background-color: $btn-primary-background-color-hover;
  }

  &:focus {
    outline: $btn-primary-outline-focus;
  }

  &:disabled {
    color: $btn-primary-color-disabled;
    background-color: $btn-primary-background-color-disabled;
  }

  @media (max-width: map.get($grid-breakpoints, "sm")) {
    & {
      font-size: $btn-lg-font-size;
      line-height: $btn-lg-line-height;
    }
  }
}

cart-button::part(button-slim) {
  font-family: $font-family-base;
  font-weight: $btn-font-weight;
  line-height: 1;
  text-align: center;
  color: $btn-primary-color;
  background-color: $btn-primary-background-color;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  width: 100%;
  max-width: 382px;
  border: none;
  position: relative;
  cursor: pointer;

  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);

  &:hover {
    background-color: $btn-primary-background-color-hover;
  }

  &:focus {
    outline: $btn-primary-outline-focus;
  }

  &:disabled {
    color: $btn-primary-color-disabled;
    background-color: $btn-primary-background-color-disabled;
  }

  @media (max-width: map.get($grid-breakpoints, "sm")) {
    & {
      font-size: $btn-lg-font-size;
      line-height: $btn-lg-line-height;
    }
  }
}

cart-button.secondary {
  &::part(button) {
    border-radius: $btn-border-radius;
    min-height: $btn-min-height;
    max-width: $btn-max-width;
    font-weight: $btn-font-weight;
    border: none;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $btn-secondary-color;
    background-color: $btn-secondary-background-color;
    line-height: normal;

    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);

    &:hover {
      background-color: $btn-secondary-background-color-hover;
    }

    &:focus {
      outline: $btn-secondary-outline-focus;
    }

    &.disabled,
    &:disabled {
      color: $btn-secondary-color-disabled;
      background-color: $btn-secondary-background-color-disabled;
    }

    @at-root .dark {
      & cart-button.secondary::part(button) {
        & .btn.btn-secondary:not(.not-dark),
        &.btn.btn-secondary:not(.not-dark) {
          background-color: $btn-secondary-background-color-dark;
          color: $btn-secondary-color-disabled;

          &:hover:not(.not-dark) {
            background-color: $btn-secondary-background-color-hover-dark;
          }

          &.disabled:not(.not-dark),
          &:disabled:not(.not-dark) {
            background-color: $btn-secondary-background-color-disabled-dark;
          }
        }
      }
    }
  }

  &[disabled]::part(button) {
    color: $btn-secondary-color-disabled;
    background-color: $btn-secondary-background-color-disabled;
    pointer-events: none;
    user-select: none;
  }

  @at-root .dark {
    cart-button.secondary[disabled]::part(button) {
      color: $btn-secondary-color-dark;
      background-color: $btn-secondary-background-color-disabled-dark;
      pointer-events: none;
      user-select: none;
    }
  }
}

wishlist-button {
  &.btn {
    &.btn-primary {
      color: $aco-white;

      .active {
        color: $btn-secondary-color;
      }
    }
  }
}
