/* Form-labels */

@use "sass:map";

/**
 Variables
 */
$form-label-margin-bottom: map.get($spacers, "spacing-8");
$form-label-font-size: null;
$form-label-font-style: null;
$form-label-font-weight: null;
$form-label-color: null;

// Form floating labels
$form-floating-height: add(3.5rem, $input-height-border);
$form-floating-line-height: $line-height-sm;
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: map.get($spacers, "spacing-16");
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;

/**
 BS default import
 */
@import "~bootstrap/scss/forms/floating-labels";

/**
 Custom Styles
 */
.filter-label-desktop {
  position: absolute;
  top: 0.25rem;
  font-size: 0.75rem;
  color: map.get($colors, "apo-blue");
}

.active-filters {
  position: absolute;
  top: 1.25rem;
}
