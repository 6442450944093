@use "sass:map";

$header-bg-color: $aco-blue;
$header-white-bg-color: $aco-white;

html {
  scroll-padding-top: 75px;
}

body {
  position: relative;
}

header {
  transform: translateY(0);
  transition: transform 200ms ease-out;

  &.offcanvas {
    transform: translateY(-100%);
  }
}

.page-header {
  position: sticky;
  top: 0;
  z-index: calc(#{$modal-z-index} - 1);

  // anti-CLS
  body:not([data-pagetype="register"]) > & {
    min-height: 118px;
  }

  body[data-is-appuser][data-pagetype="register"] & {
    display: none;
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
  margin: 0;
}

.header-usp {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.header-menu-mobile {
  left: 0;
  top: 100%;
  position: absolute;
  display: none;
  z-index: 99;
  height: calc(100svh - 68px);
  overflow-y: auto;

  &.active {
    display: flex;
  }

  .btn {
    max-width: none;
  }

  .mobile-recursive-menu {
    & .mob-link {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $aco-white;
      color: $header-bg-color;
      cursor: pointer;
      padding: 0 4px;

      &[data-selector="show-cat-products"] {
        &[data-level="1"] {
          color: $aco-white;
          background-color: $aco-dark-blue;
        }
      }

      .h6 {
        font-weight: bold;
      }

      &.main {
        background-color: $aco-white;
        color: $aco-blue;
        min-height: 40px;

        &:hover {
          color: $aco-dark-blue;
        }
      }
    }

    & .expandable-menu-wrapper {
      & .mob-link {
        & .mob-link-icon-left {
          display: none;
        }

        .mob-link-icon-right {
          height: 16px;
          width: 10px;
          display: flex;

          .fa-chevron-right {
            height: 100%;
          }
        }

        &.active {
          justify-content: start;
          padding: 0;

          & .mob-link-icon-left {
            display: inline;
            width: 10px;
          }

          & .mob-link-icon-right {
            display: none;
          }
        }

        &[data-level="2"][data-selector="show-cat-products"] {
          color: $aco-blue;
          background-color: $aco-white;
        }
      }

      & .mob-submenu {
        display: none;

        &.active {
          display: block;

          &.active-border {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &::before {
              margin: 16px 0;
              width: 100%;
              content: "";
              border: 1px solid $aco-gray-06;
              display: block;
            }
          }

          .expandable-menu-wrapper {
            display: flex;
            gap: 8px;
          }

          .mob-link {
            padding-left: 36px;
            height: 40px;
            width: 100%;
            padding-right: 4px;

            &:hover {
              color: $aco-dark-blue;
            }

            &.children.active {
              padding-left: 0;
            }
          }
        }
      }
    }

    & .mob-submenu .mobile-menu-list-item:last-child .mob-link::after {
      display: none;
    }

    & .mobile-menu-list-item:not(:last-child) {
      &[data-level="0"] {
        & .mob-link:not(.active)::after {
          background-color: $aco-gray-06;
        }
      }

      &[data-level="1"] {
        & .mob-link:not(.active)::after {
          background: $aco-gray-06;
        }

        & .mob-link:not(.active)[data-selector="show-cat-products"]::after {
          background: rgba(242 242 242 / 20%);
        }
      }

      &[data-level="2"] {
        & .mob-link:not(.active)::after {
          background: rgba(242 242 242 / 20%);
        }

        & .mob-link:not(.active)[data-selector="show-cat-products"]::after {
          background: $aco-gray-06;
        }
      }

      &[data-level="3"] {
        & .mob-link:not(.active)::after {
          background: $aco-gray-06;
        }
      }
    }
  }
}

.header {
  color: $aco-white;
  margin: 0;
  padding: 0;
  background-color: $header-bg-color;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  &.header-white {
    background-color: $header-white-bg-color;
  }

  & .header-top {
    & .header-logo {
      display: flex;
      align-items: center;

      & svg {
        @media only screen and (max-width: map.get($grid-breakpoints, "lg")) {
          max-width: 124px;
        }

        @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "lg")} + 1px)) {
          max-width: 165px;
        }
      }
    }

    & .header-icons > :not(:first-child) {
      margin-left: map.get($spacers, "spacing-16");

      @media screen and (max-width: map.get($grid-breakpoints, "sm")) {
        margin-left: map.get($spacers, "spacing-8");
      }
    }

    & .header-search {
      @media only screen and (max-width: map.get($grid-breakpoints, "lg")) {
        margin-top: map.get($spacers, "spacing-16");

        //  anti-CLS
        min-height: 40px;
      }
    }

    & .header-icons {
      display: flex;
      justify-content: end;

      @media only screen and (min-width: 992px) {
        order: 3 !important;
      }

      & .btn {
        height: 36px;
        width: 36px;

        svg {
          height: 24px;
          width: 24px;
        }

        &:hover {
          color: $aco-light-blue;
        }

        & .badge {
          position: absolute;
          bottom: 0;
          right: 0;
          top: auto;
        }
      }
    }
  }

  & .header-divider {
    height: 1px;
    width: 100%;
    background-color: $aco-gray-06;
  }

  & .header-bottom {
    & .header-navigation {
      & .header-navigation-link {
        white-space: nowrap;
        margin: 0 map.get($spacers, "spacing-16");
        font-size: $font-size-base;
        font-family: $font-family-base;
        font-weight: bold;

        svg {
          width: 10px;
          height: 16px;
        }

        &.desktop-submenu-link {
          font-size: $font-size-base;
          white-space: normal !important;
        }

        &.show {
          color: $aco-light-blue;
        }
      }

      & .header-navigation-link:first-child {
        white-space: nowrap;
        margin-left: 0;
      }

      & .header-navigation-link:last-child {
        white-space: nowrap;
        margin-right: 0;
      }

      & .header-navigation-link-wrapper {
        margin: 0 map.get($spacers, "spacing-24");

        & .header-navigation-link {
          margin: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      & .header-navigation-link-wrapper:first-child {
        margin-left: 0;
      }

      & .header-navigation-link-wrapper:last-child {
        margin-right: 0;
      }

      & .header-navigation-link-content {
        & .desktop-menu-backdrop {
          z-index: -1;
          display: none;
        }

        &.dropdown-menu.show {
          display: flex;
          position: absolute;
          width: 100%;
          inset: 0 !important;
          transform: none !important;
          top: 100% !important;
          left: 0 !important;
          height: fit-content;
          padding: 0 !important;
          background: transparent;
          border: none;
          border-radius: 0;

          & .desktop-menu-backdrop {
            display: block;
            background-color: rgba(0 0 0 / 40%);
            position: absolute;
            height: 100vh;
            width: 100%;
          }
        }

        & .header-navigation-link-content-container {
          background-color: $aco-white;
          border-radius: 0 0 32px 32px !important;
          border: 1px solid $aco-gray-06;
          border-top: none;
          min-height: 248px;
        }
      }

      & .desktop-submenu-wrapper {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        & .neutral-link {
          width: 100%;
        }

        [data-selector="navigation_arrow"] {
          pointer-events: none;
        }

        & .list-group-item {
          width: 100%;
          border-bottom: 1px solid $aco-gray-06 !important;
          padding: 0;
        }

        & .inner-submenu {
          display: none;
          height: 100%;
        }

        &.active {
          width: 100%;
          height: 100%;
          padding: 0;
          background-color: $aco-dark-blue;

          & .neutral-link {
            display: none;
          }

          & .inner-submenu-right {
            display: flex;
            padding: 32px;
            height: 100%;
            width: 100%;
          }

          & .inner-submenu-left {
            width: 25%;
            border-radius: 0 0 32px;
            justify-content: center;
            background-color: $aco-white;
            display: flex;
            align-items: start;
            overflow: hidden;

            & .desktop-submenu-link {
              text-align: center;
              font-size: $font-size-base;
            }
          }

          & .inner-submenu {
            display: flex;
            width: 100%;
            border-radius: 0 0 32px;
            justify-content: space-between;

            & .list-group {
              flex-flow: row wrap;
              width: 100%;

              & .list-group-item {
                width: auto;
                border-bottom: 1px solid rgba(242 242 242 / 20%) !important;
              }
            }

            & .inner-submenu-close {
              width: 24px;
              height: 24px;
              background-size: 10px;
            }
          }
        }

        &.inactive {
          width: 0;
          padding: 0;

          & .neutral-link {
            display: none;
          }
        }
      }
    }
  }
}

.mobile-menu-backdrop {
  background-color: rgba(0 0 0 / 40%);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 98;
}
