/* Typography */

@use "sass:map";

/**
 Variables
 */
$font-family-base: "Roboto", "Tahoma", "Verdana", "Arial", sans-serif;
$font-family-base-playfair: "Playfair Display", "Georgia", "Times New Roman", "Arial", sans-serif;
$font-family-headlines: $font-family-base-playfair;
$font-size-root: null;
$font-size-base: 1rem;
$font-size-xs: $font-size-base * 0.75;
$font-size-sm: $font-size-base * 0.875;
$font-size-md: $font-size-base * 0.9375;
$font-size-lg: $font-size-base * 1.25;
$font-size-xl: $font-size-base * 1.5;
$font-size-xxl: $font-size-base * 2.125;
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

// Headlines
$h1-font-size: $font-size-base * 2.125;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
);

// Paragraphs
$paragraph-margin-bottom: 1rem;
$body-text-align: null;

// Headings
$headings-margin-bottom: $spacer * 0.5;
$headings-font-family: null;
$headings-font-style: null;
$headings-font-weight: $font-weight-bold;
$headings-line-height: $line-height-base;
$headings-color: null;
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
);
$display-font-weight: 300;
$display-line-height: $headings-line-height;

// Other
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;
$small-font-size: 0.875em;
$sub-sup-font-size: 0.75em;
$text-muted: $gray-600;
$initialism-font-size: $small-font-size;
$blockquote-margin-y: $spacer;
$blockquote-font-size: $font-size-base * 1.25;
$blockquote-footer-color: $gray-600;
$blockquote-footer-font-size: $small-font-size;
$hr-margin-y: $spacer;
$hr-color: inherit;
$hr-height: $border-width;
$hr-opacity: 0.25;
$legend-margin-bottom: 0.5rem;
$legend-font-size: 1.5rem;
$legend-font-weight: null;
$mark-padding: 0.2em;
$dt-font-weight: $font-weight-bold;
$nested-kbd-font-weight: $font-weight-bold;
$list-inline-padding: 0.5rem;
$mark-bg: #fcf8e3;

/**
 BS default import
 */
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";

/**
  font-misin includes
*/

/* Playfair Display SemiBold */
@include font-face(
  "Playfair Display",
  "../fonts/PlayfairDisplay-SemiBold",
  500,
  normal
);

/* Roboto Regular */
@include font-face("Roboto", "../fonts/Roboto-Regular", 400, normal);

/* Roboto Bold */
@include font-face("Roboto", "../fonts/Roboto-Bold", 700, normal);

/* Roboto Italic */
@include font-face("Roboto", "../fonts/Roboto-Italic", 400, italic);

/* Roboto Bold Italic */
@include font-face("Roboto", "../fonts/Roboto-BoldItalic", 700, italic);

/**
 Custom Styles
 */
h1,
h2,
h3,
.h1,
.h2,
.h3,
%headline {
  font-family: $font-family-headlines;
  font-weight: 600;
  margin-bottom: 0;

  @-moz-document url-prefix("") {
    font-weight: normal;
  }
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-family: $font-family-base;
  font-weight: 500;

  @-moz-document url-prefix("") {
    font-weight: normal;
  }
}

.blockquote {
  font-style: italic;
  font-weight: $font-weight-bold;
  font-size: $font-size-lg;
}

.topline-heading {
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
}

.footnote {
  font-size: $font-size-sm;

  &-medium {
    font-weight: $font-weight-medium;
    font-size: $font-size-sm;
  }

  &-bold {
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
  }
}

.micro {
  font-size: $font-size-xs;

  &-medium {
    font-weight: $font-weight-medium;
    font-size: $font-size-xs;
  }

  &-bold {
    font-weight: $font-weight-bold;
    font-size: $font-size-xs;
  }
}

.caption {
  font-size: $font-size-sm;
  font-style: italic;
}

.fs-body {
  font-size: $font-size-base;
  font-weight: $font-weight-base;
}

@each $breakpointSize, $breakpointValue in $grid-breakpoints {
  @each $fontSize, $fontValue in $font-sizes {
    .h#{$fontSize}-#{$breakpointSize} {
      @media screen and (min-width: $breakpointValue) {
        font-size: $fontValue !important;
        line-height: $line-height-base !important;
      }
    }
  }
}

.fw-medium {
  font-weight: $font-weight-medium !important;
}

.fw-bold {
  font-weight: $font-weight-bold !important;
}

.hyphens-auto {
  hyphens: auto;
}

.headline-1 {
  font-size: 25px;
  line-height: 38px;
  font-weight: $font-weight-normal;
  font-family: $font-family-headlines;
}

.headline-2 {
  font-size: 23px;
  line-height: 34px;
  font-weight: $font-weight-normal;
  font-family: $font-family-headlines;
}

.headline-3 {
  font-size: 21px;
  line-height: 32px;
  font-weight: $font-weight-normal;
  font-family: $font-family-headlines;
}

.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
