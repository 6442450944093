/* Accordion */

@use "sass:map";

/**
 Variables
 */
$accordion-padding-y: map.get($spacers, "spacing-20");
$accordion-padding-x: map.get($spacers, "spacing-16");
$accordion-color: $aco-white;
$accordion-bg: $aco-blue;
$accordion-border-width: 0;
$accordion-border-color: transparent;
$accordion-border-radius: 0;
$accordion-inner-border-radius: 0;
$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;
$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: $aco-white;
$accordion-button-bg: $aco-blue;
$accordion-transition: $btn-transition, border-radius 0.15s ease;
$accordion-button-active-bg: $aco-blue;
$accordion-button-active-color: $aco-white;
$accordion-button-focus-border-color: $aco-light-blue;
$accordion-button-focus-box-shadow: none;
$accordion-icon-width: 0.95rem;
$accordion-icon-color: $aco-white;
$accordion-icon-active-color: $aco-white;
$accordion-icon-transition: transform 0.2s ease-in-out;
$accordion-icon-transform: rotate(-180deg);
$accordion-button-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{rgba($aco-white, 1)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');
$accordion-button-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{rgba($aco-white, 1)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');

/**
 BS default import
 */
@import "~bootstrap/scss/accordion";

.accordion .accordion-item {
  background-color: transparent;

  & .accordion-button[aria-expanded="false"]:not(:focus) {
    border-bottom: 1px solid $aco-gray-06;

    @at-root .dark & {
      border-bottom: 1px solid $aco-gray-06;
    }
  }
}

.accordion-button {
  font-weight: $font-weight-bold;

  &:hover {
    color: $aco-light-blue;

    &::after {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-light-blue)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');
    }
  }

  &:focus {
    border: 2px solid $aco-light-blue;
  }

  @at-root .dark {
    .accordion-button {
      background-color: $aco-white;
      color: $aco-blue;

      &::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-blue)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');
      }

      &:hover {
        color: $aco-dark-blue;

        &::after {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-dark-blue)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');
        }
      }

      &:focus {
        border: 2px solid $aco-light-blue;
      }
    }
  }
}

.accordion-body {
  color: $aco-white;

  @at-root .dark {
    .accordion-body {
      color: $aco-dark-blue;
      background-color: $aco-white;
    }
  }
}

.accordion-button.no-chevron::after,
.accordion-button.no-chevron:hover::after {
  background-image: none;
}
