@mixin use-back-icon($color) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($color)}' d='M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin use-link-icon($color) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($color)}' d='M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin list-group-item-variant-custom($state, $background, $color, $hoverColor: $color) {
  .list-group {
    & .list-group-item-#{$state} {
      color: $color;
      background: $background;

      &.list-group-item-action {
        background: $background;
        color: $color;

        &:hover {
          color: $hoverColor;
          background-color: $background;

          &:not(.list-group-item-back)::after {
            @include use-link-icon($hoverColor);
          }

          &.list-group-item-back {
            &::before {
              @include use-back-icon($hoverColor);
            }
          }
        }

        &:focus:not(:hover) {
          color: $color;
          background-color: $background;
        }

        &.active {
          color: $color;
          background: $background;
        }

        &.list-group-item-back {
          &::before {
            @include use-back-icon($color);
          }
        }

        &:not(.list-group-item-back)::after {
          @include use-link-icon($color);
        }
      }
    }
  }
}
