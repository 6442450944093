$circle-gradient-size: 162px;
$circle-gradient-rotation: -60deg;
$circle-gradient-border-radius: $border-radius-pill;
$circle-gradient-head-border-radius: $border-radius-pill;
$circle-gradient-border-size: 10%;
$circle-gradient-main-color: $aco-light-blue;
$circle-gradient-secondary-color: $aco-dark-blue;
$circle-gradient-z-index: 1;
$circle-gradient-internal-z-index: $circle-gradient-z-index + 1;
$circle-gradient-content-z-index: $circle-gradient-internal-z-index + 1;

@mixin circle-gradient($size: $circle-gradient-size, $border-size: $circle-gradient-border-size, $border-main-color: $circle-gradient-main-color, $border-secondary-color: $circle-gradient-secondary-color, $center-bg-color: $border-secondary-color) {
  $circle-gradient-center-size: calc(100% - $border-size);
  $circle-gradient-head-size: calc($border-size / 2);
  $circle-gradient-center-translate-ratio: percentage-to-ratio(calc($border-size / 2));
  $circle-gradient-center-size-ratio: percentage-to-ratio(100% - $border-size);
  $circle-gradient-center-translate-dividend: calc($circle-gradient-size * $circle-gradient-center-translate-ratio * 100);
  $circle-gradient-center-translate-divisor: calc($circle-gradient-size * $circle-gradient-center-size-ratio);
  $circle-gradient-center-translate: calc(($circle-gradient-center-translate-dividend / $circle-gradient-center-translate-divisor) * 1%);

  position: relative;
  z-index: $circle-gradient-z-index;
  transform: rotate($circle-gradient-rotation);
  height: $size;
  width: $size;
  min-height: $size;
  min-width: $size;
  max-height: $size;
  max-width: $size;
  background-image: conic-gradient(from 0.25turn, $border-main-color, $border-secondary-color);
  border-radius: $circle-gradient-border-radius;

  &::before {
    content: "";
    position: absolute;
    bottom: 50%;
    right: 0;
    transform: translateY(50%);
    width: $circle-gradient-head-size;
    height: $circle-gradient-head-size;
    min-width: $circle-gradient-head-size;
    min-height: $circle-gradient-head-size;
    max-width: $circle-gradient-head-size;
    max-height: $circle-gradient-head-size;
    background-color: $border-main-color;
    border-radius: $circle-gradient-head-border-radius;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: $circle-gradient-internal-z-index;
    transform: translate($circle-gradient-center-translate, $circle-gradient-center-translate) rotate(-$circle-gradient-rotation);
    width: $circle-gradient-center-size;
    height: $circle-gradient-center-size;
    min-width: $circle-gradient-center-size;
    min-height: $circle-gradient-center-size;
    max-width: $circle-gradient-center-size;
    max-height: $circle-gradient-center-size;
    border-radius: $circle-gradient-border-radius;
    background-color: $center-bg-color;
  }

  & .content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $circle-gradient-content-z-index;
    transform: translate(-50%, -50%) rotate(-$circle-gradient-rotation);
  }
}

.circle-gradient-modal {
  @include circle-gradient($border-main-color: $aco-light-blue, $border-secondary-color: transparent, $center-bg-color: $aco-blue, $border-size: 15%);

  .content [class*="svg-inline"] {
    font-size: 4.25rem;
    color: $aco-light-blue;
  }
}
