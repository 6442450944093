@use "sass:map";

.post-card {
  flex: 0 0 100%;
  display: flex;
  border-radius: 24px;

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "sm")} + 1px)) {
    min-width: 220px;
  }

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "md")} + 1px)) {
    min-width: 220px;
    flex: 0 0 calc((100% / 2) - 12px);
  }

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "lg")} + 1px)) {
    min-width: 220px;
    flex: 0 0 calc((100% / 3) - 16px);
  }

  &__wrapper {
    width: 100%;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid $aco-gray-06;

    &:nth-child(7) {
      .post-card__wrapper {
        border-bottom: none;
      }
    }
  }

  &:last-of-type {
    .post-card__wrapper {
      border-bottom: none;
    }
  }

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "md")} + 1px)) {
    &:nth-child(7) {
      .post-card__wrapper {
        border-bottom: 2px solid $aco-gray-06;
      }
    }
  }

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "lg")} + 1px)) {
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      .post-card__wrapper {
        border-bottom: none;
      }
    }
  }

  &__image {
    max-width: 100px;
    height: 100px;
    border-radius: 24px;
    overflow: hidden;
    object-fit: cover;

    img {
      width: 100%;
    }
  }

  &__title {
    color: $aco-blue;
    font-weight: bold;
    hyphens: auto; /* Automatische Silbentrennung */
    hyphenate-limit-chars: auto 3;
    hyphenate-limit-lines: 4;
  }

  &__category {
    font-size: 12px;
    color: $aco-gray-01;
  }

  &__count {
    color: $aco-gray-01;
  }

  &:focus {
    .post-card__image {
      border: 2px solid $aco-dark-blue;
    }
  }

  &:hover {
    .post-card__title {
      color: $aco-dark-blue;
    }
  }
}
