/* Spinners */

/**
 Variables
 */
$spinner-width: 4rem;
$spinner-height: $spinner-width;
$spinner-vertical-align: -0.125rem;
$spinner-animation-speed: 1s;
$spinner-width-sm: 3rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-width-lg: 5rem;
$spinner-height-lg: $spinner-width-lg;
$spinner-border-width-sm: 0.1875rem;
$spinner-border-width: 0.375rem;
$spinner-border-width-lg: 0.5625rem;

/**
 BS default import
 */
@import "~bootstrap/scss/spinners";

.spinner-border {
  position: relative;
  height: $spinner-width;
  width: $spinner-height;
  box-sizing: border-box;
  border-radius: $border-radius-pill;
  border: none;

  &.spinner-sm {
    width: $spinner-width-sm;
    height: $spinner-height-sm;
  }

  &.spinner-lg {
    width: $spinner-width-lg;
    height: $spinner-height-lg;
  }

  &::after,
  &.spinner-md::after {
    content: "";
    position: absolute;
    right: 50%;
    bottom: 0;
    transform: translate(50%);
    border-radius: $border-radius-pill;
    height: 10%;
    width: 10%;
  }

  &.spinner-sm::after {
    height: 8%;
    width: 8%;
  }

  &.spinner-lg::after {
    height: 11%;
    width: 11%;
  }
}

@include set-theme-colors($theme-colors)
