/* Input group */

@use "sass:map";

/**
 Variables
 */
$input-group-addon-padding-y: 0;
$input-group-addon-padding-x: map.get($spacers, "spacing-8");
$input-group-addon-font-weight: $font-weight-bold;
$input-group-addon-color: $aco-dark-blue;
$input-group-addon-disabled-color: $aco-dark-blue-disabled;
$input-group-addon-bg: $aco-white;
$input-group-addon-border-color: $aco-gray-06;
$input-group-addon-focus-color: $aco-light-blue;
$input-group-addon-border-width: 2px;
$input-group-addon-base-width: 1px;
$input-group-addon-max-height: 48px;
$input-group-addon-max-width: 176px;
$input-group-addon-small-max-height: 32px;
$input-group-addon-small-max-width: 112px;
$input-group-addon-input-max-width: 80px;
$input-group-addon-error-color: $aco-red;
$input-group-addon-button-background-color: $aco-gray-06;
$input-group-addon-button-background-color-hover: $aco-gray-05;

@mixin border($color, $width) {
  box-shadow: 0 0 0 $width $color inset;
}

.input-group {
  &.stepper {
    height: $input-group-addon-max-height;
    width: $input-group-addon-max-width;
    color: $input-group-addon-color;

    & > * {
      margin-left: 0 !important;
    }

    & input {
      @include border(
        $input-group-addon-border-color,
        $input-group-addon-border-width
      );

      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      padding: $input-group-addon-padding-y $input-group-addon-padding-x;
      color: $aco-dark-blue;
      font-size: $h5-font-size;
      border: none;
      text-align: center;
      outline: none;

      &:hover {
        @include border(
          $input-group-addon-focus-color,
          $input-group-addon-border-width
        );
      }

      &:focus {
        @include border(
          $input-group-addon-focus-color,
          $input-group-addon-border-width
        );

        background: $input-group-addon-bg;
      }

      &:disabled {
        @extend %input-disabled;
      }

      &:invalid {
        @include border(
          $input-group-addon-error-color,
          $input-group-addon-border-width
        );
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }

      &[type="number"] {
        appearance: textfield;
      }
    }

    & button,
    & .btn {
      @include border(transparent, $input-group-addon-border-width);

      position: relative;
      height: 48px;
      width: 48px;
      aspect-ratio: 1;
      border-radius: 9999px;
      background: $input-group-addon-button-background-color;
      color: $aco-dark-blue;

      &:hover {
        background: $input-group-addon-button-background-color-hover;
      }

      &:focus {
        @include border(
          $input-group-addon-focus-color,
          $input-group-addon-border-width
        );
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        transform: translate(-50%, -50%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px;
      }

      &.btn-plus {
        @extend %btn-plus-disabled;

        &::after {
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($input-group-addon-color)}' d='M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z'/></svg>");
        }
      }

      &.btn-minus {
        @extend %btn-minus-disabled;

        &::after {
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($input-group-addon-color)}' d='M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z'/></svg>");
        }
      }
    }

    &.small-stepper {
      height: $input-group-addon-small-max-height;
      width: $input-group-addon-small-max-width;

      & button {
        height: 32px;
        width: 32px;

        &::after {
          background-size: 16px;
        }
      }

      & input {
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        width: 48px;
      }
    }
  }
}

.input-disabled,
%input-disabled {
  pointer-events: none;
  color: $input-group-addon-disabled-color;
  background: $input-group-addon-bg;
}

.btn-minus-disabled,
%btn-minus-disabled {
  &:disabled::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($input-group-addon-disabled-color)}' d='M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z'/></svg>");
  }
}

.btn-plus-disabled,
%btn-plus-disabled {
  &:disabled::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill='#{to-rgb($input-group-addon-disabled-color)}' d='M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z'/></svg>");
  }
}

@include media-breakpoint-down(md) {
  .input-group.stepper {
    height: $input-group-addon-small-max-height;

    & button {
      height: 32px;
      width: 32px;

      &::after {
        background-size: 16px;
      }
    }

    & input {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      width: 48px;
    }
  }
}
