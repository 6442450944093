@use "sass:map";

.circle-gradient-online-apotheke {
  @include circle-gradient($center-bg-color: $aco-white, $size: 80px, $border-main-color: $aco-blue, $border-secondary-color: transparent, $border-size: 20%);

  @media screen and (max-width: map.get($grid-breakpoints, md)) {
    @include circle-gradient($center-bg-color: $aco-white, $size: 70px, $border-main-color: $aco-blue, $border-secondary-color: transparent, $border-size: 20%);
  }

  @media screen and (max-width: map.get($grid-breakpoints, sm)) {
    @include circle-gradient($center-bg-color: $aco-white, $size: 60px, $border-main-color: $aco-blue, $border-secondary-color: transparent, $border-size: 20%);
  }
}

.cms-main-section h1,
.cms-main-section h2,
.cms-main-section h3,
.cms-main-section h4,
.cms-main-section h5 {
  margin-bottom: map.get($spacers, "spacing-24");
}

@include media-breakpoint-down(lg) {
  .cms-main-section h1,
  .cms-main-section h2,
  .cms-main-section h3,
  .cms-main-section h4,
  .cms-main-section h5 {
    margin-bottom: map.get($spacers, "spacing-20");
  }
}

.cms-main-section ul.inside-list {
  list-style-position: inside;
}

.cms-banner-section {
  position: relative;
  margin-bottom: map.get($spacers, "spacing-24");

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 4px 4px 80px 0 rgba(99 99 102 / 8%);
  }
}

.cms-main-section .cms-internal-banner-section {
  margin-top: map.get($spacers, "spacing-48");
  margin-bottom: map.get($spacers, "spacing-48");
}

.cms-rounded-section {
  position: relative;
  background-color: $aco-white;
  padding-top: map.get($spacers, "spacing-48");
  padding-bottom: map.get($spacers, "spacing-48");
  border-top-left-radius: map.get($radius-map, 12);
  border-top-right-radius: map.get($radius-map, 12);

  & + .cms-rounded-section {
    padding-bottom: calc(map.get($spacers, "spacing-48") + map.get($spacers, "spacing-12"));
  }
}

.dark-blue-cms-rounded-section {
  background: $aco-dark-blue;
  color: $aco-white;
}

.blue-cms-rounded-section {
  background: $aco-blue;
  color: $aco-white;
}

.gray-06-cms-rounded-section {
  background-color: $aco-gray-06;
}

.cms-rounded-section.outer {
  margin-top: - map.get($spacers, "spacing-12");
}

.cms-rounded-section.inner {
  padding-bottom: calc(map.get($spacers, "spacing-48") + map.get($spacers, "spacing-12"));
}

.cms-main-section {
  color: $aco-dark-blue;
}

.cms-main-section:not(.apply-to-last) {
  padding-bottom: map.get($spacers, "spacing-128");
}

.cms-main-section.apply-to-last > *:last-child {
  padding-bottom: map.get($spacers, "spacing-128");
}

.cms-main-section > .cms-rounded-section:last-child:not(.keep-padding-bottom) {
  padding-bottom: 0;
}

.footer-tiles .service-teaser-card .icon svg {
  width: 48px;
  height: 48px;
}

@include media-breakpoint-down(xxl) {
  .cms-main-section:not(.apply-to-last) {
    padding-bottom: map.get($spacers, "spacing-112");
  }

  .cms-main-section.apply-to-last > *:last-child {
    padding-bottom: map.get($spacers, "spacing-112");
  }
}

@include media-breakpoint-down(xl) {
  .cms-rounded-section {
    padding-top: map.get($spacers, "spacing-40");
    padding-bottom: map.get($spacers, "spacing-40");
  }

  .cms-rounded-section + .cms-rounded-section,
  .cms-rounded-section.inner {
    padding-bottom: calc(map.get($spacers, "spacing-40") + map.get($spacers, "spacing-12"));
  }

  .cms-main-section:not(.apply-to-last) {
    padding-bottom: map.get($spacers, "spacing-96");
  }

  .cms-main-section.apply-to-last > *:last-child {
    padding-bottom: map.get($spacers, "spacing-96");
  }

  .cms-main-section .cms-internal-banner-section {
    margin-top: map.get($spacers, "spacing-40");
    margin-bottom: map.get($spacers, "spacing-40");
  }
}

@include media-breakpoint-down(lg) {
  .cms-rounded-section {
    padding-top: map.get($spacers, "spacing-32");
    padding-bottom: map.get($spacers, "spacing-32");
  }

  .cms-rounded-section + .cms-rounded-section,
  .cms-rounded-section.inner {
    padding-bottom: calc(map.get($spacers, "spacing-32") + map.get($spacers, "spacing-12"));
  }

  .dark-blue-cms-rounded-section h1 {
    margin-bottom: map.get($spacers, "spacing-20");
  }

  .cms-main-section:not(.apply-to-last) {
    padding-bottom: map.get($spacers, "spacing-80");
  }

  .cms-main-section.apply-to-last > *:last-child {
    padding-bottom: map.get($spacers, "spacing-80");
  }

  .cms-main-section .cms-banner-section {
    margin-bottom: map.get($spacers, "spacing-16");
  }

  .cms-main-section .cms-internal-banner-section {
    margin-top: map.get($spacers, "spacing-32");
    margin-bottom: map.get($spacers, "spacing-32");
  }
}

@include media-breakpoint-down(md) {
  .cms-rounded-section {
    padding-top: map.get($spacers, "spacing-24");
    padding-bottom: map.get($spacers, "spacing-24");
  }

  .cms-rounded-section + .cms-rounded-section,
  .cms-rounded-section.inner {
    padding-bottom: calc(map.get($spacers, "spacing-24") + map.get($spacers, "spacing-12"));
  }

  .cms-main-section:not(.apply-to-last) {
    padding-bottom: map.get($spacers, "spacing-64");
  }

  .cms-main-section.apply-to-last > *:last-child {
    padding-bottom: map.get($spacers, "spacing-64");
  }

  .cms-main-section .cms-banner-section {
    margin-bottom: map.get($spacers, "spacing-24");
  }

  .cms-main-section .cms-internal-banner-section {
    margin-top: map.get($spacers, "spacing-24");
    margin-bottom: map.get($spacers, "spacing-24");
  }
}

.cms-main-section .cms-banner-section,
.cms-main-section .cms-internal-banner-section {
  & img {
    object-fit: cover;
    height: 300px;

    @include media-breakpoint-down(md) {
      height: 200px;
    }
  }
}
