/* Form-input */

@use "sass:map";

/**
 Variables
 */
$input-max-width: 382px;
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;
$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;
$input-font-size-xs: $input-btn-font-size-xs;
$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-bg: $body-bg;
$input-disabled-bg: $gray-200;
$input-disabled-border-color: $aco-dark-blue-disabled;
$input-color: $body-color;
$input-border-color: $aco-dark-blue;
$input-border-width: $input-btn-border-width;
$input-box-shadow: $box-shadow-inset;
$input-border-radius: $border-radius;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-lg: $border-radius-lg;
$input-focus-bg: transparent;
$input-focus-border-color: transparent;
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;
$input-placeholder-color: $gray-600;
$input-plaintext-color: $body-color;
$input-height-border: $input-border-width * 2;
$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y);
$input-height-inner-quarter: add(
  $input-line-height * 0.25em,
  $input-padding-y * 0.5
);
$input-height: add(
  $input-line-height * 1em,
  add($input-padding-y * 2, $input-height-border, false)
);
$input-height-sm: add(
  $input-line-height * 1em,
  add($input-padding-y-sm * 2, $input-height-border, false)
);
$input-height-lg: add(
  $input-line-height * 1em,
  add($input-padding-y-lg * 2, $input-height-border, false)
);
$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
$form-color-width: 3rem;
$form-label-height: 16px;

// Checkboxes and Radio
$form-check-input-width: 1.5em;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-start: $form-check-input-width + 0.5em;
$form-check-margin-bottom: 0.125rem;
$form-check-label-color: null;
$form-check-label-cursor: null;
$form-check-transition: null;
$form-check-input-active-filter: brightness(90%);
$form-check-input-bg: $aco-white;
$form-check-input-border: 2px solid $aco-dark-blue;
$form-check-input-border-radius: 0.25em;
$form-check-radio-border-radius: $border-radius-pill;
$form-check-input-focus-border: $aco-dark-blue;
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-color: $aco-dark-blue;
$form-check-input-checked-bg-color: $aco-light-blue;
$form-check-input-checked-border-color: $aco-light-blue;
$form-check-input-checked-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$aco-dark-blue}" stroke="#{$aco-dark-blue}" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>');
$form-check-radio-checked-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$aco-white}" stroke="#{$aco-white}" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>');
$form-check-radio-size: 1rem;
$form-check-input-indeterminate-color: $aco-white;
$form-check-input-indeterminate-bg-color: $aco-light-blue;
$form-check-input-indeterminate-border-color: transparent;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='#{$aco-white}' stroke='#{$aco-white}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");
$form-check-input-disabled-opacity: 1;
$form-check-label-disabled-opacity: 1;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity;
$form-check-inline-margin-end: 1rem;
$form-switch-color: $aco-white;
$form-switch-width: 3.5rem;
$form-switch-height: 2rem;
$form-switch-padding-start: calc($form-switch-width + 0.5rem);
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$aco-dark-blue}'/></svg>");
$form-switch-border-radius: $form-switch-width;
$form-switch-transition: background-position 0.15s ease-in-out;
$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$aco-white}'/></svg>");
$form-switch-checked-bg-position: right center;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$aco-dark-blue}'/></svg>");

/**
 BS default import
 */
@import "~bootstrap/scss/placeholders";

/**
 Custom Styles
 */

input[name="postcode"] {
  text-transform: uppercase;
}

.form-check:not(.form-switch) {
  max-width: $input-max-width;

  & .form-check-input {
    margin-right: map.get($spacers, "spacing-12");

    &[type="checkbox"] {
      background-color: $aco-white;
      border-color: $aco-dark-blue;

      & + .form-check-label {
        color: $aco-dark-blue;
      }

      @at-root .dark {
        & .form-check:not(.form-switch) .form-check-input[type="checkbox"] {
          border-color: transparent;

          & + .form-check-label {
            color: $aco-gray-06;
          }

          &:disabled {
            border-color: transparent;

            & + .form-check-label {
              color: $aco-gray-06;
            }
          }
        }
      }

      &:focus {
        border-color: $aco-light-blue;
      }

      &:disabled {
        border-color: $aco-dark-blue-disabled;

        & + .form-check-label {
          color: $aco-dark-blue-disabled;
        }
      }

      &:checked {
        background-color: $aco-light-blue;
        border-color: transparent;

        &:hover {
          background-color: $aco-light-blue-hover;
        }

        &:focus {
          border-color: $aco-dark-blue;
        }

        &:disabled {
          background-color: $aco-chatbot-blue;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-dark-blue-disabled)}" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>');
        }
      }

      &:hover:not(:disabled) {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-dark-blue)}" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>');
      }

      &:indeterminate:disabled {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path stroke="#{to-rgb($aco-dark-blue-disabled)}" fill="#{to-rgb($aco-dark-blue-disabled)}" d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z"/></svg>');
        background-color: $aco-chatbot-blue;
        border-color: transparent;
      }

      &:indeterminate:not(:disabled) {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-dark-blue)}" d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z"/></svg>');
        background-color: $aco-light-blue;

        &:hover {
          background-color: $aco-light-blue-hover;
        }
      }

      &.is-invalid {
        border-color: $aco-red;

        & + .form-check-label {
          color: $aco-red;
        }
      }
    }

    &[type="radio"] {
      width: $form-check-radio-size;
      height: $form-check-radio-size;
      background-color: $aco-white;

      &:disabled {
        border-color: $aco-dark-blue-disabled;
      }

      &:checked {
        background-color: $aco-dark-blue;
        border: none;
        transform: scale(1.4);
        background-size: 50%;

        &:disabled {
          background-color: $aco-dark-blue-disabled;
        }
      }
    }
  }
}

.form-check.form-switch {
  display: flex;
  width: fit-content;
  gap: map.get($spacers, "spacing-12");
  color: $aco-dark-blue;
  font-size: $input-btn-font-size-sm;

  & .form-check-input {
    background-color: $aco-white;
    border-color: $aco-dark-blue;
    width: $form-switch-width;
    min-width: $form-switch-width;
    height: $form-switch-height;

    &:focus {
      border-color: $aco-light-blue;
    }

    &:disabled {
      border-color: $aco-dark-blue-disabled;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{to-rgb($aco-dark-blue-disabled)}'/></svg>");
    }

    &.is-invalid {
      border-color: $aco-red;
    }
  }

  &.dark .form-check-input {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{to-rgb($aco-white)}'/></svg>");
    background-color: transparent;
    border-color: $aco-white;
  }

  & .form-check-input:checked {
    border-color: transparent;
    background-color: $aco-light-blue;

    &:focus {
      border-color: $aco-dark-blue;
    }

    &:hover {
      background-color: $aco-light-blue-hover;
    }

    &:disabled {
      background-color: $aco-chatbot-blue;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{to-rgb($aco-white)}'/></svg>");
    }
  }

  &.dark .form-check-input:focus {
    border-color: $aco-light-blue;
  }

  &.dark .form-check-input:disabled {
    border-color: $aco-gray-03;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{to-rgb($aco-gray-03)}'/></svg>");
  }

  &.dark .form-check-input:checked {
    &:focus {
      border-color: $aco-white;
    }

    &:disabled {
      border-color: transparent;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{to-rgb($aco-white)}'/></svg>");
    }
  }

  &.dark .form-check-input.is-invalid {
    border-color: $aco-red;
  }
}

.form-check.form-toggle {
  & .btn-check {
    & + .btn {
      border-radius: $border-radius-pill;
      font-size: $input-btn-font-size;
    }

    & + .btn.btn-primary {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      background-color: $aco-light-blue;

      & path {
        fill: $aco-white;
      }
    }

    & + .btn.btn-primary.btn-small {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      background-color: $aco-white;
      padding: 0;

      & path {
        fill: $aco-gray-04;
      }
    }

    &:focus {
      & + .btn.btn-primary {
        border: 2px solid $aco-dark-blue;
      }

      & + .btn.btn-primary.btn-small {
        border: 2px solid $aco-light-blue;
      }
    }

    &:hover {
      & + .btn.btn-primary {
        background-color: $aco-light-blue-hover;
      }

      & + .btn.btn-primary.btn-small {
        background-color: $aco-gray-06;
      }
    }

    &.is-invalid {
      & + .btn.btn-primary {
        border: 2px solid $aco-red;
      }
    }

    &:checked {
      & + .btn.btn-primary {
        background-color: $aco-light-blue;

        & path {
          fill: $aco-dark-blue;
        }
      }

      & + .btn.btn-primary.btn-small {
        background-color: $aco-white;

        & path {
          fill: $aco-light-blue;
        }
      }

      &:disabled {
        & + .btn.btn-primary {
          background-color: $aco-chatbot-blue;

          & path {
            fill: $aco-dark-blue-disabled;
          }
        }

        & + .btn.btn-primary.btn-small {
          background-color: $aco-white;

          & path {
            fill: $aco-chatbot-blue;
          }
        }
      }

      &:hover {
        & + .btn.btn-primary {
          background-color: $aco-light-blue-hover;
        }

        & + .btn.btn-primary.btn-small {
          background-color: $aco-gray-06;
        }
      }

      &.is-invalid {
        & + .btn.btn-primary.btn-small {
          border: 2px solid $aco-red;

          & path {
            fill: $aco-red;
          }
        }
      }
    }

    &:not(:checked):disabled {
      & + .btn.btn-primary {
        background-color: $aco-chatbot-blue;
      }

      & + .btn.btn-primary.btn-small {
        background-color: $aco-white;

        & path {
          fill: $aco-gray-06;
        }
      }
    }
  }
}

.form-input-wrapper {
  max-width: $input-max-width;
  position: relative;
  background-color: transparent;

  &.select-textarea {
    width: 100%;
    max-width: 382px;
  }

  & .form-label {
    font-weight: $font-weight-medium;
    position: absolute;
    top: 18px;
    left: 0;
    color: $aco-blue;
    transition: all 0.1s linear;
    pointer-events: none;
    margin-bottom: 0;
  }

  & .form-message {
    color: $aco-gray-02;
    font-size: $font-size-sm;
    margin-top: map.get($spacers, "spacing-6");
    position: absolute;
  }

  & textarea.form-control {
    color: $aco-dark-blue;
    font-weight: $font-weight-medium;
    background: $aco-white;
    border-radius: map.get($radius-map, 8);
    border: 2px solid $aco-gray-04;
    padding: map.get($spacers, "spacing-12") map.get($spacers, "spacing-16");
    margin-top: map.get($spacers, "spacing-48");

    & + .form-label {
      top: map.get($spacers, "spacing-16");
      left: map.get($spacers, "spacing-16");
      font-size: 0.75rem;
      font-weight: 400;
    }

    &:placeholder-shown ~ .form-label {
      font-size: $input-font-size-sm;
      top: 0;
      left: 0;
      transform: translateY(-150%);
    }

    & ~ {
      font-size: $input-font-size-sm;
      top: 0;
      transform: translateY(calc(-100% - map.get($spacers, "spacing-16")));
    }

    &.is-invalid {
      border: 2px solid $aco-red;
    }

    &:read-only {
      border: 2px solid $aco-gray-06;
      color: $aco-dark-blue;

      & + .form-label {
        color: $aco-blue-disabled;
      }
    }

    &:disabled {
      border: 2px solid $aco-gray-06;
      color: $aco-dark-blue-disabled;

      & + .form-label {
        color: $aco-dark-blue-disabled;
      }
    }
  }

  & :not(textarea).form-control {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $aco-gray-04;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1px;
    background-color: transparent;
    color: $aco-dark-blue;
    font-weight: $font-weight-medium;
    margin-top: map.get($spacers, "spacing-16");

    &:read-only ~ .form-label {
      color: $aco-blue-disabled;
    }

    &:disabled ~ .form-label {
      color: $aco-dark-blue-disabled;
    }

    &:placeholder-shown ~ .form-label {
      font-size: $input-font-size-sm;
      top: 0;
      transform: translateY(-100%);
    }

    &:read-only {
      background-color: transparent;
      border-bottom: 1px solid $aco-gray-04;
      color: $aco-dark-blue;
    }

    &:disabled {
      background-color: transparent;
      border-bottom: 1px solid $aco-gray-04;
      color: $aco-dark-blue-disabled;
    }

    &.is-invalid {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{to-rgb($aco-red)}" d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"/></svg>');
      border-bottom: 2px solid $aco-red;
      padding-bottom: 1px;
      background-size: 16px;
      padding-right: map.get($spacers, "spacing-32");
    }

    & + .form-label.floating-label {
      font-weight: $font-weight-normal;
      font-size: $input-font-size-xs;
      top: 0;
      transition: all 0.1s linear;
    }
  }

  & .form-label-error {
    position: absolute;
  }

  &.voucher {
    & .form-control.voucher-clear {
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"/></svg>');
    }
  }
}

.form-input-wrapper,
.review {
  max-width: unset;
  position: relative;

  & textarea::placeholder {
    color: $aco-dark-blue;
  }

  & .placeholder-asterisk {
    position: absolute;
    top: 12px;
    left: 280px;
    color: $aco-blue;
  }

  & .gone {
    display: none;
  }

  & .form-message {
    font-size: $font-size-sm;
  }
}

.dark .form-input-wrapper textarea.form-control + .form-label {
  color: $aco-light-blue;
}

.dark .form-input-wrapper :not(textarea).form-control {
  color: $aco-white;
  background-position: right 8px bottom 10px;

  & ~ .form-label {
    color: $aco-light-blue;
  }

  &:read-only {
    color: $aco-white;
    background-color: transparent;
    border-bottom: 1px solid $aco-gray-01;

    & ~ .form-label {
      color: $aco-gray-01;
    }
  }

  &:disabled {
    color: $aco-gray-01;

    & ~ .form-label {
      color: $aco-gray-01;
    }
  }

  &.is-invalid {
    color: $aco-red;
  }
}

.dark .form-input-wrapper textarea.form-control {
  & + .form-message {
    color: $aco-gray-03;
  }
}

.form-input-wrapper textarea.form-control:hover:not(:read-only):not(:disabled) {
  border: 2px solid $aco-gray-05;
}

.form-input-wrapper textarea.form-control:focus:not(:read-only):not(:disabled) {
  border: 2px solid $aco-light-blue;
  color: $aco-dark-blue;

  & + .form-label {
    top: 1rem;
    left: 0;
    font-size: 0.75rem;
    font-weight: 400;
  }
}

.dark .form-input-wrapper textarea.form-control:hover:not(:read-only):not(:disabled) {
  border: 2px solid $aco-white;
}

.dark .form-input-wrapper textarea.form-control:focus:not(:read-only):not(:disabled) {
  border: 2px solid $aco-light-blue;
}

.form-input-wrapper :not(textarea).form-control:hover:not(:read-only):not(:disabled) {
  border-bottom: 2px solid $aco-dark-blue;
  padding-bottom: 1px;
}

.form-input-wrapper :not(textarea).form-control:focus:not(:read-only):not(:disabled) {
  border-bottom: 2px solid $aco-light-blue;
  color: $aco-dark-blue;
  padding-bottom: 1px;

  & ~ .form-label {
    font-size: $input-font-size-xs;
    top: 0;
  }
}

.dark .form-input-wrapper :not(textarea).form-control:focus:not(:read-only):not(:disabled) {
  color: $aco-white;
}

.dark .form-input-wrapper :not(textarea).form-control:hover:not(:read-only):not(:disabled) {
  border-bottom: 2px solid $aco-white;
  padding-bottom: 1px;
}

/* stylelint-disable */

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus, {
    filter: none;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0 1000px rgba(255 255 255 / 0%) inset;
    transition: background-color 5000s ease-in-out 0s;

    &:disabled {
      -webkit-text-fill-color: $aco-gray-01;
    }
  }

  // firefox
  input:autofill {
    background: rgba(255 255 255 / 0%);
  }
.modal-white,
.show-order-details-nologin {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus, {
    -webkit-text-fill-color: $aco-black;
  }
}

.fade-out {
  left: -500000px;
  opacity: 0;
  transition: opacity 500ms, left 0ms 500s;
}

#newsletter_input{
  display: inline;
}

/* stylelint-enable */
