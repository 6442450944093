@use "sass:map";

/* Link list */

.link-list {
  list-style: none;
  padding-left: 0;
  margin: 0;

  a {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem $content-spacing-x 0.75rem 0;

    // Indirect way to activate mix-blend-mode only for borders, not for other colors
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: inline-block;
      width: 100%;
      height: map.get($border-widths, "medium");
      background: $aco-gray-06;
      mix-blend-mode: multiply;
    }
  }

  &__icon {
    flex: 0 0 auto;
    width: 1rem;
    height: 1rem;
    min-height: 0;
    fill: currentColor;
  }

  // Remove border for last element
  &__item {
    &:last-child {
      a {
        &::after {
          content: none;
        }
      }
    }
  }
}
