.text-icon-component {
  display: flex;
  column-gap: 1rem;

  &__text {
    p {
      margin: 0;
    }
  }
}
