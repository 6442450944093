@use "sass:map";

/* Home */
$home-main-content-max-width-elements: 416px;
$home-main-content-min-width-elements: 390px;
$home-main-content-height-elements-mobile: 161px;
$home-main-content-width-elements-mobile: 161px;
$home-main-content-min-height-cards: 80px;
$gradient-circle-wrapper-padding-right: 8.83%;
$gradient-circle-wrapper-padding-bottom: 6.6031%;
$gradient-circle-wrapper-padding-bottom-sm: 9%;
$gradient-circle-internal-icon-size: 17.1613%;
$secondary-gradient-circle-size: 57.69%;
$padding-product-section: map.get($spacers, "spacing-32");
$section-rounded-top-padding: map.get($spacers, "spacing-48");
$section-rounded-top-padding-sm: map.get($spacers, "spacing-24");
$section-rounded-border-radius: map.get($radius-map, 12);

.section-rounded-top {
  @include section-rounded-top(transparent, $section-rounded-top-padding, $section-rounded-border-radius);

  @media screen and (max-width: map.get($grid-breakpoints, md)) {
    @include section-rounded-top(transparent, $section-rounded-top-padding-sm, $section-rounded-border-radius);
  }
}

.white-section-rounded-top {
  @include section-rounded-top($aco-white, $section-rounded-top-padding, $section-rounded-border-radius);

  @media screen and (max-width: map.get($grid-breakpoints, md)) {
    @include section-rounded-top($aco-white, $section-rounded-top-padding-sm, $section-rounded-border-radius);
  }
}

.gray-section-rounded-top {
  @include section-rounded-top($aco-gray-06, $section-rounded-top-padding, $section-rounded-border-radius);

  @media screen and (max-width: map.get($grid-breakpoints, md)) {
    @include section-rounded-top($aco-gray-06, $section-rounded-top-padding-sm, $section-rounded-border-radius);
  }
}

.usp-banner-section {
  @include section-rounded-top(transparent, $section-rounded-top-padding, $section-rounded-border-radius, $section-rounded-top-padding);

  @media screen and (max-width: map.get($grid-breakpoints, md)) {
    @include section-rounded-top(transparent, $section-rounded-top-padding-sm, $section-rounded-border-radius, $section-rounded-top-padding-sm);
  }
}

.themed-grid-col {
  margin-top: 0;
}

.image-e-prescription {
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media screen and (min-width: map.get($grid-breakpoints, xs)) {
    width: 336px;
    height: 199.1px;
  }

  @media screen and (min-width: map.get($grid-breakpoints, md)) {
    width: 216px;
    height: 128px;
  }

  @media screen and (min-width: map.get($grid-breakpoints, xl)) {
    width: 261px;
    height: 154.7px;
  }

  @media screen and (min-width: map.get($grid-breakpoints, xxl)) {
    width: 306px;
    height: 181.3px;
  }
}

.home-main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $aco-white;

  & .section-2 {
    background-image: url("/releases/current/templates/apo_com_responsive/assets/img/backgrounds/home/landing_page_sky_background.jpg");
    background-image:
      image-set(
        url("/releases/current/templates/apo_com_responsive/assets/img/backgrounds/home/landing_page_sky_background@2x.jpg") 2x,
        url("/releases/current/templates/apo_com_responsive/assets/img/backgrounds/home/landing_page_sky_background@3x.jpg") 3x
      );
    background-size: cover;
    background-position: center;
  }

  & .bottom-section {
    padding-top: map.get($spacers, "spacing-32");
    padding-bottom: calc($section-rounded-border-radius + map.get($spacers, "spacing-32"));
    margin-top: 0;

    & .left-side,
    & .right-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  & .other-services-card {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: $home-main-content-min-height-cards;
    color: $aco-white;
    background: $aco-dark-blue;
    padding: map.get($spacers, "spacing-16");
    border: 2px solid $aco-blue-disabled;
    border-radius: map.get($radius-map, 20);
    outline: none;

    &:hover {
      border: 2px solid $aco-white;
    }

    &:focus {
      border: 2px solid $aco-light-blue;
      outline: none;
    }

    & .icon {
      width: 58px;
      padding-right: map.get($spacers, "spacing-16");

      svg {
        width: 100%;
        height: 42px;
      }
    }

    & .content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      & .title {
        font-weight: $font-weight-bold;
      }

      & .description {
        font-size: $font-size-sm;
        color: $aco-gray-03;
      }
    }
  }

  & .other-services-card-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: map.get($spacers, "spacing-6");
    justify-content: center;
    width: $home-main-content-width-elements-mobile;
    min-height: $home-main-content-height-elements-mobile;
    color: $aco-white;
    background: $aco-dark-blue;
    padding: map.get($spacers, "spacing-16");
    border: 2px solid $aco-blue-disabled;
    border-radius: map.get($radius-map, 20);
    outline: none;

    &:hover {
      border: 2px solid $aco-white;
    }

    &:focus {
      border: 2px solid $aco-light-blue;
      outline: none;
    }

    & .icon {
      width: fit-content;
    }

    & .content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      & .title {
        font-weight: $font-weight-bold;
      }
    }
  }

  & .gradient-circles-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (min-width: map.get($grid-breakpoints, xxl)) {
      padding-left: 0;
    }

    & .main-gradient-circle {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      max-width: $home-main-content-max-width-elements;
      max-height: $home-main-content-max-width-elements;
      aspect-ratio: 1;
      border-radius: $border-radius-pill;
      transform-style: preserve-3d;

      @media screen and (min-width: map.get($grid-breakpoints, xs)) {
        width: 290px;
        height: 290px;
        margin-bottom: 12.5%;
      }

      @media screen and (min-width: map.get($grid-breakpoints, sm)) {
        margin-bottom: 9.1%;
      }

      @media screen and (min-width: map.get($grid-breakpoints, md)) {
        margin-bottom: 14%;
      }

      @media screen and (min-width: map.get($grid-breakpoints, lg)) {
        width: 345px;
        height: 345px;
        margin-bottom: 10.6%;
      }

      @media screen and (min-width: map.get($grid-breakpoints, lg)) and (max-width: map.get($grid-breakpoints, xl)) {
        margin-right: 27%;
      }

      @media screen and (min-width: map.get($grid-breakpoints, xl)) {
        width: 416px;
        height: 416px;
        margin-bottom: 13%;
      }

      @media screen and (min-width: map.get($grid-breakpoints, xxl)) {
        margin-bottom: 21.5%;
        margin-right: 20%;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.7;
        border-radius: $border-radius-pill;
        background-image: linear-gradient(to right, $aco-dark-blue 11%, $aco-blue 73%, #2f82c9 100%);
        box-shadow: 0 4px 4px 0 rgba($aco-black, 25%);
      }

      & .content {
        position: relative;
        z-index: 2;
        padding-top: 1rem;

        & h1 {
          margin-bottom: map.get($spacers, "spacing-24");
          line-height: 1.0625;
          word-break: break-word;

          @media screen and (max-width: map.get($grid-breakpoints, lg)) {
            font-size: 1.75rem;
            line-height: 42px;
            margin-bottom: map.get($spacers, "spacing-8");
          }

          @media screen and (min-width: map.get($grid-breakpoints, lg)) {
            font-size: 2.5rem;
          }

          @media screen and (min-width: map.get($grid-breakpoints, xl)) {
            font-size: 3.5rem;
          }

          @media screen and (min-width: map.get($grid-breakpoints, xxl)) {
            font-size: 4rem;
          }
        }

        @media screen and (min-width: map.get($grid-breakpoints, lg)) {
          transform: translateX(10%);
        }

        @media screen and (max-width: map.get($grid-breakpoints, md)) {
          padding-top: 0;
        }
      }

      & .internal-icon {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1;
        width: 49px;
        height: 49px;
        background: $aco-light-blue;
        border-radius: $border-radius-pill;
        transform: translate(-35%, 25%);

        @media screen and (min-width: map.get($grid-breakpoints, lg)) {
          transform: translateY(3%);
        }

        @media screen and (min-width: map.get($grid-breakpoints, lg)) {
          width: 80px;
          height: 80px;
        }

        @media screen and (min-width: map.get($grid-breakpoints, xl)) {
          width: 96px;
          height: 96px;
        }

        & .main-icon {
          width: 50%;
          height: 50%;
          color: $aco-dark-blue;
        }
      }

      & .btn {
        position: absolute;
        bottom: 0;

        @media screen and (max-width: map.get($grid-breakpoints, "lg")) {
          transform: translateY(90%);
        }

        @media screen and (min-width: map.get($grid-breakpoints, "lg")) {
          transform: translateY(70%);
        }

        @media screen and (min-width: map.get($grid-breakpoints, "xl")) {
          transform: translateY(15%);
        }

        @media screen and (min-width: map.get($grid-breakpoints, "xxl")) {
          transform: translateY(30%);
        }
      }

      & .secondary-gradient-circle {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        opacity: 0.6;
        aspect-ratio: 1;
        border-radius: $border-radius-pill;
        background-image: linear-gradient(to right, $aco-dark-blue 0%, $aco-blue 100%);

        @media screen and (max-width: map.get($grid-breakpoints, lg)) {
          transform: translate(2%, 13%);
          width: 125px;
          height: 125px;
        }

        @media screen and (min-width: map.get($grid-breakpoints, lg)) and (max-width: map.get($grid-breakpoints, xl)) {
          transform: translate(58%, 17%);
          width: 200px;
          height: 200px;
        }

        @media screen and (min-width: map.get($grid-breakpoints, xl)) and (max-width: map.get($grid-breakpoints, xxl)) {
          transform: translate(18%, 28%);
          height: 240px;
          width: 240px;
        }

        @media screen and (min-width: map.get($grid-breakpoints, xxl)) {
          transform: translate(42%, 38%);
          height: 240px;
          width: 240px;
        }
      }
    }
  }
}

.card-title-truncate-overflow {
  @include ellipsis-line-clamp(2);

  min-height: 48px;
  line-height: 1.56;
  max-width: 100%;
  margin-bottom: 0;

  p {
    margin-bottom: 0;
  }
}

/* stylelint-disable selector-id-pattern */
#zusatz_banner_above {
  transition: height 900ms;
  height: 0;
  overflow: hidden;
  will-change: height;

  &[data-dy-embedded-object="true"] {
    height: 64px;
  }
}
