@use "sass:map";

$check-icon-size: 162px;
$check-icon-rotation: -60deg;
$check-icon-border-radius: $border-radius-pill;
$check-icon-head-border-radius: $border-radius-pill;
$check-icon-border-size: 16%;
$check-icon-background-size: 70%;
$envelope-icon-background-size: 50%;
$check-icon-background-size: 52.3%;
$check-icon-main-color: $aco-light-blue;
$check-icon-secondary-color: $aco-dark-blue;
$check-icon-center-size: calc(100% - $check-icon-border-size);
$check-icon-head-size: calc($check-icon-border-size / 2);
$check-icon-center-translate-ratio: percentage-to-ratio(calc($check-icon-border-size / 2));
$check-icon-center-size-ratio: percentage-to-ratio(100% - $check-icon-border-size);
$check-icon-center-translate-dividend: calc($check-icon-size * $check-icon-center-translate-ratio * 100);
$check-icon-center-translate-divisor: calc($check-icon-size * $check-icon-center-size-ratio);
$check-icon-center-translate: calc(($check-icon-center-translate-dividend / $check-icon-center-translate-divisor) * 1%);

.success-page {
  & p {
    margin-bottom: 0;
  }
}

.checkout-success-interaction {
  max-width: 382px;
  display: inline-flex;
  flex-direction: column;
}

.klarna-success-iframe {
  min-width: 360px;
}

.check-icon {
  @include circle-gradient($center-bg-color: $aco-dark-blue, $size: $check-icon-size, $border-main-color: $aco-light-blue, $border-secondary-color: transparent, $border-size: 15%);

  &::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{to-rgb($check-icon-main-color)}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
    background-position: center;
    background-repeat: no-repeat;
    background-size: $check-icon-background-size;
  }
}
