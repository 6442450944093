/**
 * Clamps too long string on a requested line with ellipsis ...
 * @param function $line: line the clamp should happen
 */

@mixin ellipsis-line-clamp($line, $webkit-box: -webkit-box) {
  display: #{$webkit-box};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: #{$line};
  -webkit-box-orient: vertical;
}
