.appstore-link {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  &__mobile {
    flex: 1 0 100%;
  }

  img {
    width: 135px;
    max-width: 80%;
    height: auto;
  }

  // Modifier

  &--is-mobile {
    max-width: none;
  }

  &--is-desktop {
    width: 60%;

    img {
      width: 120px;
    }
  }
}
