/**
Variables
 */

/**
Default Import
 */
@import "react-datepicker/dist/react-datepicker.css";

/**
Custom Classes
 */

@-moz-document url-prefix("") {
  .NativeDatepicker {
    overflow: hidden;

    &__input {
      position: relative !important;
      left: 0 !important;
      top: 18px !important;
      width: 270px !important;
      height: auto !important;
      opacity: 1 !important;
      background: transparent !important;
      color: #62d6fc;
      cursor: default !important;
      border: none !important;
      transform: translateX(-110px);
      outline: none !important;
    }
  }
}

.datepicker {
  display: block;
}

.datepicker-dropdown {
  position: initial;

  .datepicker-picker {
    box-shadow: none;
  }
}

.day {
  &.prev,
  &.next {
    opacity: 0;
  }
}

[data-selector="date_input"] {
  .datepicker {
    display: none;

    &.active {
      display: block;
      position: absolute;
    }
  }
}

#login-register {
  .datepicker {
    display: none;

    &.active {
      display: block;
      position: absolute;
    }
  }
}

/* stylelint-disable */
.react-datepicker {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  color: $aco-dark-blue;
  max-width: 400px;
  border: none;

  .react-datepicker__header {
    text-align: center;
    background-color: #fff;
    border-bottom: 0 solid transparent;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day-names {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    margin-bottom: 0;
    padding: 6px;
  }

  .react-datepicker__day--outside-month {
    color: $aco-gray-04;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__week {
    white-space: nowrap;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .react-datepicker__current-month {
    font-weight: normal;
    color: $aco-dark-blue;
    margin-top: -7px;
    margin-bottom: 10px;
  }

  .react-datepicker__navigation {
    display: flex;
    align-items: center;
  }

  .react-datepicker__day--selected {
    border-radius: 100%;
    background-color: $aco-light-blue;
    color: $aco-dark-blue;
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
  }

  .react-datepicker__day-name {
    font-size: 0.75rem;
    color: $aco-gray-02;
  }

  .react-datepicker__day {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border-radius: 100%;
      background-color: $aco-gray-06;
    }
  }
}

a {
}

/* stylelint-enable */
