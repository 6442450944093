/* Grid */
@use "sass:map";

/**
 Variables
 */
$grid-breakpoints: (
  xs: 0,
  sm_mobile: 375px,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px
) !default;
$grid-columns: 12;
$grid-gutter-width: 1.5rem;
$grid-row-columns: 6;
$gutters: $spacers;
$width-percentages: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
);

/**
 BS default import
 */
@import "~bootstrap/scss/grid";

.max-width-sm {
  max-width: map.get($grid-breakpoints, "sm");
}

@each $break_key, $break_value in $grid-breakpoints {
  @each $width_key, $width_value in $width-percentages {
    .w-#{$break_key}-#{$width_key} {
      @media screen and (min-width: $break_value) {
        width: $width_value !important;
      }
    }
  }
}

@each $break_key, $break_value in $grid-breakpoints {
  @each $width_key, $width_value in $width-percentages {
    .w-#{$break_key}-#{$width_key} {
      @media screen and (min-width: $break_value) {
        width: $width_value !important;
      }
    }
  }
}

.w-fit-content {
  width: fit-content;
}
