$max-z-index: 100;
$z-index-per-ten: calc($max-z-index / 10);

@mixin z-index-list {
  @for $i from 1 through $z-index-per-ten {
    .z-index-#{$i * 10} {
      z-index: $i * 10;
    }

    .z-index-negative-#{$i * 10} {
      z-index: -$i * 10;
    }
  }
}
