@use "sass:map";

/* Navigations */

/**
 Variables
 */
$nav-link-padding-y: map.get($spacers, "spacing-8");
$nav-link-padding-x: map.get($spacers, "spacing-16");
$nav-link-font-size: null;
$nav-link-font-weight: null;
$nav-link-color: $link-color;
$nav-link-hover-color: $link-hover-color;
$nav-link-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
$nav-link-disabled-color: $gray-600;

// Tabs
$nav-tabs-border-color: $gray-300;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg;

// Pills
$nav-pills-border-radius: $border-radius-pill;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

/**
 BS default import
 */
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";

.nav {
  &.nav-tabs {
    background-color: $aco-dark-blue;
    width: fit-content;
    border: none;

    & .nav-link {
      text-transform: uppercase;
      border: none;
      box-sizing: border-box;
      border-radius: $nav-tabs-border-radius;
      height: 72px;
      padding-left: 32px;
      padding-right: 32px;
      color: $aco-white;
      font-weight: $font-weight-bold;
      background-color: $aco-dark-blue;
      box-shadow: inset 0 -2px 0 0 rgba($aco-white, 0.2);
      align-items: center;
      display: flex;

      &.active {
        border: none;
        box-shadow: inset 0 -2px 0 0 $aco-yellow;
      }
    }
  }

  &.nav-pills {
    background-color: $aco-gray-06;
    border-radius: $nav-pills-border-radius;
    width: fit-content;
    border-color: transparent;
    position: relative;
    z-index: 2;
    height: 40px;

    @include media-breakpoint-down(xxl) {
      font-size: 14px;
    }

    @include media-breakpoint-down(sm) {
      &.mobile {
        width: 100%;

        & button {
          width: 50%;
        }
      }
    }

    & .nav-link {
      line-height: 1.4rem;
      text-transform: uppercase;
      height: 100%;
      box-sizing: border-box;
      color: $aco-gray-01;
      font-weight: $font-weight-bold;
      border-color: transparent;
      border-radius: $nav-pills-border-radius;
      padding: 10px 18px;
      position: relative;

      &.active {
        border-radius: $nav-pills-border-radius;
        background-color: $aco-light-blue;
        color: $aco-dark-blue;
        z-index: 3;
        transform-style: preserve-3d;
      }
    }
  }

  &.nav-tabs .nav-link:hover {
    color: $aco-light-blue;
  }

  &.nav-tabs .nav-link:focus,
  &.nav-tabs .nav-link:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 2px $aco-light-blue;
  }

  &.nav-pills .nav-link:hover {
    border-color: transparent;
    color: $aco-dark-blue;
  }

  &.nav-pills .nav-link:focus,
  &.nav-pills .nav-link:focus-visible {
    outline: none;
    border: 2px solid $aco-dark-blue;
    padding: 8px 16px;

    & + .nav-link.active::before {
      transform: translateZ(-1px);
      content: "";
      height: 40px;
      width: 36px;
      z-index: 1;
      top: 0;
      position: absolute;
      left: -18px;
      border: 2px solid $aco-dark-blue;
      border-left: none;
      border-right: none;
      background-color: $aco-gray-06;
    }
  }

  &.nav-pills .nav-link.active + .nav-link:not(.active):focus {
    border: 2px solid $aco-dark-blue;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    &::after {
      content: "";
      height: 40px;
      width: 18px;
      z-index: 1;
      top: -2px;
      position: absolute;
      right: 100%;
      border: 2px solid $aco-dark-blue;
      border-left: none;
      border-right: none;
    }
  }
}
