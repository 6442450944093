@use "sass:map";

$grid-breakpoints-custom: (
  xs: 0,
  sm_mobile: 375px,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px
) !default;
$container-max-widths-custom: (
  xs: 355px,
  sm_mobile: 355px,
  sm: 564px,
  md: 744px,
  lg: 984px,
  xl: 1164px,
  xxl: 1344px
) !default;
$container-padding-x: $grid-gutter-width * 0.5 !default;
$container-extra-padding-x: 12px !default;
$container-extra-size: calc(#{$container-extra-padding-x} * 2) !default;

// import Bootstrap
// @import "~bootstrap/scss/containers";
@import "~bootstrap/scss/mixins/breakpoints";

@function get-container-max-width($breakpoint) {
  @return map-get($container-max-widths-custom, $breakpoint);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints-custom) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints-custom) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints-custom) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: var(--aco-gutter-x, 0.75rem);
  padding-left: var(--aco-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.container-sm, .container {
  @include media-breakpoint-up(sm) {
    max-width: get-container-max-width(sm) !important;
  }
}

.container-md, .container-sm, .container {
  @include media-breakpoint-up(md) {
    max-width: get-container-max-width(md) !important;
  }
}

.container-lg, .container-md, .container-sm, .container {
  @include media-breakpoint-up(lg) {
    max-width: get-container-max-width(lg) !important;
  }
}

.container-xl, .container-lg, .container-md, .container-sm, .container {
  @include media-breakpoint-up(xl) {
    max-width: get-container-max-width(xl) !important;
  }
}

.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
  @include media-breakpoint-up(xxl) {
    max-width: get-container-max-width(xxl) !important;
  }
}

.container.container-extra {
  @each $key, $value in $container-max-widths-custom {
    @include media-breakpoint-up($key) {
      max-width: calc(#{get-container-max-width($key)} + #{$container-extra-size});
      padding-left: calc(#{$container-padding-x} + #{$container-extra-padding-x});
      padding-right: calc(#{$container-padding-x} + #{$container-extra-padding-x});
    }
  }
}
