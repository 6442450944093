@use "sass:map";

.cascade-wrapper {
  display: flex;
  flex-flow: column-reverse;

  &.reverse {
    flex-flow: column;
  }

  .cascade {
    position: relative;
    margin-top: -(map.get($radius-map, 24));
  }
}
