.address-check {
  padding: 40px 40px 50px;
  margin: 20px auto;

  &__given-suggested,
  &__title {
    padding: 0;
  }

  input[type="text"] {
    border: none;
    border-bottom: 1px solid $aco-white;
    width: 100%;

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid $aco-light-blue;
    }
  }

  &__input-group-section-wrapper,
  &__input-group-section,
  &__input-group {
    gap: 24px;
  }

  &__input-group {
    margin-bottom: 60px;
  }

  &__suggestions-forms {
    row-gap: 34px;
    column-gap: 40px;
  }

  &__suggestions-text {
    margin: 0 0 10px;
  }

  &__submit {
    margin-top: 10px;
  }

  @include media-breakpoint-down(sm) {
    &__submit, .btn {
      width: 100%;
      max-width: unset;
    }

    &__suggestions-forms {
      form {
        width: 100%;
        border-bottom: 1px solid $aco-white;
        padding-bottom: 40px;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    input[type="text"] {
      width: 75%;
    }
  }

  @include media-breakpoint-up(xl) {
    input[type="text"] {
      width: 100%;
    }
  }
}
