@use "sass:map";

$check-icon-size: 162px;
$circle-icon-rotation: -60deg;

.scan-preview {
  max-width: 100%;
}

.icon-circle {
  position: relative;
  height: $check-icon-size;
  width: $check-icon-size;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $aco-light-blue;

  svg {
    width: 45%;
    height: 45%;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate($circle-icon-rotation) scale(-1, 1);
    height: $check-icon-size;
    width: $check-icon-size;
    background-image: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 176 176' color='#62D6FC' stroke-width='12' fill='none'><g><linearGradient id='spinner-secondHalf' gradientUnits='userSpaceOnUse' x1='-1393.3174' y1='1582.0088' x2='-1392.2252' y2='1582.0088' gradientTransform='matrix(170.1357 0 0 -85.0679 237048.1875 134585.125)'><stop  offset='0' stop-color='currentColor' stop-opacity='0' /><stop  offset='1' stop-color='currentColor' stop-opacity='0.5' /></linearGradient><linearGradient id='spinner-firstHalf' gradientUnits='userSpaceOnUse' x1='-1393.3174' y1='1581.1044' x2='-1392.2252' y2='1581.1044' gradientTransform='matrix(170.1357 0 0 -85.0679 237048.1875 134670.1875)'><stop  offset='0' stop-color='currentColor' /><stop  offset='1' stop-color='currentColor' stop-opacity='0.5' /></linearGradient><path stroke='url(#spinner-secondHalf)' d='M7,88C7,43.3,43.3,7,88,7s81,36.3,81,81'/><path stroke='url(#spinner-firstHalf)' d='M169,88c0,44.7-36.3,81-81,81S7,132.7,7,88'/><path stroke='currentColor' stroke-linecap='round' d='M7,88C7,86.3,7,84.6,7,82.8'/></g></svg>");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.prescription-type-select {
  .list-group-item-selection {
    background: transparent;
    color: $aco-white;

    .list-group-item-content {
      padding-right: 10px;
    }
  }
}

.prx-workflow {
  #video {
    position: absolute;
    object-fit: cover;
    max-width: 100%;
    width: 300px;
    height: 300px;
  }
}
