@use "sass:map";

/* Borders */

/**
 Variables
 */
$border-width: 1px;
$border-widths: (
  small: 1px,
  medium: 2px,
);
$border-color: $gray-300;
$border-radius: 1rem;
$border-radius-sm: 0.75rem;
$border-radius-lg: 1.5rem;
$border-radius-pill: 50rem;

/* Generation of allowed radius */
$base-radius-rem: 0.0625rem;
$radius-map: (
  0: $base-radius-rem * 0,
  1: $base-radius-rem * 1,
  2: $base-radius-rem * 2,
  6: $base-radius-rem * 6,
  8: $base-radius-rem * 8,
  12: $base-radius-rem * 12,
  16: $base-radius-rem * 16,
  20: $base-radius-rem * 20,
  24: $base-radius-rem * 24,
  32: $base-radius-rem * 32,
);
$divider-background: (
  aco-blue: $aco-blue-disabled,
  aco-dark-blue: $aco-dark-blue-disabled,
  aco-gray-06: $aco-gray-05,
  aco-white: $aco-gray-06,
);

.border-rounded {
  @each $key, $value in $radius-map {
    &-#{$key} {
      border-radius: #{$value};
    }

    &-top-#{$key} {
      border-top-left-radius: #{$value} !important;
      border-top-right-radius: #{$value} !important;
    }

    &-bottom-#{$key} {
      border-bottom-left-radius: #{$value} !important;
      border-bottom-right-radius: #{$value} !important;
    }

    &-start-#{$key} {
      border-top-left-radius: #{$value} !important;
      border-bottom-left-radius: #{$value} !important;
    }

    &-end-#{$key} {
      border-top-right-radius: #{$value} !important;
      border-bottom-right-radius: #{$value} !important;
    }
  }
}

/* Colors outside theme */
.border.border-aco-white-40 {
  // border-color: rgba($aco-white, 0.4) !important;
  border-color: $aco-gray-04;
}

.border.border-aco-white-20 {
  // border-color: rgba($aco-white, 0.2) !important;
  border-color: $aco-gray-06;
}

.divider {
  @each $key, $value in $divider-background {
    &-#{$key} {
      height: 1px;
      width: 100%;
      background-color: #{$value};
    }
  }
}
