// Rating Product
@use "sass:map";

[data-selector="add_rating_feedback_wrapper"] {
  min-height: 24px;
}

[data-selector="error_box"] {
  min-height: 70px;
}

[data-selector="rating_form"] {
  max-width: 460px;

  .message-truncated {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    width: 100%;
  }
}

[data-selector="rating-image"] {
  img {
    max-width: 11.625rem;
    height: auto;
    width: 100%;

    @media screen and (max-width: map.get($grid-breakpoints, "xl")) {
      max-width: 9.75rem;
    }

    @media screen and (max-width: map.get($grid-breakpoints, "lg")) {
      max-width: 7.875rem;
    }

    @media screen and (max-width: map.get($grid-breakpoints, "md")) {
      max-width: 9.125rem;
    }

    @media screen and (max-width: map.get($grid-breakpoints, "sm")) {
      max-width: 21.3125rem;
    }
  }
}
