@use "sass:map";

$header-min-height: 394px;
$header-background-color: $aco-dark-blue;
$header-text-color: $aco-white;
$header-image-background-color: $aco-white;
$header-image-padding: map.get($spacers, "spacing-8");
$header-bottom-border-radius: map.get($radius-map, 24);
$header-padding-top: map.get($spacers, "spacing-16");
$header-padding-bottom: map.get($spacers, "spacing-24");
$header-padding-x: map.get($spacers, "spacing-16");
$content-min-height: 433px;
$content-text-color: $aco-dark-blue;
$content-padding-y: map.get($spacers, "spacing-24");
$content-padding-x: map.get($spacers, "spacing-16");
$product-image-border-radius: map.get($radius-map, 16);
$product-content-padding-left: map.get($spacers, "spacing-24");
$product-margin-bottom: map.get($spacers, "spacing-40");
$product-input-group-height: 32px;
$product-input-group-width: 129px;
$product-input-group-color: $aco-dark-blue;
$product-remove-icon-color: $aco-light-blue;
$product-info-color: $aco-gray-03;
$product-available-color: $aco-green;
$product-unavailable-color: $aco-red;

.account-watch-list-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $header-text-color;
  background-color: $header-background-color;

  h1 {
    @include media-breakpoint-down(sm) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  &:not(:only-child) {
    border-bottom-left-radius: $header-bottom-border-radius;
    border-bottom-right-radius: $header-bottom-border-radius;
  }

  & .products-section {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    &.empty {
      justify-content: flex-end;
    }

    & .product {
      display: flex;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: $product-margin-bottom;
      }

      & .product-image {
        position: relative;
        display: flex;
        height: fit-content;
        border-radius: $product-image-border-radius;
        padding: $header-image-padding;
        background-color: $header-image-background-color;
        max-width: 166px;
        max-height: 166px;

        @include media-breakpoint-down(sm) {
          max-width: 100px;
          max-height: 100px;
        }

        & img {
          width: 100%;
          height: 100%;
        }
      }

      & .product-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-left: $product-content-padding-left;

        @include media-breakpoint-down(sm) {
          padding-left: map.get($spacers, "spacing-16");
        }

        & .product-name,
        & a.product-name {
          color: $header-text-color;
          font-weight: $font-weight-bold;
        }

        & .product-remove {
          margin-left: 1rem;
          color: $product-remove-icon-color;
          width: fit-content;
          height: fit-content;

          @include media-breakpoint-down(sm) {
            margin-left: 0.75rem;
          }

          & svg {
            @include media-breakpoint-down(sm) {
              font-size: $font-size-base;
            }
          }
        }

        & .product-info {
          display: flex;
          flex-direction: column;
          color: $product-info-color;
          font-size: $font-size-sm;

          @include media-breakpoint-down(sm) {
            font-size: $font-size-xs;
          }
        }

        & .input-group {
          height: $product-input-group-height;
          width: $product-input-group-width;

          & input:not(:disabled) {
            color: $product-input-group-color;
            font-weight: $font-weight-bold;
          }
        }

        & .available {
          color: $product-available-color;
          font-size: $font-size-sm;
          font-weight: $font-weight-bold;
        }

        & .unavailable {
          color: $product-unavailable-color;
          font-size: $font-size-sm;
          font-weight: $font-weight-bold;
        }

        & .total-price {
          font-weight: $font-weight-bold;
        }

        & cart-button {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;

          &::part(button) {
            margin-top: map.get($spacers, "spacing-16");
            width: 100%;
            max-width: 261px;

            @media screen and (max-width: map.get($grid-breakpoints, sm)) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.account-watch-list-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: $content-min-height;
  color: $content-text-color;
  padding: $content-padding-y $content-padding-x;

  & .contributions-section {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    &.empty {
      justify-content: flex-end;
    }
  }
}
