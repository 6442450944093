@use "sass:map";

.account-reminder-wrapper {
  #account-reminder-search-wrapper {
    & > * {
      width: 100%;
    }

    & input.form-control {
      &::placeholder {
        color: $aco-gray-01;
        opacity: 1;
      }
    }

    & .account-reminder-search-field {
      margin-bottom: map.get($spacers, "spacing-32");

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: map.get($grid-breakpoints, md)) {
        margin-bottom: map.get($spacers, "spacing-24");
      }
    }

    & .autosuggest {
      & h1,
      & h2,
      & h3,
      & h4,
      & h5,
      & h6 {
        margin-bottom: map.get($spacers, "spacing-24");
      }

      & hr {
        height: 0;
        background: transparent;
      }

      @media screen and (max-width: map.get($grid-breakpoints, md)) {
        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6 {
          margin-bottom: map.get($spacers, "spacing-20");
        }
      }
    }
  }

  .accordion-button {
    border: 2px solid transparent;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .reminder-item {
    & .info-counter {
      & .counter {
        @media screen and (min-width: map.get($grid-breakpoints, md)) {
          @include circle-gradient($size: calc($counter-size * 1.34));
        }

        & .content {
          width: calc(100% - $circle-gradient-border-size);
        }
      }
    }
  }

  .qty-per-dosage {
    width: 230px;
  }

  .reminder-title {
    width: 210px;
  }

  .reminder-product-image {
    height: fit-content;
  }

  [data-selector="search-result"] {
    cursor: pointer;
    border: 2px solid transparent;

    img {
      opacity: 0.7;
    }

    &:hover,
    &.active {
      color: $aco-dark-blue;
      border: 2px solid $aco-light-blue;

      img {
        opacity: 1;
      }
    }

    b {
      color: $aco-light-blue;
    }
  }

  .react-datepicker__navigation--previous {
    right: 46px;
    left: auto;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: $aco-dark-blue;
    border-width: 1px 1px 0 0;
  }

  .react-datepicker .react-datepicker__header {
    text-align: left;
    padding-left: 8px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: $aco-dark-blue;
    font-weight: normal;
    font-size: 1rem;
  }
}

[data-selector="product_cart"] {
  max-width: 520px;
  width: 100%;
}
