/* Account */

@use "sass:map";

$counter-badge-size: 1.875rem;
$counter-badge-padding: 0.4rem;
$counter-elements-rotate-deg: -$circle-gradient-rotation;
$counter-size: 96px;
$counter-z-index: $circle-gradient-z-index;
$counter-badge-z-index: $circle-gradient-content-z-index;

.welcome {
  color: $aco-light-blue;
  font-size: $font-size-base;
}

.account-header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 318px;
  color: $aco-white;
  background-color: $aco-dark-blue;
  border-bottom-left-radius: map.get($radius-map, 32);
  border-bottom-right-radius: map.get($radius-map, 32);
  padding-top: map.get($spacers, "spacing-40");
  padding-bottom: map.get($spacers, "spacing-32");

  & .client-number {
    opacity: 0.6;
  }
}

.account-order {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 177px;
  color: $aco-white;
  background-color: $aco-blue;
  padding: map.get($spacers, "spacing-16");
  border-radius: map.get($radius-map, 8);
}

.info-counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  & .counter {
    @include circle-gradient($size: $counter-size);

    z-index: $counter-z-index;
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;

    &.counter-small {
      @include circle-gradient($size: calc($counter-size / 1.5));
    }

    & .counter-badge {
      position: absolute;
      height: $counter-badge-size;
      width: $counter-badge-size;
      padding: $counter-badge-padding;
      bottom: 0;
      left: 25%;
      z-index: $counter-badge-z-index;
      transform: rotate($counter-elements-rotate-deg) translate(25%, 25%);
    }
  }

  & .info {
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    text-align: center;
    padding-top: $counter-badge-padding;
  }
}

.icon-with-badge {
  position: relative;

  & .badge-icon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%) translateY(-30%);

    &.badge-white {
      background: $aco-white;
    }

    &.badge-sm {
      font-size: 0.6875rem;
    }
  }
}

.btn-text-base {
  font-size: $font-size-base;
}

.options-list {
  width: 100%;
}

[data-pagetype="account_address_book"] {
  .select-items {
    top: 40px;
  }
}

.account-menu {
  padding: 20px 40px;
}
