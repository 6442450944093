@use "sass:map";

/* Teaser Card */

$item-default-width: 343px;
$item-min-height: 228px;
$headline-width: 65%;
$content-size: 60%;
$content-spacing-y: map.get($spacers, "spacing-16");
$content-spacing-x: map.get($spacers, "spacing-20");
$image-width: 10rem;
$image-height: 10rem;

.teaser-card {
  position: relative;
  padding: 1rem 1.25rem;
  flex: 1 1 100%;
  min-height: 228px;
  min-width: 250px;
  width: $item-default-width;
  border: map.get($border-widths, "medium") solid $aco-gray-06;
  border-radius: $border-radius;
  z-index: 0;
  overflow: hidden;

  p:last-of-type {
    margin-bottom: 0;
  }

  &-container__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
  }

  &__background-image {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    width: 50%;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("/releases/current/templates/apo_com_responsive/assets/img/resource/images/circle_light_blue.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 55%;
    border-bottom-right-radius: calc($border-radius - 0.1rem);
  }

  // Elements
  &__title {
    margin-bottom: $content-spacing-y;
    font-size: 20px;
    hyphens: auto;

    .has-image & {
      max-width: $headline-width;
    }

    @include media-breakpoint-up("xl") {
      font-size: 24px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: first baseline;
    width: calc(100% + 1.25rem);
    gap: $content-spacing-x;
    padding-bottom: $content-spacing-y;
    padding-right: $content-spacing-x;
    border-bottom: map.get($border-widths, "medium") solid $aco-gray-06;

    &-link {
      font-size: 16px;
      color: $aco-gray-01;
      white-space: nowrap;

      // Icon
      &::after {
        content: url("data:image/svg+xml,%3Csvg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 320 512\"><path d=\"M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z\" fill=\"#{$aco-gray-01}\" /></svg>");
        display: inline-block;
        width: 8px;
        height: auto;
        margin-left: 8px;
      }
    }

    // Corrections if title is inside header
    .teaser-card__title {
      margin-bottom: 0;
      padding: 0;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .link-list {
      width: calc(100% + 1.25rem);
    }

    .has-image & {
      max-width: $content-size;
    }
  }

  &__content {
    flex: 1;
    hyphens: auto;

    &--text {
      display: flex;
      overflow: hidden;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    &--image {
      width: $image-width;
      height: $image-height;
      object-fit: contain;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 8px;

    // Icon
    &--more-info {
      &::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("data:image/svg+xml,%3Csvg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 192 512\"><path d=\"M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32l64 0c17.7 0 32 14.3 32 32l0 224 32 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 512c-17.7 0-32-14.3-32-32s14.3-32 32-32l32 0 0-192-32 0c-17.7 0-32-14.3-32-32z\" fill=\"#{$aco-blue}\"/></svg>") no-repeat 50% 50%;
        background-size: 3px;
        border: map.get($border-widths, "small") solid $aco-blue;
        border-radius: 50%;
      }
    }
  }

  // Item modifier
  &--filled::before {
    background-color: $aco-gray-06;
  }

  &--shadow {
    box-shadow: $box-shadow-md;
  }

  @include media-breakpoint-up(xs) {
    flex: 1 1 100%; // Eine Karte pro Reihe im xs-Viewport
    max-width: 356px; // Maximale Breite der Karten im xs-Viewport
  }

  @include media-breakpoint-up(sm) {
    flex: 1 1 calc(100% / 2 - 1rem); // Zwei Karten pro Reihe ab sm
    max-width: 356px; // Maximale Breite der Karten im sm-Viewport
    min-width: 250px; // Minimale Breite der Karten im xs-Viewport
  }

  @include media-breakpoint-up(md) {
    flex: 1 1 calc(100% / 2 - 1rem); // Zwei Karten pro Reihe ab md
  }

  @include media-breakpoint-up(lg) {
    flex: 1 1 calc(100% / 3 - 1rem); // Drei Karten pro Reihe ab lg
    max-width: 296px; // Maximale Breite der Karten ab lg
  }

  @include media-breakpoint-up(xl) {
    flex: 1 1 calc(100% / 3 - 1rem); // Drei Karten pro Reihe ab xl
    max-width: 356px;
  }

  @include media-breakpoint-up(xxl) {
    flex: 1 1 calc(100% / 3 - 1rem); // Drei Karten pro Reihe ab xxl
    max-width: 343px;
  }
}
