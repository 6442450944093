@use "sass:map";

/* Article Card */

.brandshop {
  width: 100%;

  .swiper-wrapper {
    max-height: 88px;

    @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "sm_mobile")} + 1px)) {
      max-height: 111px;
    }

    @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "sm")} + 1px)) {
      max-height: 153px;
    }

    @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "md")} + 1px)) {
      max-height: 146px;
    }

    @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "xl")} + 1px)) {
      max-height: 176px;
    }
  }
}

[data-selector="brands-scroller"] {
  display: flex;
  right: 0;
}

.brand-card {
  border: 2px solid $aco-gray-05;
  border-radius: 12px;
  width: 88px;
  height: 88px;
  user-select: none;

  &:hover {
    top: -1px;
  }

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "sm_mobile")} + 1px)) {
    width: 111px;
    height: 111px;
  }

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "sm")} + 1px)) {
    width: 153px;
    height: 153px;
  }

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "md")} + 1px)) {
    width: 146px;
    height: 146px;
  }

  @media only screen and (min-width: calc(#{map.get($grid-breakpoints, "xl")} + 1px)) {
    width: 176px;
    height: 176px;
  }
}

.swiper-button-disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.5 !important;
}
