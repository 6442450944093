/* Cards */

@use "sass:map";

/**
 Variables
 */
$card-spacer-y: map.get($spacers, "spacing-16");
$card-spacer-x: map.get($spacers, "spacing-16");
$card-title-spacer-y: map.get($spacers, "spacing-8");
$card-border-width: $border-width;
$card-border-color: rgba($black, 0.125);
$card-border-radius: $border-radius;
$card-box-shadow: null;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-padding-y: $card-spacer-y * 0.5;
$card-cap-padding-x: $card-spacer-x;
$card-cap-bg: rgba($black, 0.03);
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: $white;
$card-img-overlay-padding: map.get($spacers, "spacing-16");
$card-group-margin: $grid-gutter-width * 0.5;

// Product Card variables

/**
 BS default import
 */
@import "~bootstrap/scss/card";

/**
 BS Override
 */
.products-slider-dark {
  .card {
    border: none;
  }
}

/**
 Custom Styles for Product Cards
 */

/* stylelint-disable no-descending-specificity */

.product-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 261px;

  & .card {
    margin-bottom: map.get($spacers, "spacing-16");
  }

  & .card-button-wrapper {
    margin-top: map.get($spacers, "spacing-12");
  }

  & .price-and-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    margin-top: map.get($spacers, "spacing-16");

    & .price-wrapper {
      gap: map.get($spacers, "spacing-16");

      & .product-price:first-child:last-child {
        width: 100%;
        text-align: end;
      }
    }

    & > * {
      width: 100%;
    }
  }

  &[data-test="variation"] {
    .eyecatcher {
      border-radius: 0 4px 4px 0;

      &-combination {
        background-color: $aco-orange;
        border-radius: 0 4px 4px 0 !important;

        div:nth-child(2) {
          display: flex;
          align-items: center;
          position: absolute;
          height: 100%;
          top: 0;
          right: -8px;
          border-radius: 4px;
          padding: 2px 8px;
          color: $aco-dark-blue;
          background-color: $aco-gray-04;
          transform: translateX(100%);
        }
      }
    }

    .product-bullet-point {
      width: fit-content;
      font-size: 14px;
      column-gap: 8px;
      align-items: center;

      > span {
        font-weight: 400 !important;
      }

      .icon-container {
        aspect-ratio: unset;
        width: auto;
        border: none;
      }
    }

    .footnote {
      color: $aco-gray-02;
      font-size: 12px;

      .footnote-label,
      sup {
        text-indent: unset;
      }
    }

    .footnote-bold {
      font-size: 16px;
      line-height: 1.5;
    }

    .unit-price-section {
      margin-bottom: 0;
      max-height: none;
      min-height: 0;

      .footnote-bold {
        font-size: 14px;
        line-height: 1.3;
        font-weight: 400;
      }
    }

    .tags-section {
      margin: 0;
    }

    .price-and-button {
      flex-flow: row;
      align-items: flex-end;
      margin: 1rem 0 0;
      column-gap: 5px;

      .price-wrapper {
        display: flex;
        flex-flow: row wrap;
        gap: 0;
        column-gap: 4px;
        line-height: 1.3;
        align-items: center;
        justify-content: flex-start !important;

        .product-price {
          padding: 0;

          &:first-child:last-child {
            text-align: unset;
          }
        }

        .product-price.small {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .card-button-wrapper {
        flex: 0;

        cart-button {
          &.shadow-root::part(button) {
            width: 100%;
            min-height: 40px;
            height: 40px;
            padding: 12px 17px 12px 16px;
            border-radius: 20px;
          }
        }
      }
    }

    .product-pricediscount {
      flex: 1 1 auto;
      font-size: 14px;
      color: $aco-gray-01;
    }

    .rating {
      rating-stars[size="md"] {
        min-width: 95px;

        .rating-star-size-md {
          font-size: 17px;

          svg {
            width: 17px;
          }
        }
      }

      .amount {
        font-size: 14px;
      }
    }

    .card-title-truncate-overflow {
      min-height: 0;
    }

    .card-button-wrapper {
      &[data-in-stock="false"] {
        opacity: 0;
        pointer-events: none;
      }
    }

    @include media-breakpoint-between(xl, xxl) {
      .product-pricediscount {
        display: none;
      }
    }
  }
}

.product-card,
.product-card-sponsored {
  & .card-body {
    justify-content: center;
    align-items: center;
    max-height: 100%;

    &,
    & img {
      display: flex;
      width: auto;
      height: 100%;
      max-width: 100%;
    }

    & img {
      max-height: 200px;
    }
  }

  & .tags-section {
    flex-grow: 1;
    margin-bottom: map.get($spacers, "spacing-8");
  }

  & .unit-price-section {
    flex-grow: 1;
    min-height: 42px;
    margin-bottom: map.get($spacers, "spacing-8");
  }

  & .bullet-points {
    & .product-bullet-point {
      display: flex;
      align-items: flex-start !important;
      flex-direction: row;
      gap: map.get($spacers, "spacing-8");
      min-height: 60px;
    }

    & .icon-container {
      width: 16px;
      height: 16px;
      background-color: $aco-white !important;
      border: 1px solid $aco-blue;
      color: $aco-blue;
      margin: 3px 0 0 !important;
    }
  }

  & .price-and-button {
    & .price-wrapper {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }

  & .card-button {
    &,
    &.shadow-root::part(button) {
      font-size: $btn-font-size;
      line-height: $btn-line-height !important;
      width: 100%;
      min-height: $btn-min-height;
      height: $btn-min-height;
      padding: $btn-padding-y $btn-padding-x;
    }
  }
}

.product-card-sponsored {
  display: flex;
  padding: map.get($spacers, "spacing-8");
  height: 100%;
  gap: map.get($spacers, "spacing-20");
  max-width: 782px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  & .card-title-truncate-overflow {
    @extend %headline;

    font-size: $h3-font-size;
  }

  & .card {
    @include media-breakpoint-up(sm) {
      min-width: 214px;
      max-width: 336px;
    }
  }

  & .price-and-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    & .price-wrapper {
      gap: map.get($spacers, "spacing-8");
    }
  }
}

.service-card-section {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  & .icon svg {
    height: 48px;
    width: 48px;
  }
}

@include media-breakpoint-up(lg) {
  .service-card-section {
    grid-template-columns: repeat(2, 2fr);
  }
}
