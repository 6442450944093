.contentful-entry-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0;
  margin: 0 auto;

  h2 {
    font-weight: 600;
    font-size: 23px;
    line-height: 34px;
  }

  .contentful-entry-container__content {
    hyphens: auto;
    width: 100%;
    overflow: hidden;
    background: white;
    padding: 0;

    &.pre-cascade {
      padding-bottom: 1.5rem;
    }

    .contentful-entry-container__content__inner-container {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .products-slider {
      padding: 0;
    }

    *[data-name="webUSPModule"] {
      .usp_wrap {
        row-gap: 2rem;
      }
    }

    .products-slider [data-slider="products"] {
      padding: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 0 6px;

    h2 {
      font-size: 24px;
      line-height: 36px;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 0 12px;

    h2 {
      font-size: 25px;
      line-height: 38px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 0 20px;

    h2 {
      font-size: 26px;
      line-height: 39px;
    }
  }

  @include media-breakpoint-up(xl) {
    h2 {
      font-size: 28px;
      line-height: 42px;
    }
  }

  @include media-breakpoint-up(xxl) {
    padding: 0;
    max-width: 1344px;

    .contentful-entry-container__content .contentful-entry-container__content__inner-container {
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0;
      max-width: 1076px;
    }

    h2 {
      font-size: 28px;
      line-height: 42px;
    }
  }
}
