$logoff-check-icon-size: 168px;
$logoff-check-icon-size-sm: 128px;
$logoff-check-icon-border-size: 18%;
$logoff-check-icon-background-size: 45.74%;
$logoff-check-icon-background-size-sm: 46.69%;
$logoff-check-icon-center-bg-color: $aco-dark-blue;
$logoff-check-icon-border-main-color: $aco-light-blue;
$logoff-check-icon-border-secondary-color: transparent;

.logoff-check-icon {
  @include circle-gradient($center-bg-color: $logoff-check-icon-center-bg-color, $size: $logoff-check-icon-size, $border-main-color: $logoff-check-icon-border-main-color, $border-secondary-color: $logoff-check-icon-border-secondary-color, $border-size: $logoff-check-icon-border-size);

  &::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{to-rgb($check-icon-main-color)}' d='M440.1 103C450.3 112.4 450.3 127.6 440.1 136.1L176.1 400.1C167.6 410.3 152.4 410.3 143 400.1L7.029 264.1C-2.343 255.6-2.343 240.4 7.029 231C16.4 221.7 31.6 221.7 40.97 231L160 350.1L407 103C416.4 93.66 431.6 93.66 440.1 103V103z'/></svg>");
    background-position: center;
    background-repeat: no-repeat;
    background-size: $logoff-check-icon-background-size;
  }

  @include media-breakpoint-down(md) {
    @include circle-gradient($center-bg-color: $logoff-check-icon-center-bg-color, $size: $logoff-check-icon-size-sm, $border-main-color: $logoff-check-icon-border-main-color, $border-secondary-color: $logoff-check-icon-border-secondary-color, $border-size: $logoff-check-icon-border-size);

    &::after {
      background-size: $logoff-check-icon-background-size-sm;
    }
  }
}
